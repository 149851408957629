.tooltip {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &::before {
    content: '!';
    background: $brandPrimaryColor;
    color: $whiteColor;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding: 4px;
  }

  // &:hover {
  //     .#{$namespace}tooltip__content {
  //         display: block;
  //     }
  // }
  &__content {
    $offset: 50%;
    display: none;
    width: 60vw;
    max-width: 400px;
    background: $backgroundWhiteColor;
    box-shadow: 0 0 5px rgb(0 0 0 / .3);
    position: absolute;
    left: 50%;
    transform: translate(-$offset);
    margin-bottom: 10px;
    padding: 15px;
    line-height: 1.5;
    border-radius: 5px;
    z-index: $indexTooltip;

    @include fontSize();

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      box-shadow: 0 0 5px rgb(0 0 0 / .3);
      bottom: -5px;
      left: $offset;
      transform: translateX(-50%) rotate(45deg);
      display: block;
      position: absolute;
      z-index: 1;
      background: $backgroundWhiteColor;
    }

    &::after {
      content: '';
      width: 20px;
      height: 7px;
      bottom: 0;
      left: $offset;
      transform: translateX(-50%);
      display: block;
      position: absolute;
      z-index: 2;
      background: $backgroundWhiteColor;
    }

    &--visible {
      display: block;
    }

    &--position {
      &_bottom {
        bottom: auto;
        top: 100%;
        margin-bottom: 0;
        margin-top: 10px;

        &::before {
          bottom: auto;
          top: -5px;
        }

        &::after {
          bottom: auto;
          top: 0;
        }
      }
    }
  }
}
