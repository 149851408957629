@import '../../dependencies.scss';

.active-filters {
  $root: &;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $screen-tablet-portrait) {
    flex-wrap: nowrap;
  }

  &__filter-text {
    order: 1;
    font-size: 14px;
    line-height: 22px;
    color: $brownMediumColor;
    font-weight: bold;
    margin: 3px 16px 3px 0;
    white-space: nowrap;
    width: 45%;

    @media (max-width: $screen-mobile-landscape-max) {
      display: none;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
    }
  }

  &__filter,
  &__remove-all {
    display: inline-block;
    margin: 3px 6px 3px 0;
    position: relative;
    white-space: nowrap;
  }

  &__remove-all {
    order: 2;
    cursor: pointer;
    position: relative;
    background: transparent;
    border-radius: 0;
    font-size: 12px;
    text-decoration: underline;
    color: $brownMediumColor;
    padding: 0;
    margin: 3px 0 3px 12px;
    border: 0 none;
    line-height: 23px;
    white-space: nowrap;
    min-height: 23px;

    @media (min-width: $screen-tablet-portrait) {
      order: 3;
    }

    .tag__button--icon_close {
      display: none;
    }

    &:hover {
      text-decoration: underline !important;
      background-color: transparent;
      color: $brownMediumHoverColor;
    }
  }

  &__filter-wrapper {
    order: 2;
    align-self: flex-start;
    display: block;
    overflow-x: auto;
    width: auto;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__placeholder {
    @include componentPreloader();
    min-height: 79px;
  }
}
