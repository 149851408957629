.link-box-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 16px 0;
  align-items: center;

  .products-list-page + &,
  .additional-content + & {
    @include container();
    margin-bottom: 32px;
  }

  &__headline {
    font-weight: $fontWeightBold;
    color: $brownMediumColor;
    font-size: 13px;
    width: auto;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    width: auto;
  }

  &__item {
    display: inline-block;
    color: $brownDarkColor;
    text-decoration: underline;

    &:hover {
      color: $brownMediumColor;
    }
  }
}
