@import '../../../bps/components/readMore/readMore.scss';

.read-more {
  &__btn {
    font-size: 16px;
    font-weight: bold;
    color: $redDarkColor;
    margin: 8px 0 0;
    cursor: pointer;
  }

  &__trigger {
    color: $redDarkColor;
    display: block;
    font-weight: $fontWeightBold;
  }
}
