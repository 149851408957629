.page-main {
  --header-height: 46px;
  display: block;
  min-height: 140px;

  @media (min-width: $screen-desktop-sm) {
    --header-height: 147px;
  }
}

.page--category-list {
  .mood-teaser {
    .page-intro {
      &__text,
      &__button {
        color: $whiteColor;
      }
    }
  }
}

.theme-light {
  .breadcrumbs__item::after,
  .breadcrumbs__link-name,
  .products-sort__select:not(.select--active),
  .products-sort__select:not(.select--active) .select__wrapper--custom::before,
  .products-list-page__name,
  .pagination__button,
  .pagination__pages > *,
  .paging__select:not(.select--active) .select__selected-value,
  .paging__select:not(.select--active) .select__wrapper--custom::before,
  .page-intro__text,
  .filters__open-filters,
  .filters__open-filters::before,
  .select__wrapper::before,
  .page-intro__button:not(.button--light) {
    color: $blackColor !important;
  }
}

.theme-light,
.theme-light-premium {
  .breadcrumbs__item::after,
  .breadcrumbs__link-name,
  .products-list-page__name,
  .active-filters__filter-text,
  .active-filters__remove-all,
  .highlight-links__filter-text,
  .page-intro__text,
  .page-intro__button:not(.button--dark) {
    color: $blackColor !important;
  }

  .active-filters__filter,
  .highlight-links__filter,
  .tag__button--icon_close {
    color: $whiteColor !important;
  }

  .active-filters__filter,
  .highlight-links__filter {
    background: $blackColor !important;
  }
}

.theme-dark,
.theme-dark-premium {
  .breadcrumbs__item::after,
  .breadcrumbs__link-name,
  .products-list-page__name,
  .page-intro__text,
  .page-intro__button:not(.button--light),
  .products-sort__select:not(.select--active),
  .products-sort__select:not(.select--active) .select__wrapper--custom::before,
  .active-filters__filter-text,
  .active-filters__remove-all,
  .highlight-links__filter-text,
  .filters__open-filters,
  .filters__open-filters::before,
  .select__wrapper::before,
  .pagination__button,
  .pagination__pages > *,
  .paging__select:not(.select--active) .select__selected-value,
  .paging__select:not(.select--active) .select__wrapper--custom::before {
    color: $whiteColor !important;
  }

  .active-filters__filter,
  .highlight-links__filter,
  .tag__button--icon_close {
    color: $blackColor !important;
  }

  .active-filters__filter,
  .highlight-links__filter {
    background: $sandHoverColor !important;
  }
}
