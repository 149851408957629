.navigation {
  $root: &;
  $nav-visible-breakpoint: $screen-desktop-sm;

  @media (min-width: $nav-visible-breakpoint) {
    position: relative;
    min-height: 20px;

    &__list {
      @include clearList();
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-left: 0 !important;

      @media (min-width: $screen-desktop) {
        gap: 60px;
      }

      @media (min-width: $screen-desktop-xl) {
        gap: 90px;
      }

      .header__sticky--active & {
        @media (min-width: $screen-desktop-sm) {
          gap: 10px;
          margin-left: 60px;
        }

        @media (min-width: $screen-desktop) {
          gap: 20px;
        }

        @media (min-width: $screen-desktop-xl) {
          gap: 40px;
        }
      }
    }

    &__link {
      display: block;
      color: $terracottaColor;
      font-family: $fontFamily;
      font-size: 16px;
      font-weight: $fontWeightThin;
      text-decoration: none;
      white-space: nowrap;
      text-align: center;
      position: relative;
      width: 100%;
      padding: 12px 15px 11px;

      @media (min-width: $screen-desktop-sm) {
        padding: 17px 15px 19px;
      }

      > span {
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: -3px;
          background: $terracottaColor;
          height: 2px;
          width: 0;

          #{$root}__element--hover & {
            animation: linkHoverIn ease .4s;
            width: 100%;
            left: 0;
          }

          #{$root}__element--mouseout & {
            animation: linkHoverOut ease .4s;
            width: 0;
            right: 0;
          }
        }
      }

      &--all {
        box-shadow: 0 -2px 10px 0 rgb(0 0 0 / .1);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 200px;
        text-align: center;
      }

      &:visited {
        color: $terracottaColor;
      }

      .header__sticky--active & {
        padding: 15px 5px 17px;
        font-size: 15px;
        color: $sandColor;

        @media (min-width: $screen-desktop) {
          padding: 15px 15px 17px;
          font-size: 16px;
        }

        > span {
          &::after {
            background: $sandColor;
          }
        }
      }
    }

    &__flyout {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: -2000px;
      right: 0;
      width: 100%;
      transition: visibility .2s, opacity .2s;
      min-height: 200px;
      background: $backgroundWhiteColor;
      box-shadow: 0 5px 25px 0 rgb(0 0 0 / .2);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      z-index: $indexHeader;

      &--all {
        left: 0;
        right: auto;
        width: auto;
      }
    }

    &__element {
      display: flex;
      justify-content: center;
      height: 100%;
      text-transform: uppercase;
      width: auto;

      &:hover,
      & + :hover,
      &--visible {
        #{$root} {
          &__flyout {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
      }

      &.highlight {
        .navigation__link {
          color: $terracottaColor;

          > span {
            border: 2px solid $terracottaColor;
            padding: 3px 10px 5px;
            border-radius: 5px;
            transition: background .3s;

            &::after {
              width: 0 !important;
            }
          }
        }

        body:not(.is_touch) & {
          &:hover {
            .navigation__link {
              color: $sandColor;

              > span {
                background: $terracottaColor;
              }
            }
          }
        }

        .header__sticky--active & {
          .navigation__link {
            color: $sandColor;

            > span {
              border-color: $sandColor;
            }
          }
        }
      }

      .header__sticky--active & {
        @media (min-width: $screen-desktop-sm) {
          padding: 0;
          width: auto;
        }
      }
    }
  }
}

@keyframes linkHoverIn {
  0% {
    left: 0;
    width: 0;
  }

  100% {
    left: 0;
    width: 100%;
  }
}

@keyframes linkHoverOut {
  0% {
    right: 0;
    width: 100%;
  }

  100% {
    right: 0;
    width: 0;
  }
}
