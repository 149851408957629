@import '../../../bps/components/recipeTeaser/recipeTeaser.scss';

.recipe-teaser {
  &__headline {
    margin: 0 0 20px;
    font-size: 28px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
    }
  }

  &__card {
    .category-page:not(.products-list--no-sidebar) &,
    .products-list-page__content &,
    .teaser-in-column & {
      @media (min-width: $screen-tablet-portrait) {
        display: flex;
        flex-direction: column;
      }

      @media (min-width: $screen-tablet-landscape) {
        flex-direction: row;
      }
    }
  }

  &__drink-name {
    margin: 8px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    line-height: 1.4;

    @media (min-width: $screen-tablet-portrait) {
      padding: 24px;
      font-size: 16px;
    }
  }

  &__ingredients {
    &.grid {
      flex-grow: 2;
      margin: 0 -16px;
      width: calc(100% + 32px);

      > .row {
        > .col-xs-12 {
          padding: 0 16px 16px;

          > * {
            padding: 0 !important;
          }

          p {
            margin: 0 0 12px;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }

  &__image {
    .category-page:not(.products-list--no-sidebar) &,
    .products-list-page__content &,
    .teaser-in-column & {
      @media (min-width: $screen-tablet-portrait) {
        max-height: 300px;

        img {
          max-height: 300px;
        }
      }

      @media (min-width: $screen-tablet-landscape) {
        max-height: none;

        img {
          max-height: none;
        }
      }
    }
  }
}
