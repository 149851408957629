.flag-savings {
  background: transparent;
  height: auto;
  color: $whiteColor;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(.8) translateX(-5%);

  @media (min-width: $screen-desktop) {
    transform: none;
  }

  &__first,
  &__second {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 16px;
    font-weight: $fontWeightBold;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-desktop) {
      font-size: 27px;
      width: 80px;
      height: 80px;
    }

    &-text {
      font-size: 9px;
      font-weight: $fontWeightRegular;
      text-align: center;
      max-width: 60px;
      line-height: 1;

      @media (min-width: $screen-desktop) {
        font-size: 10px;
      }
    }
  }

  &__first {
    background-color: $flagSavingFirstColor;
    background-image: url(../staticImages/flag-savings-first.svg);
    color: #eee1ce;

    > span {
      display: inline;
      margin: 0;
      transform: rotate(15deg) translate(-4px, -2px);
      font-size: 27px;
      font-family: $fontPriceFamily;
      font-weight: $fontWeightRegular;
    }

    .minus {
      display: inline;
      font-family: $fontPrimaryFamily;
      font-weight: $fontWeightRegular;
      content: "-";
      margin: -1px 0 0 -11px;
      position: absolute;
    }

    .percent {
      display: inline-block;
      background-image: url(../staticImages/flag-percent.svg);
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      transform: rotate(-12deg);
      margin: 4px 0 0 -1px;
      position: absolute;
    }
  }

  &__second {
    background-color: $flagSavingSecondColor;
    background-image: url(../staticImages/flag-savings-second.svg);
    font-size: 18px;
    font-family: $fontPriceFamily;
    font-weight: $fontWeightRegular;
    width: 70px;
    height: 70px;
    margin: 0 0 0 -8px;
    color: #da8e50;
    transform: rotate(15deg);
    gap: 2px;

    @media (min-width: $screen-desktop) {
      font-size: 20px;
      width: 80px;
      height: 80px;
    }

    &-text {
      font-family: $fontPrimaryFamily;
    }

    .flag-savings--bigger & {
      font-size: 26px;

      @media (min-width: $screen-desktop) {
        font-size: 32px;
      }
    }
  }
}
