.content-search-results {
  &__headline {
    @include fontSize($fontSizeLarge);
    margin-bottom: 20px;
  }

  &__result {
    width: 100%;
    margin-bottom: 20px;
    text-decoration: none;
    transition: border .2s;
    display: block;
    padding: 5px 0;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $brandPrimaryColor !important;
    }
  }

  &__result-headline {
    text-decoration: underline;
    color: $textBaseColor;
  }

  &__result-text {
    margin: 5px 0;
    color: $textBaseColor;

    .nm-search-result-hit {
      font-weight: $fontWeightSemiBold;
    }
  }
}
