/* stylelint-disable  plugin/no-unsupported-browser-features */

$fontPrimaryFamily: 'Averta', sans-serif;
$fontSecondaryFamily: 'Olga', sans-serif;
$fontHeadlineFamily: 'BlackerPro', serif;
$fontPriceFamily: 'VinaSans', sans-serif;
$fontFamily: $fontPrimaryFamily;

$fontLineHeightRegular: 1.5;

// Font weights
$fontWeightThin: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightBlack: 900;

// Font sizes
$fontSizeTiny: 8px;
$fontSizeXXSmall: 10px;
$fontSizeXSmall: 12px;
$fontSizeSmall: 14px;
$baseFontSize: 16px;
$fontSizeBase: 16px;
$fontSizeMedium: 17px;
$fontSizeXMedium: 18px;
$fontSizeXYMedium: 24px;
$fontSizeXXMedium: 28px;
$fontSizeXXXMedium: 30px;
$fontSizeLarge: 40px;
$fontSizeXLarge: 46px;
$fontSizeXXLarge: 48px;
$fontSizeXXXLarge: 50px;
$fontSizeHuge: 64px;
$fontSizeFont: $baseFontSize;

$fontSizeInputField: 14px;

// Colors
$neutralColor: #9e7957;
$terracottaColor: #c6492e;
$terracottaHoverColor: #cf644d;
$terracottaActiveColor: #d7806d;
$orangeColor: #f86729;
$orangeHoverColor: #f97e49;
$orangeActiveColor: #fa9569;
$brownColor: #b16937;
$brownDarkColor: #6c483c;
$brownMediumColor: #927565;
$brownMediumHoverColor: #a28a7c;
$brownMediumActiveColor: #b39e93;
$brownMediumLightColor: #e6d5bf;
$brownHoverColor: #bd8055;
$brownActiveColor: #c89673;
$beigeColor: #f1e8d7f2;
$redWineColor: #c43a37;
$whiteWineColor: #7db891;
$roseWineColor: #f4ab9b;
$cavaWineColor: #d6b14f;
$blackColor: #3a3732;
$blackHoverColor: #585551;
$blackActiveColor: #757370;
$sandMediumColor: #eee1ce;
$sandColor: #eee1ce;
$sandHoverColor: #f3eadd;
$sandActiveColor: #f8f3eb;
$redColor: #e6001e;
$tealColor: #128484;
$redDarkColor: #603137;
$greenColor: #6b9e59;
$greenLightColor: #008c00;
$grayColor: #8f8c85;
$grayLightColor: #b3b3b3;
$neutralBrightColor: #a68674;
$whiteColor: #fff;
$bioVeganColor: #628f49;

$grayDarkColor: #2f2f2d;
$grayMediumColor: #505050;
$grayLighterColor: #eee;
$goldColor: #d4b78b;
$goldDarkColor: #c39b67;
$sandLighterColor: #dad4c2;
$sandLightColor: #ede9dd;
$sandGrayColor: #e1dbcb;
$sandDark: #ded3bd;
$purpleColor: #563d62;
$dividerColor: #d2bcaf;
$itemBackgroundColor: #d9d9d9;
$brownLightColor: #f7f3ef;

$colorVariationLikeProduct: #e94336;
$colorVariationText: #fff;

$paypalcheckout: #fbc438;
$milesAndMoreColor: #071257;

$brandPrimaryColor: $terracottaColor;
$brandSecondaryColor: $orangeColor;
$brandTertiaryColor: $goldColor;
$brandHighlightColor: $redColor;

$moodOneColor: $goldColor;
$moodTwoColor: $goldColor;
$moodThreeColor: $goldColor;
$moodFourColor: $goldColor;

$availableColor: #628f49;
$laterAvailableColor: #f86729;
$soldOutColor: #c6492e;
$discountColor: #f86729;
$dayOfferColor: $purpleColor;

// Basket
$basketGreenColor: $availableColor;
$basketRedColor: $terracottaColor;
$basketPaypalColor: #ffc439;
$basketPaypalHoverColor: #f1ba37;

// Body
$bodyBackgroundColor: $brownMediumLightColor;

// Status
$errorColor: $terracottaColor;
$warningColor: #eead01;
$waitingColor: $tealColor;
$successColor: #628f49;
$infoColor: $redDarkColor;

// Text
$textLightColor: $sandLighterColor;
$textLightGrayColor: $grayLightColor;
$textGrayColor: $grayDarkColor;
$textMediumGrayColor: $grayMediumColor;
$textBaseColor: $blackColor;
$textDarkestColor: $blackColor;
$textWarningColor: $warningColor;
$textErrorColor: $errorColor;
$textSuccessColor: $successColor;
$textInfoColor: $infoColor;
$textHighlightColor: $brandSecondaryColor;
$textHoverColor: $brandPrimaryColor;

// Borders
$borderLightColor: $grayLighterColor;
$borderWhiteColor: $whiteColor;
$borderDefaultColor: $grayLightColor;
$borderPrimaryColor: $grayColor;
$borderDarkColor: $grayMediumColor;
$borderBlackColor: $blackColor;
$borderAccountAddress: $grayColor;
$borderOnSandColor: rgb(122 117 104 / .3); // special color fitting light and dark sand backgrounds

// Backgrounds
$backgroundWhiteColor: $whiteColor;
$backgroundGreenColor: $successColor;
$backgroundDarkColor: $blackColor;
$backgroundTableColor: $grayLightColor;
$backgroundWarningColor: $warningColor;
$backgroundGrayColor: $grayLighterColor;
$backgroundDarkGrayColor: $grayMediumColor;
$backgroundPrimaryColor: $sandLightColor;
$backgroundSecondaryColor: $sandMediumColor;

// Badgets
$badgetBackgroundColor: $orangeColor;
$badgetTextColor: $textLightColor;
$disruptorBadgetBackgroundColor: $redColor;

// Remove
$removeColor: $brownMediumActiveColor;
$removeHoverColor: $brownMediumHoverColor;

/* stylelint-enable  plugin/no-unsupported-browser-features */
