@import '../../bps/variables/componentConfig.scss';

// Icon
$iconBurgerHeight: 1.5px;
$iconBurgerWidth: 23px;

// Button
$buttonBorderWidth: 0;
$buttonBorderRadius: 6px;
$buttonSecondaryHeightMobile: 34px;
$buttonSecondaryHeightDesktop: 34px;
$buttonCounterWidth: 40px;
$buttonCounterHeight: 40px;
$buttonCounterWidthMobile: 40px;
$buttonCounterHeightMobile: 40px;
$buttonFontSizeMobile: 13px;
$buttonFontSizeDesktop: 16px;
$buttonDarkColor: $orangeColor;
$buttonBackgroundColor: $orangeColor;
$buttonBorderColor: $orangeColor;
$buttonTextColor: $whiteColor;
$buttonHoverBackgroundColor: $orangeHoverColor;
$buttonHoverBorderColor: $orangeHoverColor;
$buttonHoverTextColor: $whiteColor;
$buttonDisabledBorderColor: $grayColor;
$buttonDisabledTextColor: $grayLighterColor;
$buttonShadow: none;
$buttonShadowActive: none;
$buttonPrimaryHeightDesktop: 44px;
$buttonPrimaryBorderColor: $orangeColor;
$buttonPrimaryBackgroundColor: $orangeColor;
$buttonPrimaryHoverBackgroundColor: $orangeHoverColor;
$buttonPrimaryActiveBackgroundColor: $orangeActiveColor;
$buttonSecondaryBorderColor: $sandColor;
$buttonSecondaryBackgroundColor: $sandColor;
$buttonSecondaryTextColor: $textDarkestColor;
$buttonSecondaryHoverBackgroundColor: $sandHoverColor;
$buttonSecondaryHoverTextColor: $textDarkestColor;
$buttonSecondaryActiveBackgroundColor: $sandActiveColor;

// consentCookieBox
$consentCookieBoxButtonAcceptColor: $buttonPrimaryTextColor;
$consentCookieBoxButtonAcceptBackgroundColor: $buttonPrimaryBackgroundColor;
$consentCookieBoxButtonSecondaryHoverBackgroundColor: $brownColor;
$consentCookieBoxButtonSecondaryHoverTextColor: $brownHoverColor;
$consentCookieBoxButtonSecondaryBorderHover: 1px solid transparent;
$consentCookieBoxButtonAcceptHoverBackgroundColor: $buttonPrimaryHoverBackgroundColor;
$consentCookieBoxButtonAcceptHoverTextColor: $buttonPrimaryHoverTextColor;
$consentCookieBoxButtonAcceptBorderHover: $buttonPrimaryBorderColor;

// Select
$selectBorderRadius: 1.8px;

// Input
$inputFieldHeightMobile: 50px;
$inputFieldHeightDesktop: 46px;
$inputFieldFontSizeMobile: 16px;
$inputFieldFontSizeDesktop: 16px;
$inputLabelColor: $grayColor;
$inputLabelFontSize: 10px;
$inputShadow: 0 0 10px 0 rgb(0 0 0 / .25);
$inputTextColor: $blackColor;
$inputIconColor: $brandPrimaryColor;
$inputFieldColor: $blackColor;
$inputBorderColor: $grayLightColor;
$inputFocusBorderColor: $blackColor;
$inputSuccessBorderColor: $blackColor;
$inputBackgroundColor: $whiteColor;
$inputFocusBackgroundColor: $inputBackgroundColor;
$inputSuccessBackgroundColor: $inputBackgroundColor;
$inputErrorBackgroundColor: $inputBackgroundColor;
$inputRadioCheckedColor: $blackColor;
$inputRadioBackgroundColor: $inputBackgroundColor;
$inputFocusColor: $blackColor;
$inputIconValidColor: $successColor;

// Select
$selectTextColor: $textBaseColor;
$selectInactiveColor: $whiteColor;
$selectActiveColor: $textBaseColor;

// Carousel
$carouselButtonBackground: $terracottaColor;
$carouselBorderRadius: 100%;
$carouselButtonPrimaryColor: $sandHoverColor;
$carouselButtonTextColor: $orangeColor;
$carouselButtonBoxShadow: rgb(108 72 60 / .2) 0 0 10px;
$carouselButtonTextShadow: none;
$carouselButtonHoverTextColor: $orangeHoverColor;
$carouselButtonHoverBackgroundColor: #f7f0e7;
$carouselButtonActiveTextColor: $orangeActiveColor;
$carouselButtonActiveBackgroundColor: #faf7f1;
$carouselPaginationBackgroundColor: $orangeColor;
$carouselPaginationHighlightColor: $orangeColor;
$carouselPaginationHoverBackground: $orangeHoverColor;
$carouselPaginationActiveBackground: $orangeActiveColor;
$carouselButtonTextColorDesktop: $whiteColor;

// SaleBox
$carouselPaginationHighlightColor: $goldColor;

// MoodTeaser
$moodTeaserTextColor: $whiteColor;
$moodTeaserHeadlineColor: $whiteColor;

// ProductBox
$productBoxBorder: 0 none;
$productBoxBackgroundColor: $sandHoverColor;
$productBoxListBorder: 3.6px;
$productBoxWhishListBorder: 0;
$productBoxListPadding: 16px;
$productBoxNameColor: $textDarkestColor;
$productBoxShortDescriptionColor: $textDarkestColor;
$productBoxButtonWishlistColor: $textDarkestColor;
$productBoxWishlistColor: $textDarkestColor;
$productBoxHeaderColor: $whiteColor;
$productBoxLoadingColor: $textDarkestColor;
$productBoxStatusSuccessorBackgroundColor: $brownColor;
$productBoxStatusSuccessorColor: $redDarkColor;
$productBoxWideBottleBackground: radial-gradient(circle, $whiteColor 0%, lighten($goldColor, 13%) 100%);
$productBoxLinkColor: $brownMediumColor;
$productBoxLinkFontSize: 10px;
$productBoxStatusBorderRadius: 4px;
$productBoxUnavailableTextTransform: none;

// ServiceTeaser
$serviceHeadlineFontSize: 14px;
$serviceSublineFontSize: 14px;
$serviceIconColor: $grayDarkColor;
$serviceLineColor: $sandMediumColor;
$serviceSublineColor: $grayColor;

// Tags
$tagBorderRadius: 11px;
$tagFontSize: 12px;
$tagFontWeight: $fontWeightRegular;
$tagPaddingRight: 27px;
$tagBackgroundColor: $sandMediumColor;
$tagBorderColor: $sandMediumColor;
$tagBorderHoverColor: $sandLighterColor;
$tagBackgroundHoverColor: $sandLighterColor;
$tagCloseColor: $redDarkColor;

// origin-highlights
$originHighlightsMargin: 0 8px 10px 0;
$originHighlightsBackground: $buttonBackgroundColor;
$originHighlightsPadding: 10px 13px;
$originHighlightsColor: $buttonTextColor;
$originHighlightsBorderRadius: $buttonBorderRadius;
$originHighlightsBorder: 0 none;

// checkbox
$checkboxWidth: 20px;
$checkboxBorder: 1px solid $whiteColor;
$checkboxBorderRadius: 1.8px;
$checkboxLabelPaddingLeftMobile: 24px;
$checkboxLabelPaddingLeftDesktop: 29px;
$checkboxLabelFontSizeMobile: 16px;
$checkboxLabelFontSizeDesktop: 16px;
$checkboxLabelLineHeight: 1.1;
$checkboxBackground: $inputBackgroundColor;
$checkboxLabelColor: $blackColor;
$checkboxCheckedColor: $whiteColor;
$checkboxCheckedBorderColor: $blackColor;

// giftGreetingText
$giftGreetingTextBorderRadius: 4px;
$giftGreetingTextBorder: 1px solid $grayLightColor;
$giftGreetingTextCheckboxColor: $redDarkColor;
$giftGreetingTextBorderRadius: 4px;
$giftGreetingTextBorder: 1px solid $grayLightColor;
$giftGreetingTextCheckboxCheckedColor: $whiteColor;

// availabilityInfo
$availabilityInfoFontMobile: 10px;
$availabilityInfoFontTablet: 12px;
$availabilityInfoFontDesktop: 14px;
$availabilityInfoDotMobile: 6px;
$availabilityInfoDotDesktop: 10px;
$availabilityInfoSpaceLeft: 16px;
$availabilityInfoSpaceMinusLeft: -18px;
$availabilityInfoBold: $fontWeightRegular;

// singleOrderDetails
$singleOrderDetailsFontSizeSmall: 12px;
$singleOrderDetailsFontSizeBase: 14px;
$singleOrderDetailsFontSizeMedium: 16px;
$singleOrderDetailsFontSizeBig: 18px;
$singleOrderDetailsFontSizeBiggest: 30px;
$singleOrderDetailsOrderHeaderBorderTop: 1px solid rgb(146 117 101 / .2);
$singleOrderTableHeaderBorderInUserArea: 1px solid rgb(146 117 101 / .2);
$singleOrderTableProductBorderBottom: 1px solid rgb(146 117 101 / .2);
$singleOrderTableHeaderFontWeight: $fontWeightRegular;
$singleOrderTableHeaderColor: $brownMediumHoverColor;
$singleOrderTableHeaderPaddingInUserArea: 12px 0 2px;
$singleOrderDetailsSectionNameColor: $textDarkestColor;
$singleOrderDetailsStatusColor: $terracottaColor;
$singleOrderDetailsSummaryTextColor: $brownMediumHoverColor;

// $singleOrderCancel
$singleOrderCancelColor: $brownMediumHoverColor;

// accountOrdersList
$accountOrderListPagingMargin: 0 0 8px;

// accountOrdersListView
$accountOrdersListViewBorderColor: rgb(146 117 101 / .2);
$accountOrdersListViewBorderWidth: 1px;
$accountOrdersListViewPadding: 16px 0;
$accountOrdersListViewContentColor: $sandHoverColor;
$accountOrdersListViewContentPadding: 16px;
$accountOrdersListViewContentAvailabilityPadding: 18px;

// accordionList
$accordionListIconColor: $terracottaColor;
$accordionListBorderColor: $terracottaColor;
$accordionListBorderHoverColor: $terracottaColor;
$accordionListHeadlineBackgroundColor: $whiteColor;
$accordionListHeadlineColor: $terracottaColor;
$accordionListContentBackgroundColor: $whiteColor;
$accordionListHeadlineHoverColor: $whiteColor;
$accordionListHeadlineHoverBackgroundColor: $terracottaColor;
$accordionListContentInnerMobilePadding: 20px;
$accordionListContentInnerDesktopPadding: 40px;

// Counter
$buttonCounterWidth: 40px;
$buttonCounterHeight: 40px;
$buttonCounterWidthMobile: 40px;
$buttonCounterHeightMobile: 40px;
$buttonAddToBasketWidth: 40px;
$buttonAddToBasketHeight: 40px;
$buttonCounterBorderRadius: 1.8px;
$buttonCounterHideButtonPosition: 3px;
$buttonCounterShowButtonPosition: -22px;
$buttonCounterLineHeightIncreaseButton: 30px;
$buttonCounterLineHeightDecreaseButton: 30px;
$buttonCounterBackgroundColor: $sandHoverColor;
$buttonCounterBorderColor: $blackColor;
$buttonCounterTextColor: $blackColor;
$buttonCounterHoverBorderColor: $blackColor;
$buttonCounterDisabledTextColor: $blackColor;
$buttonCounterDisabledBorderColor: $grayLighterColor;

// Header
$headerIconColor: $sandColor;
$headerSearchIconColor: $grayColor;

$headerIconBackgroundColor: transparent;
$headerIconHoverColor: $blackColor;
$headerIconHoverBackgroundColor: #f3f3f3;
$loggedInIconColor: $blackColor;
$headerMenuHoverBackgroundColor: #f3f3f3;

// AddToBasket Button
$buttonAddToBasketBackgroundColor: $moodOneColor;
$buttonAddToBasketBorderColor: $moodOneColor;
$buttonAddToBasketTextColor: $whiteColor;
$buttonAddToBasketHoverBackgroundColor: $brandPrimaryColor;
$buttonAddToBasketHoverTextColor: $whiteColor;

// Price
$priceColor: $grayDarkColor;
$priceDiscountColor: $orangeColor;

// Address Suggestion
$addressSuggestionColor: $inputTextColor;
$addressSuggestionBorderColor: $inputTextColor;
$addressSuggestionBackgroundColor: $whiteColor;
$addressSuggestionHoverColor: $whiteColor;
$addressSuggestionHoverBackgroundColor: $terracottaColor;

// HeroSlider
$heroSliderLinkButtonColor: $whiteColor; //$textDarkestColor;
$heroSliderLinkButtonBackgroundColor: $moodOneColor; //rgba(255, 255, 255, .9);
$heroSliderLinkButtonBorderColor: $moodOneColor; //$textLightColor;
$heroSliderDisplayNewFlag: inline;

// ContentStarter Teaser
$contentStarterBoxBackgroundColorMobile: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, #fff 20px, #fff 100%);
$contentStarterBoxBackgroundColorTablet: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, #fff 30px, #fff 100%);
$contentStarterBoxBackgroundColorDesktop: linear-gradient(180deg, rgb(255 255 255 / .75) 0%, #fff 60px, #fff 100%);
$contentStarterBackgroundColor: $backgroundWhiteColor;
$contentStarterHeaderColor: $redDarkColor;
$contentStarterHeaderFontSizeMobile: $fontSizeXYMedium;
$contentStarterHeaderFontWeight: $fontWeightBold;
$contentStarterHeaderFontSizeDesktop: $fontSizeLarge;
$contentStarterSubTitleColor: $grayColor;
$contentStarterSubTitleFontSize: $fontSizeXMedium;
$contentStarterSubTitleFontWeight: $fontWeightRegular;
$contentStarterPublishedFontSize: $fontSizeXSmall;
$contentStarterPublishedColor: $textBaseColor;
$contentStarterSeparatorColor: $brownMediumHoverColor;
$contentStarterSeparatorBorder: 2px solid $contentStarterSeparatorColor;
$contentStarterSeparatorWidth: 74px;
$contentStarterTextFontSize: $baseFontSize;

// SaleBox
$saleBoxWishlistColor: $brandPrimaryColor;

// Rating
$ratingStarsColor: $grayDarkColor;
$ratingEmptyStarsColor: $itemBackgroundColor;
$ratingStarsHoverColor: $blackColor;

// Datepicker
$datePickerDayBackgroundColor: $whiteColor;

// goTop
$goTopWidthMobile: 32px;
$goTopWidthDesktop: 40px;
$goTopColor: $orangeColor;
$goTopBackgroundColor: $carouselButtonHoverBackgroundColor;
$goTopHoverBackgroundColor: $carouselButtonHoverBackgroundColor;
$goTopActiveBackgroundColor: $carouselButtonActiveBackgroundColor;
$goTopBoxShadow: 0 0 10px 0 rgb(108 72 60 / .2);
$goTopHoverColor: $orangeColor;
$goTopFontSizeMobile: 16px;
$goTopFontSizeDesktop: 24px;
$goTopIcon: arrowUp;

// contactPage
$contactPageIconSize: 40px;
$contactPageIconColor: $whiteColor;
$contactPageIconBackground: $terracottaColor;

// Loader
$loaderBackgroundColor: transparent;

// searchAutosuggest
$searchAutosuggestBackgroundColor: $whiteColor;

// Filters
$filtersColor: $redDarkColor; // stylelint-disable-line  plugin/no-unsupported-browser-features
$filtersIconColor: $redDarkColor; // stylelint-disable-line  plugin/no-unsupported-browser-features
$filterSwitchActiveColor: $redDarkColor;

// Grid Backgrounds
$gridBackgroundColor1: linear-gradient(to top, #0001 0, #0000 50px);
$gridBackgroundColor2: $whiteColor;
$gridBackgroundColor3: $sandMediumColor;
$gridBackgroundColor4: $sandHoverColor;
$gridBackgroundColor5: linear-gradient(170deg, $sandLightColor 0%, #fffef9 100%);

// Winefinder
$winefinderButton: #d8d3c1;

// FloatingToolbar
$toolbarBgColor: $brandPrimaryColor;
$toolbarTextColor: $whiteColor;

// Product Award Modul
$productAwardTileTextColor: $textBaseColor;
$productAwardTileLinkColor: $textBaseColor;
$productAwardTileBackground: $whiteColor;
$productAwardTileBoxShadow: none;
$productAwardTileHeadlineColor: $textBaseColor;
$productAwardTileTextColor: $grayDarkColor;
$productAwardTileLinkColor: $grayDarkColor;
$productAwardItemBackground: $whiteColor;
$productAwardItemBoxShadow: none;

// product-order-free-item
$productOrderFreeItemBackgroundColorLegacy: rgb(177 105 55 / .1);
$productOrderFreeItemBackgroundColor: $sandHoverColor;
$productOrderFreeItemDescription3Display: block;
$productOrderFreeItemHeadlineColor: $brownColor;
$productOrderFreeItemNameColor: $textBaseColor;
$productOrderFreeItemShortDescriptionColor: $brownMediumActiveColor;

// scollbars
$customScrollbarThumbColor: $brownMediumColor;
$customScrollbarThumbHoverColor: $brownMediumHoverColor;

// siblingNavigation
$siblingNavigationBackground: linear-gradient(90deg, rgb(255 255 255 / 0) 0%, $sandLightColor 100%);

// productOrderPosition
$productOrderPositionPromotionBg: $greenColor;
$productOrderPositionPromotionText: $whiteColor;
$productOrderPositionRemoveIcon: 'close';
$productOrderPositionNameColor: $textBaseColor;
$productOrderPositionPriceInfoCurrentMobileFontSize: 16px;

// OrderSummary
$orderSummaryFontWeightBold: $fontWeightBold;

// VoucherForm
$voucherFormFontWeightBold: $fontWeightBold;
$voucherFormInfoFontWeight: $fontWeightRegular;
$voucherFormInfoColor: $brownMediumHoverColor;
$voucherFormInputBackground: $inputBackgroundColor;

// CartPage
$cartPageHeaderMarginMobile: 16px 0;
$cartPageHeaderMarginTablet: 40px auto 25px;
$cartPageHeaderMarginDesktop: 40px auto 25px;
$cartPageCurrencyFontSizeProduct: .75em;
$cartPageCurrencyFontSizeTotal: 1em;

// form Headline
$formHeadlineFontSizeMobile: 21px;
$formHeadlineFontSizeDesktop: 32px;
$formHeadlineMarginMobile: 0 0 16px;
$formHeadlineMarginDesktop: 0 0 16px;
$formHeadlineFontWheight: $fontWeightBold;
$formHeadlineColor: $textBaseColor;

// Pagination
$paginationSelectColor: $redDarkColor;
$paginationSelectArrowColor: $redDarkColor;
$paginationButtonTextColor: $redDarkColor;
$paginationButtonBorderColor: $moodOneColor;
$paginationPagesFlexGrow: 3;
$paginationSelectMargin: 0;
$paginationLabelColor: $grayColor;
$paginationPagesCountColor: $grayColor;
$paginationSelectBackgroundColor: $sandHoverColor;

// paymentMethodForm
$paymentMethodFormLabelFontWeight: $fontWeightRegular;
$paymentMethodFormLabelMargin: -8px 0 32px 32px;

// deliveryOptionForm
$deliveryOptionFormPriceDisplay: none;
$deliveryOptionFormLabelFontWeight: $fontWeightRegular;
$deliveryOptionFormForecastTextFontSizeMobile: $fontSizeXSmall;
$deliveryOptionFormForecastTextFontSizeDesktop: $fontSizeSmall;

// checkout
$checkoutFontWeight: $fontWeightSemiBold;
$checkoutBoxContentFontSize: $fontSizeSmall;
$checkoutBoxButtonColor: $goldColor;
$checkoutBoxButtonFontSize: $fontSizeSmall;
$checkoutBoxButtonFontWeight: $fontWeightRegular;
$checkoutBoxFontSize: $fontSizeSmall;
$checkoutTextFontSize: $baseFontSize;
$checkoutSummaryLeftFontSize: $fontSizeMedium;
$checkoutSummaryRightFontSize: $fontSizeXXMedium;
$checkoutSummarySmallFontSize: $fontSizeSmall;
$checkoutStepSummaryHeadlineFontSizeMobile: 24px;
$checkoutStepSummaryHeadlineMarginMobile: 0;
$checkoutStepSummaryHeadlineFontSizeDesktop: 40px;
$checkoutStepSummaryHeadlineMarginDesktop: 0;
$checkoutStepSummaryBoxTextFontSizeMobile: 12px;
$checkoutStepSummaryBoxTextFontSizeDesktop: 13px;
$checkoutStepSummaryFooterTextColor: $brownMediumColor;
$checkoutStepSummaryTextLinkColor: $brownMediumColor;
$checkoutStepSummaryFooterTextFontSize: 13px;
$checkoutStepSummaryCheckboxMaxWidth: 1150px;
$checkoutStepSummaryCheckboxLabelLineHeight: 1.3;
$checkoutStepSummaryCheckboxLabelFontSizeMobile: 15px;
$checkoutStepSummaryCheckboxLabelFontSizeDesktop: 20px;
$checkoutStepSummaryCheckboxDeclarationFontSize: 13px;
$checkoutStepSummaryCheckboxLabelColor: $brownMediumActiveColor;
$checkoutStepSummaryFontSizeMobile: 16px;
$checkoutStepSummaryFontSizeDesktop: 16px;
$checkoutStepSummaryBoxActionPaddingRight: 165px;
$checkoutStepSummaryPaymentMethodNameFontWeight: $fontWeightRegular;

// checkoutSteps
$checkoutStepsColorLegacy: $brownMediumColor;
$checkoutStepsActiveColorLegacy: $orangeColor;

$checkoutStepsColor: $brownMediumActiveColor;
$checkoutStepsActiveColor: $orangeColor;
$checkoutStepsTextColor: $whiteColor;
$checkoutStepsTextLabelFontSizeMobile: 13px;
$checkoutStepsTextLabelFontSizeDesktop: 16px;
$checkoutStepsTextLabelFontWeight: $fontWeightRegular;
$checkoutStepsMobileWidth: 32px;
$checkoutStepsDesktopWidth: 32px;
$checkoutStepsMobileLineHeight: 30px;
$checkoutStepsDesktopLineHeight: 30px;
$checkoutStepsMobileFontSize: 13px;
$checkoutStepsDesktopFontSize: 18px;
$checkoutStepsFontWeight: $fontWeightRegular;
$checkoutStepsLineMobileWidth: 126px;
$checkoutStepsLineDesktopWidth: 126px;
$checkoutStepsMobileTransform: translate(-126px, -38px);
$checkoutStepsDesktoptransform: translate(-131px, -46px);
$checkoutStepsMobileMaxWidth: 270px;
$checkoutStepsDesktopMaxWidth: 334px;
$checkoutStepsStepMinWidth: auto;
$checkoutStepsMobileMargin: 16px 10px;
$checkoutStepsDesktopMargin: 32px;

// orderSummarySmall
$orderSummarySmallFontSize: $fontSizeSmall;
$orderSummarySmallFontWeight: $fontWeightBold;
$orderSummarySmallSmallTextFontWeight: $fontWeightRegular;
$orderSummarySmallSmallTextFontSize: $fontSizeXXSmall;

// $checkoutElement
$checkoutElementBoxBackground: $whiteColor;
$checkoutElementBoxLeftSideBackground: $sandHoverColor;

// checkoutPage
$checkoutPageBackground: transparent;
$checkoutPageColumnLastChildMobileDisplay: none;
$checkoutPageColumnLastChildDesktopDisplay: block;

// directOrderTeaser
$directOrderTeaserHeadlineFontSizeMobile: $fontSizeXYMedium;
$directOrderTeaserHeadlineFontSizeDesktop: $fontSizeXXMedium;
$directOrderTeaserHeadlineFontWeight: $fontWeightBold;
$directOrderTeaserHeadlineBorderBottom: $grayLighterColor 1px solid;
$directOrderTeaserHeadlineDisclaimerFontSize: $fontSizeXSmall;
$directOrderTeaserHeadlineDisclaimerLinkColor: $grayMediumColor;
$directOrderTeaserHeadlineNewsletterCheckFontSize: 16px;
$directOrderTeaserHeadlineNewsletterCheckSmallFontSize: 12px;
$directOrderTeaserHeadlineTextFontSize: 14px;
$directOrderTeaserHeadlineCheckboxLabelFontSize: $fontSizeSmall;
$directOrderTeaserHeadlineCheckboxBoxTop: 2px;
$directOrderTeaserHeadlineErrorFontSize: $fontSizeSmall;
$directOrderTeaserHeadlineLinkColor: $brandPrimaryColor;
$directOrderTeaserElementPaddingOuter: 0 8px;
$directOrderTeaserElementPadding: 8px;

// contentIndex
$contentIndexHeadColor: $textDarkestColor;
$contentIndexTextColor: $textBaseColor;
$contentIndexCountryColor: $textLightColor;
$contentIndexArrowColor: $brandPrimaryColor;

// contentIndexHeader
$contentIndexHeaderBackgroundColor: $backgroundWhiteColor;
$contentIndexHeaderTextColor: $textDarkestColor;
$contentIndexHeaderDisabledColor: $grayLightColor;

// customerService
$customerServiceButtonBarMarginMobile: 0 0 16px;
$customerServiceButtonBarMarginDesktop: 0 0 24px;

// productDescription
$productDescriptionImageDistance: 25px;

// inspirationList
$inspirationListTabTextColor: $grayColor;
$inspirationListTabTextHighlightColor: $redDarkColor;

// similarWinesSuggestTeaser
$similarWinesSuggestTeaserHeadlineFamily: $fontHeadlineFamily;
$similarWinesSuggestTeaserHeadlineSmallFamily: $fontHeadlineFamily;
$similarWinesSuggestTeaserDescriptionFamily: $fontHeadlineFamily;

// onePageCheckoutForm
$onePageCheckoutFormBackground: $backgroundWhiteColor;
$onePageCheckoutFormInputBackground: $inputBackgroundColor;
$onePageCheckoutFormCheckboxLabelFontSize: 14px;

// lightbox
$lightBoxBorderRadius: 10px;

// Newsletter
$newsletterLabelFontSize: 14px;
$newsletterDisclaimerFontSize: 12px;

// infoPopup
$infoPopupFontSize: 10px;
$infoPopupLineHeight: 12px;
$infoPopupPadding: 8px 26px 8px 8px;
$infoPopupColor: $textDarkestColor;
$infoPopupInfoBackground: $whiteColor;
$infoPopupInfoFontSize: 18px;
$infoPopupInfoSize: 17px;

// recipeTeaser
$recipeTeaserTextAlign: left;
$recipeTeaserColor: #fff !important;
$recipeTeaserBackgroundColor: #760940;
$recipeTeaserTextPadding: 25px;
$recipeTeaserFontSize: 16px;
$recipeTeaserHeadlineFontSize: 40px;
$recipeTeaserHeadlineFontWeight: $fontWeightSemiBold;
$recipeTeaserDrinkNameFontSize: 22px;
$recipeTeaserDrinkNameFontFamily: $fontPrimaryFamily;
$recipeTeaserDrinkNameFontWeight: $fontWeightBold;
$recipeTeaserDrinkNameMobileTextAlign: left;
$recipeTeaserDrinkNameDesktopTextAlign: center;
$recipeTeaserIngredientsTitleFontSize: inherit;
$recipeTeaserExtraInfoItemTextAlign: left;
$recipeTeaserExtraInfoItemLineHeight: normal;

// availabilityReminder
$availabilityReminderFontSize: 13px;

//slotMachine
$slotMachineTeaserBackgroundLeft: #ddd;
$slotMachineTeaserBackgroundRight: #ffe9e6;
$slotMachineBtnBackgroundColor: $whiteColor;
$slotMachineVoucherTextColor: #fb5b4f;

//SlotMachineColumn
$slotMachineColumnBorderColor: #ecb748;
$slotMachineColumnItemBorderColor: #c98b25;

// deliveryForecastMessage
$deliveryForecastMessageFontSizeMobile: 13px;
$deliveryForecastMessageFontSizeDesktop: 13px;
$deliveryForecastMessageDeliveryOptionFontWeight: $fontWeightRegular;

// sidePanel
$sidePanelBackground: $sandHoverColor;
$sidePanelCheckoutFormBackground: $sandHoverColor;
$sidePanelMaxWidth: 500px;
$sidePanelCartMaxWidth: 360px;
$sidePanelCheckoutMaxWidth: 460px;
$sidePanelContainerTop: 26px;
$sidePanelCloseIconName: 'close';
$sidePanelCloseIconRight: 8px;
$sidePanelCloseIconTop: 15px;
$sidePanelCloseIconFontSize: 20px;
$sidePanelCloseBackground: radial-gradient(circle, rgb(243 234 221 / 1) 0%, rgb(243 234 221 / 0) 100%);

// registerForm
$registerFormSubHeadlineFontSize: 21px;
$registerFormSubHeadlineMargin: 16px 0 8px;

// registerAddressForm
$registerAddressFormOptionAreaBackground: $sandHoverColor;

// benefitLine
$benefitLineBackgroundColor: $sandMediumColor;
$benefitLineTextColor: $terracottaColor;
$benefitLineFontWeight: $fontWeightThin;
$benefitLineMaxWidth: 1440px;
