.benefit-line {
  --benefit-line-background-color: #{$benefitLineBackgroundColor};
  --benefit-line-text-color: #{$benefitLineTextColor};
  --benefit-line-font-size-tablet: #{$benefitLineFontSizeTablet};
  --benefit-line-font-size-desktop: #{$benefitLineFontSizeDesktop};
  --benefit-line-line-height: #{$benefitLineLineHeight};
  --benefit-line-font-weight: #{$benefitLineFontWeight};
  --benefit-line-padding: #{$benefitLinePadding};
  background: var(--benefit-line-background-color);
  color: var(--benefit-line-text-color);

  &__content {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      @include container($benefitLineMaxWidth);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--benefit-line-padding);
      font-size: var(--benefit-line-font-size-tablet);
      line-height: var(--benefit-line-line-height);
      font-weight: var(--benefit-line-font-weight);
      gap: $benefitLineItemGapTablet;
    }

    @media (min-width: $screen-desktop) {
      font-size: var(--benefit-line-font-size-desktop);
      gap: $benefitLineItemGapDesktop;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: $benefitLineItemGapTablet;

    @media (min-width: $screen-desktop) {
      font-size: var(--benefit-line-font-size-desktop);
      gap: $benefitLineItemGapDesktop;
    }

    .icon {
      display: flex;

      &::before {
        font-size: $benefitLineItemIconSizeMobile;
        color: inherit;

        @media (min-width: $screen-desktop) {
          font-size: $benefitLineItemIconSizeDesktop;
        }
      }
    }
  }
}
