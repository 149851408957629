.flag {
  $size: 56px;
  border-radius: 50%;
  font-size: $fontSizeXXSmall;
  color: $flagTextColor;
  width: $size;
  height: $size;
  text-align: center;
  font-weight: $fontWeightBold;
  margin: 0 auto 4px;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $screen-tablet-portrait) {
    margin: 0 auto 8px;
  }

  &--undefined {
    background: $flagUndefinedColor;
  }

  &--free {
    position: relative;
    border-radius: 0;
    width: 56px;
    height: 22px;
    margin: 0 auto 4px;
    color: $orangeColor;
    text-align: center;
    font-family: $fontPrimaryFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: $fontWeightRegular;
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  &--new {
    background-image: url('../staticImages/flag-new.svg');
    background-size: 100% 100%;
    width: 44px;
    height: 24px;
    border-radius: 0;

    > span {
      display: none;
    }
  }

  &--newVintage {
    background-image: url('../staticImages/flag-new-vintage.svg');
    background-size: 100% 100%;
    width: 56px;
    height: 34px;
    border-radius: 0;

    > span {
      display: none;
    }
  }

  &--bio {
    background-image: url('../staticImages/flag-bio.svg');
    background-size: 100% 100%;
    width: 32px;
    height: 24px;
    border-radius: 0;

    > span {
      display: none;
    }
  }

  &--exclusiveText {
    background-image: url('../staticImages/flag-nur-bei-vinos.svg');
    background-size: 100% 100%;
    width: 66px;
    height: 44px;
    border-radius: 0;

    > span {
      display: none;
    }
  }

  &--link {
    color: $whiteColor !important;
    text-decoration: none !important;
  }

  &--award {
    border-radius: 0;
    $size: 50px;
    width: $size;
    height: $size;
    display: block;

    img {
      width: 100%;
    }
  }

  &--savings {
    background-image: url('../staticImages/flag-discount.svg');
    background-size: 100% 100%;
    color: $sandColor;
    padding: 0;
    display: flex;
    flex-direction: row;
    line-height: 1;
    width: 80px;
    height: 80px;

    > span {
      display: inline;
      margin: -8px 0 0 -4px;
      transform: rotate(15deg) scale(.9);
      font-size: 25px;
      font-family: $fontPriceFamily;
      font-weight: $fontWeightRegular;
    }

    .minus {
      display: inline;
      font-family: $fontPrimaryFamily;
      font-weight: $fontWeightRegular;
      content: "-";
      margin: -1px 0 0 -11px;
      position: absolute;
    }

    .percent {
      display: inline-block;
      background-image: url(/assets/vinos/frontend-core/staticImages/flag-percent.svg);
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      transform: rotate(-12deg);
      margin: 4px 0 0 -1px;
      position: absolute;
    }
  }

  &--image {
    border-radius: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
