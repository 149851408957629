.one-page-navigation {
  $root: &;

  &__wrapper {
    position: relative;
    background: $sandMediumColor;
    transition: top ease-in-out .25s;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 36px;
      background: linear-gradient(to right, rgb(238 225 206 / 0), rgb(238 225 206 / .9)); // stylelint-disable-line  plugin/no-unsupported-browser-features
    }

    #{$root}--sticky & {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / .4);
    }

    .page--scroll-up #{$root}--sticky & {
      top: var(--header-sticky-height);
    }
  }

  &__container {
    overflow-x: auto;
  }

  &__items {
    display: flex;
    padding: 0;
    gap: 32px;

    @media (min-width: $screen-tablet-landscape) {
      gap: 56px;
    }

    &::after {
      // needed for gap behind the last visible nav item
      content: '.';
      visibility: hidden;
    }
  }

  &__item {
    padding: 20px 0;
    font-size: 13px;
    color: $blackColor;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: underline transparent;
    transition: color .2s, text-decoration-color .3s;
    white-space: nowrap;

    &--active,
    &:hover {
      text-decoration: underline $blackColor;
    }

    &--active {
      color: $orangeColor;
    }

    // stylelint-disable-next-line
    .is_safari--old & {
      margin-right: 16px;
    }
  }
}
