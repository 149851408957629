.navigation-flyout {
  $nav-visible-breakpoint: $screen-desktop-sm;

  @media (min-width: $nav-visible-breakpoint) {
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 24px;
    }

    &__categories-list {
      @include clearList();
      margin: 11px 0 0;
      display: flex;
      flex-flow: column;

      &--main {
        display: flex;
        margin-top: 0;
        flex-flow: row;
      }

      &--multi {
        display: flex;
        margin: 0 -8px;
      }
    }

    &__categories-element {
      flex-grow: 1;

      &--main {
        break-inside: avoid; // stylelint-disable-line plugin/no-unsupported-browser-features
        margin-bottom: 0;
        padding: 0 8px;
        position: relative;
        width: calc(100% + 8px);

        &-width_30 {
          width: calc(33% + 8px);
        }
      }

      &--teaser {
        flex-basis: calc(100% / 3);

        img {
          width: 100%;
        }

        &-right {
          width: 62%;
          display: flex;
          justify-content: flex-end;

          .image-text-action-teaser--flyout {
            max-width: 725px;
            width: 100%;
          }

          &-width_foto {
            max-width: 230px;
            max-height: 269px;
            display: none;

            @media (min-width: $screen-desktop) {
              display: block;
            }

            .navigation-flyout-teaser {
              padding: 0;

              &__header {
                font-family: $fontPrimaryFamily;
                font-size: 17px !important;
                line-height: 22px;
              }
            }
          }
        }
      }

      &--more {
        display: none;
        order: 100;
      }

      &.highlight .navigation-flyout__categories-link {
        font-weight: $fontWeightBold;
      }
    }

    &__categories-link {
      display: inline-block;
      font-family: $fontPrimaryFamily;
      font-size: 16px;
      line-height: 17px;
      color: $blackColor;
      text-transform: none;
      text-decoration: none;
      padding: 8px 0 14px;
      width: 100%;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        padding-top: 0;
      }

      &--main {
        color: $orangeColor;
        font-family: $fontPrimaryFamily;
        font-size: 18px;
        line-height: 20px;
        display: inline-block;
        white-space: nowrap;
        text-transform: none;

        &--icon {
          @include iconFont(arrowRight, after);
          font-size: $fontSizeXSmall;
          font-family: $fontPrimaryFamily;
          margin-left: 4px;
          padding-bottom: 0;
          transition: .15s;

          &::after {
            display: inline-block;
            color: $orangeColor;
          }
        }

        &:hover {
          text-decoration: none;
        }

        &:hover &--icon {
          margin-left: 8px;
          text-decoration: none;
        }
      }

      &--all {
        color: $buttonSecondaryBackgroundColor;
        position: relative;
        bottom: -8px;
        font-weight: $fontWeightBold;
        display: none;

        &:hover {
          color: $buttonSecondaryHoverBackgroundColor;
          text-decoration: underline;
        }
      }
    }

    mark {
      background: inherit;
      color: inherit;
    }

    &__list-half {
      width: 50%;
      padding: 0 16px;
    }

    &__categories-element--teaser-center {
      width: 100%;

      .teasergroup_teaser {
        padding: 0;
      }

      .teasergroup_teaser__items-container { // stylelint-disable-line
        display: flex;
        width: calc(100% + (2 * 12px)) !important;
        margin: 0 -12px -24px !important;
        flex-flow: row wrap;
        justify-content: center;
        max-width: none !important;

        .teasergroup_teaser__item {
          flex-grow: 1;
          display: inline-block;
          margin: 0 !important;
          padding: 0 12px 24px !important;

          @media (min-width: $screen-tablet-landscape) {
            &.col-lg-3 {
              width: 33.33%;
            }

            &.col-lg-4 {
              width: 33.33%;
            }

            &.col-lg-6 {
              width: 33.33%;
            }

            &.col-lg-9 {
              width: 33.33%;
            }
          }

          .navigation-flyout-teaser {
            padding: 0 !important;
          }
        }
      }
    }

    .image-text-action-teaser--flyout-image {
      .image-text-action-teaser__header {
        color: $whiteColor;
        font-size: 32px;
        line-height: 1.2;
        margin: 0;
        text-shadow: 0 0 5px rgb(0 0 0 / .1), 0 0 12px rgb(0 0 0 / .1);
      }

      .image-text-action-teaser__box {
        position: absolute;
        width: 100%;
        background: none;
        margin: 0;
        min-height: auto;
        bottom: 0;
        padding: 24px;
      }

      .image-text-action-teaser__button {
        padding: 8px 0 6px;
        font-size: 12px;
      }
    }

    .image-text-action-teaser--flyout {
      .product-teaser,
      .image-text-action-teaser__link {
        display: flex;
        flex-direction: row;
      }

      .product-teaser > .product-teaser__link,
      .image-text-action-teaser__img-wrapper {
        width: 50%;
      }

      .product-teaser__box,
      .image-text-action-teaser__box {
        width: 50%;
        background: none;
        margin: 0;
        padding: 0 8px 0 32px;
        min-height: auto;
      }

      .product-teaser__header,
      .image-text-action-teaser__header {
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 8px;
      }

      .product-teaser__subline,
      .image-text-action-teaser__subline {
        font-size: 14px;
        line-height: 1.3;
        flex-grow: inherit;
        margin: 0 0 24px;
      }

      .product-teaser__subline {
        margin: 0 70px 24px 0;
      }

      .product-teaser__img-product img {
        width: auto;
      }

      .product-teaser__img-wrapper--product img {
        margin: auto;
        max-height: 250px;
      }

      .product-teaser__specification-link {
        order: 5;
        font-size: 12px;
      }

      .product-teaser__flags {
        top: auto;
        bottom: 72px;
        right: 9px;
      }

      .product-teaser__conversion {
        margin-bottom: 2px;
      }

      .price-info--reduction {
        display: flex;
        justify-content: flex-start;
        flex-flow: row-reverse wrap;
      }

      .product-teaser__price {
        .price-info__old {
          flex: 1 2 auto;
          margin-top: 28px;
        }

        .price-info__unit {
          flex-basis: 100%;
        }
      }

      .product-teaser__flags--flag2 {
        display: none;
      }
    }

    &__footer {
      width: 100%;
      padding-top: 25px;
      border-top: 1px solid $borderLightColor;
    }

    &__footer-link {
      display: inline-block;
      font-size: $baseFontSize;
      color: $textBaseColor;
      text-decoration: none;

      &:hover,
      &:visited {
        color: $textBaseColor;
        text-decoration: none;
      }

      &:hover {
        color: $textHoverColor;
      }

      i {
        font-size: $baseFontSize;
        color: $textHoverColor;
        margin-left: 8px;
      }
    }
  }

  .burger-navi-copy-from {
    display: none;
  }

  .navigation-flyout-teaser__header {
    font-family: $fontPrimaryFamily;
    text-transform: none;
    font-size: 21px;
    font-weight: $fontWeightMedium;
    line-height: 27px;

    &-link {
      @include iconFont(arrowRight, after);

      &:hover::after {
        margin-left: 8px;
      }

      &::after {
        display: inline-block;
        margin-left: 4px;
        font-size: 12px;
        transition: .15s;
      }

      .black {
        display: inline-block;
        color: $blackColor;
      }
    }

    &-link:has(>.black) { // stylelint-disable-line
      &::after {
        color: $blackColor;
      }
    }
  }

  .grid-container {
    display: grid; // stylelint-disable-line
    width: 100%;
    // stylelint-disable-next-line
    grid-template-areas:
      "a b c d e"
      "a b c d e";

    @media (min-width: $screen-desktop) {
      // stylelint-disable-next-line
      grid-template-areas:
        "a b c e"
        "a b d e";
    }

    .navigation-flyout__categories-element--main {
      width: auto;
      min-width: 195px;

      @media (min-width: $screen-desktop) {
        width: 210px;
      }
    }
  }

  .grid-item-1 {
    grid-area: a; // stylelint-disable-line

    @media (min-width: $screen-desktop) {
      .navigation-flyout__categories-element--main {
        width: 250px;
      }
    }
  }

  .grid-item-2 {
    grid-area: b; // stylelint-disable-line

    @media (min-width: $screen-desktop) {
      .navigation-flyout__categories-element--main {
        width: 250px;
      }
    }
  }

  .grid-item-3 {
    grid-area: c; // stylelint-disable-line
  }

  .grid-item-4 {
    grid-area: d; // stylelint-disable-line

    span {
      @media (min-width: $screen-desktop) {
        padding-top: 32px;
      }
    }
  }

  .grid-item-5 {
    grid-area: e; // stylelint-disable-line
  }

  .grid-item-6 {
    grid-area: f; // stylelint-disable-line
  }
}
