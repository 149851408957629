.personalization,
.html-block .personalization {
  h2,
  h3 {
    display: inline-block;
    width: auto;
    line-height: 1.4;
    text-align: center;
  }

  > .headline--type_h2 {
    padding: 0;
  }

  em {
    font-size: 1.5em;
    font-style: normal;
    font-family: $fontSecondaryFamily;
    font-weight: 300;
    line-height: 1;
  }

  &-banner {
    display: block;
    width: 100%;
    min-height: 35px;
    position: relative;
    z-index: 1;
    background: $terracottaColor;
    color: $sandColor;
    text-align: center;
    margin: 0 auto;
    padding: 8px 24px;
    font-size: 14px;
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    line-height: 1.3;
    border-radius: 3.6px;

    &::after {
      position: absolute;
      right: 48px;
      bottom: -13px;
      content: "";
      width: 0;
      height: 0;
      border-top: 14px solid $terracottaColor;
      border-right: 16px solid transparent;
    }
  }

  &-headline {
    width: 100%;
    text-align: center;
  }

  &-headline-teaser {
    background: $orangeColor;
    color: $blackColor;
    font-size: 30px;
    line-height: 1.4;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    border-radius: 4px;
    padding: 2px 24px;
    position: relative;

    @media (min-width: $screen-laptop) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 32px;
      padding: 8px 12px;
    }

    @media (min-width: $screen-desktop) {
      padding: 2px 12px;
    }

    .products-list--no-sidebar & {
      margin-bottom: -4px;

      @media (min-width: $screen-tablet-portrait) {
        margin-bottom: -2px;
      }

      @media (min-width: $screen-desktop) {
        margin-bottom: -18px;
      }
    }

    h2,
    h3 {
      color: $blackColor;
      display: flex;
      justify-content: flex-end;
      width: auto;
      font-size: 30px;
      line-height: 1.4;
      margin: 4px 16px 0 0;
      text-align: center;
      font-family: $fontSecondaryFamily;
      font-style: normal;
      font-weight: $fontWeightThin;

      @media (min-width: $screen-laptop) {
        text-align: left;
        font-size: 32px;
        margin: 4px 32px 4px 0;
        width: 40%;
      }
    }

    p {
      display: inline-block;
      margin: 0 8px 8px;
      text-align: center;
      color: $sandHoverColor;
      font-weight: $fontWeightThin;
      font-size: 13px;

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 8px;
        text-align: left;
      }

      @media (min-width: $screen-laptop) {
        font-size: 17px;
        width: 60%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      border-width: 10px;
      border-style: solid;
      border-color: $orangeColor transparent transparent transparent;
      transform: rotate(270deg);
      width: 16px;
      height: 14px;
      z-index: -1;
    }
  }

  &--no-space-bottom {
    margin: 0 0 -20px;
  }
}
