.address-box {
  font-style: normal;
  width: 100%;

  @media (min-width: $screen-tablet-portrait) {
    flex-grow: 2;
  }

  &__line {
    display: block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &--checkout {
    margin: 15px 0 20px;
  }

  &__logo {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    span {
      display: block;
    }

    .sprite-forecast-dhl-packstation {
      @extend .sprite-packstation_small;
    }

    .sprite-forecast-dhl-paketshop {
      @extend .sprite-paketshop;
    }
  }
}
