.footer {
  $root: &;
  position: relative;
  background: var(--footer-background);
  z-index: $indexFooter;

  .checkout-layout & {
    z-index: inherit;
  }

  &__area-newsletter {
    text-align: center;

    @media (min-width: $screen-desktop) {
      text-align: left;
    }

    form {
      display: flex;
      position: relative;
      max-width: 350px;
      margin: 0 auto;

      @media (min-width: $screen-desktop) {
        margin: 0;
        max-width: none;
      }
    }

    input,
    button {
      border: $sandMediumColor solid 1.4px;
      border-radius: 3px;
      height: 40px;
      color: $sandMediumColor;
    }

    input {
      padding: 8px 40px 8px 8px;
      background: #0000;
      width: 100%;

      &::placeholder {
        color: $sandMediumColor;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px var(--footer-background) inset;
        -webkit-text-fill-color: $sandMediumColor;
      }
    }

    button {
      @include iconFont(arrowRightBold, before) {
        color: $terracottaColor;
        transition: transform .2s ease-in-out;
        margin: 0 -2px 0 0;
      }
      width: 40px;
      line-height: 38px;
      background: $sandMediumColor;
      position: absolute;
      right: 0;
      cursor: pointer;

      &:hover::before {
        transform: scale(1.1);
      }
    }
  }

  &__area-contact {
    margin: 24px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 24px;
    }
  }

  &__area-trustpilot {
    margin: 24px 0 16px;

    @media (max-width: $screen-tablet-portrait) {
      h4 {
        display: none !important;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }

    .trustpilot-box {
      margin: 0;

      @media (max-width: $screen-mobile-landscape) {
        max-width: none;
        columns: 2; // stylelint-disable-line  plugin/no-unsupported-browser-features
      }
    }
  }

  &__area-awards {
    margin: 32px 0 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: $sandMediumColor;
    font-weight: $fontWeightThin;
  }

  &__award {
    font-size: 13px;
    line-height: 130%;
    text-align: center;
    width: 33%;

    &--half {
      width: 50%;
    }
  }

  &__award-img-container {
    height: 63px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__award-title {
    margin: 6px 0 0;
  }

  &__award-descr {
    font-size: 11px;
  }

  &__row {
    display: flex;
    padding: 24px 5px 40px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    min-height: 60px;

    @media (min-width: $screen-mobile) {
      padding: 24px 16px 40px;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding: 32px 20px 40px;
    }

    @media (min-width: $screen-desktop-sm) {
      min-height: 120px;
      padding: 40px 40px 54px;
      align-items: flex-start;
    }

    @media (min-width: $screen-desktop) {
      padding: 0 40px 54px;

      & .row:first-child .col-xs-12 {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
      }
    }
  }

  &__sprites {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--top {
      margin-top: -8px;
    }

    > i {
      margin: 0 8px 8px 0;
    }

    &--bottom {
      margin-bottom: 8px;

      > i {
        margin: 0 2px 2px 0;
        outline: 2px solid $terracottaColor;
        outline-offset: -2px;
        border-radius: 8px;
      }
    }
  }

  &__area-social {
    margin-top: 12px;

    a {
      text-decoration-color: #0000 !important;

      &::before {
        font-size: 25px;
      }
    }

    &-fb {
      @include iconFont(facebook, before);
    }

    &-ig {
      @include iconFont(instagram, before);
    }
  }

  &__area-box {
    display: flex;
    margin: 0;
    gap: 16px;
    flex-direction: column;

    h4 {
      text-align: center;

      @media (min-width: $screen-tablet-portrait) {
        text-align: left;
      }
    }
  }

  &__area-checkout {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin: 0 0 40px;

    @media (min-width: $screen-tablet-landscape) {
      gap: 80px;
    }

    @media (min-width: $screen-desktop) {
      margin: 0;
    }

    #{$root}__area-awards,
    #{$root}__area-trustpilot {
      margin: 0;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--row {
      flex-direction: row;
    }

    & i {
      display: inline-block;
      margin: 0 8px 0 0;
      vertical-align: middle;
    }
  }

  .grid {
    margin: 0;
    padding: 0;

    > .row {
      align-items: stretch;

      > .col-lg-1,
      > .col-lg-2,
      > .col-lg-3,
      > .col-lg-4,
      > .col-lg-5,
      > .col-lg-6,
      > .col-lg-7,
      > .col-lg-8,
      > .col-lg-9,
      > .col-lg-10,
      > .col-lg-11,
      > .col-lg-12 {
        width: 100%;

        @media (min-width: $screen-tablet-portrait) {
          width: auto;
          flex-grow: 1;
          min-width: 140px;
        }
      }

      @media (min-width: $screen-tablet-portrait) {
        > .col-lg-2 {
          max-width: calc(100% / 4);
        }

        > .col-lg-4 {
          min-width: 100%;

          & > * {
            max-width: 600px;
            margin: 0 auto;
          }
        }
      }

      @media (min-width: 800px) and (max-width: 1200px) {
        > .col-lg-4 {
          & > .html-teaser {
            display: flex;
            justify-content: center;
            max-width: none;
            margin-bottom: 16px;

            & > * {
              width: 50%;
            }

            h3 {
              font-size: 24px;
            }

            .footer__area-awards {
              margin: 16px 0 24px;
            }
          }
        }
      }

      @media (min-width: $screen-desktop) {
        > .col-lg-2 {
          max-width: calc(100% / 6);
        }

        > .col-lg-4 {
          min-width: 0;
          max-width: calc(100% / 3);
          padding: 0 16px 0 0;

          & > * {
            max-width: 100%;
          }
        }
      }

      &:first-child {
        width: 100%;
        max-width: $maxWidthInner;
        margin: 0 auto;

        @media (min-width: $screen-tablet-portrait) {
          > .col-xs-12:not(:first-child) {
            padding: 0 4px;
            border-right: 1.7px solid $sandMediumColor;
          }

          > .col-xs-12:nth-child(2) {
            border-left: 1.7px solid $sandMediumColor;
          }
        }

        @media (min-width: $screen-desktop) {
          > .col-xs-12:not(:first-child) {
            padding: 0 8px;
          }
        }
      }

      &:last-child {
        border-top: 1.7px solid $sandMediumColor;

        @media (max-width: $screen-mobile) {
          margin: 0;
        }

        @media (min-width: $screen-tablet-portrait) {
          margin: 0 -20px;
        }

        @media (min-width: $screen-desktop-sm) {
          margin: 0 -40px;
        }

        & > * {
          @include container();
        }
      }
    }
  }

  .html-block {
    font-size: $baseFontSize;
    color: $whiteColor;
    padding: 0 5px;
    text-align: left;

    & > .html-block {
      padding: 0;
    }

    h3,
    h4 {
      color: $sandMediumColor !important;
      line-height: 1.1;
    }

    h3 {
      font-family: $fontHeadlineFamily;
      font-size: 18px;
      margin: 0 0 16px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 32px;
        margin: 0 0 24px;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: $fontWeightMedium;
      margin: 0;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 18px;
      }

      @media (min-width: $screen-desktop-sm) {
        font-size: 21px;
      }
    }

    p {
      font-size: 11px;
      line-height: 1.4;
      color: $sandMediumColor;
      margin: 0 0 12px;
      font-weight: $fontWeightThin;

      &:last-child {
        margin-bottom: 0;
      }

      strong,
      b {
        margin: 0;
        color: $whiteColor;
        font-weight: $fontWeightSemiBold;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0 16px;
        margin: 0;
        line-height: 25px;
        text-align: left;

        @media (min-width: $screen-tablet-portrait) {
          line-height: 23px;
          padding: 0;
        }
      }
    }

    a,
    a:not(.button) {
      font-weight: $fontWeightThin;
      text-decoration: none;
      color: $sandMediumColor;
      font-size: 13px;
      line-height: 1.4;
      display: inline-block;
      position: relative;
      padding: 0;

      &:visited {
        color: $sandMediumColor;
      }

      &:hover {
        color: $sandMediumColor;
        text-decoration: underline;
      }
    }
  }

  &__arrow-link {
    @include iconFont(arrowRight, after);

    &::after {
      display: inline-block;
      padding-left: 7px;
      font-size: 8px;
      text-decoration: none;
    }
  }

  // checkout footer
  &__c-rating {
    margin: 32px 0 40px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 8px 0 24px;
      text-align: center;
    }

    .trustpilot-box {
      @media (min-width: $screen-tablet-portrait) {
        margin: 16px auto 0;
      }
    }

    img {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  &__c-links {
    li {
      padding: 0 !important;
    }

    a {
      font-size: $baseFontSize !important;
      line-height: 30px !important;
    }

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: center;

      li {
        padding: 0 24px !important;
      }

      a {
        font-size: $baseFontSize !important;
        line-height: 1 !important;
      }
    }
  }

  .accordion {
    @media (min-width: $screen-tablet-portrait) {
      &:last-child {
        margin-bottom: 16px;
      }

      & + .accordion {
        margin-top: 16px;
      }
    }

    &__headline {
      padding: 12px 0;

      &::after {
        color: $sandMediumColor;
        font-size: 20px;
      }

      @at-root .footer .accordion--open {
        .accordion__headline {
          border-bottom: $sandMediumColor solid 1px;
        }

        .accordion__content {
          transition: max-height .2s cubic-bezier(.4, 1, .5, 1), padding .2s cubic-bezier(.4, 1, .5, 1);
          padding: 8px 0;
          box-sizing: content-box;
        }
      }
    }

    &__anchor {
      display: none;
    }

    @media (min-width: $screen-tablet-portrait) {
      &__headline {
        border: 0 !important;
        cursor: auto;

        &::after {
          display: none;
        }
      }

      &__content {
        max-height: none !important;
        padding: 0;
        border: 0 !important;
      }
    }
  }

  &__no-accordion {
    h4 {
      padding: 12px 0;
    }
  }

  &__link-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 8px 0;

    & > a {
      padding: 8px !important;

      @media (min-width: $screen-tablet-landscape) {
        padding: 8px 16px !important;
      }
    }
  }
}
