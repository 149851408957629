.products-sort {
  position: relative;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  &__label {
    position: relative;
    top: 12px;
    left: 16px;
    font-size: 13px;
    color: $blackColor;

    @media (max-width: $screen-mobile-landscape-max) {
      display: none;
    }

    @media (min-width: $screen-tablet-portrait) {
      position: static;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: 18px;
    }
  }

  .select {
    color: $blackColor;

    &__wrapper::before {
      color: $blackColor;
    }

    &__custom-dropdown {
      min-width: 250px;
    }

    &__selected-value {
      font-size: 11px;

      @media (min-width: $screen-tablet-landscape) {
        font-size: $fontSizeMedium;
      }
    }

    &__custom-option {
      font-size: $baseFontSize;

      &--selected {
        font-weight: $fontWeightRegular;
      }
    }
  }
}

// PLACEHOLDER STYLES

/* stylelint-disable-next-line */
products-sort,
[is="products-sort"] {
  @media (min-width: $screen-tablet-portrait) {
    display: none;
  }
}
