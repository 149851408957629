.process-teaser {
  padding: 24px 0;

  &--space-around {
    margin: 32px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px 0;
    }
  }

  &--space-bottom {
    margin: 0 0 32px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 40px;
    }
  }

  &--white {
    background: $whiteColor;
  }

  &--sand-color {
    background: $sandHoverColor;
  }

  &__headline {
    line-height: 1.4;
    text-align: center;
    padding: 0 16px 16px !important;
    margin: 0 !important;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 16px 32px !important;
    }

    &.headline {
      font-size: 18px !important;
      color: $orangeColor !important;
      font-weight: $fontWeightBold !important;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 28px !important;
      }
    }

    &--averta {
      font-family: $fontPrimaryFamily !important;
    }
  }

  &--confirmation {
    padding: 16px 0 48px;

    .process-teaser__headline.headline {
      padding: 16px !important;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
      justify-content: center;
      padding: 0 24px;
    }
  }

  &__block {
    padding: 0 24px;
    font-size: 12px;
    line-height: 1.4;
    font-weight: $fontWeightBold;
    max-width: 280px;

    @media (min-width: $screen-tablet-portrait) {
      flex-grow: 1;
      width: 15%;
      padding: 0;
      align-self: flex-start;
      max-width: none;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: 14px;
      max-width: 200px;
    }

    a {
      font-weight: $fontWeightBold !important;
      text-decoration: underline !important;
      font-size: 12px !important;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 14px !important;
      }
    }

    &--bigger {
      @media (min-width: $screen-tablet-portrait) {
        width: 20%;
        max-width: 250px;
      }
    }
  }

  &__icon {
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
      display: flex;
      font-size: 14px;
      line-height: 1.4;
      font-weight: bold;
      height: 76px;
      justify-content: center;
      flex-flow: column;
    }

    &::before {
      font-size: 40px;
      color: $brownMediumColor;
    }

    &--circle {
      font-size: 37px;
      line-height: 42px;
      font-weight: bold;
      width: 58px;
      height: 58px;
      border-radius: 100%;
      border: 5px solid $brownMediumColor;
      background: $sandHoverColor;
      color: $brownMediumColor;
      margin: 16px auto;
      padding: 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-flow: column;
      }

      > span {
        @media (min-width: $screen-tablet-portrait) {
          margin: -6px 0 0;
        }
      }
    }

    &.icon--bigger,
    &.icon--present,
    &.icon--confirmationBox,
    &.icon--confirmationTracking,
    &.icon--confirmationHome {
      &::before {
        font-size: 60px;
      }
    }

    &.icon--confirmationMail {
      &::before {
        font-size: 40px;
      }
    }

    &.icon--confirmationVersand {
      &::before {
        font-size: 38px;
      }
    }
  }

  &__divider {
    padding: 24px 0 16px;
    display: block;

    @include iconFont(confirmationArrow) {
      font-size: 10px;
      padding: 8px 0;
      color: $grayDarkColor;
      transform: rotate(90deg);

      @media (min-width: $screen-tablet-portrait) {
        transform: none;
      }

      @media (min-width: $screen-tablet-landscape) {
        padding: 8px;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      align-self: flex-start;
      margin: 8px 0 0;
    }
  }

  &__button-container {
    text-align: center;
    padding: 16px;
    margin: 16px 0 -24px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 32px 24px 0;
    }

    .button {
      max-width: 100%;
    }
  }

  &__small-text {
    font-size: 10px;
    line-height: 1.2;
    padding: 8px 0 0;

    a {
      font-size: 10px !important;
      line-height: 1.2;
    }
  }
}

.process-teaser .process-teaser__headline {
  &--orange {
    color: $orangeColor;
  }
}
