@import "../../../bps/components/availabilityInfo/availabilityInfo.scss";

.availability-info {
  &__label {
    &--later-available {
      color: $laterAvailableColor;

      .availability-info--label::before {
        background-color: $laterAvailableColor;
      }
    }
  }
}
