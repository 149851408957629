.lazy-load {
  position: relative;
  min-height: 30px;
}

/* stylelint-disable-next-line */
lazy-load,
[is="lazy-load"] {
  display: inline-block;
  min-width: 40px;
  min-height: 30px;
}
