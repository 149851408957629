.newsletter-form {
  $root: &;

  .form {
    &__text {
      margin: 0;

      &.newsletter-form__declaration {
        margin-top: 16px;
      }
    }

    &__group {
      margin-bottom: 16px;
      font-size: $fontSizeXSmall;

      &--row {
        margin: 0;
        padding: 0;
      }
    }

    &__col-6 {
      padding: 0;
      margin-bottom: 16px;
      margin-right: 8px;
      width: calc(100% - 8px);

      @media (min-width: $screen-tablet-portrait) {
        width: calc(50% - 8px);
      }

      &:first-child {
        @media (min-width: $screen-tablet-portrait) {
          margin-right: 8px;
        }
      }
    }

    &__col-7 {
      padding: 0;
      margin-bottom: 16px;

      @media (min-width: $screen-mobile-landscape) {
        width: calc((100% / (12 / 7)) - 8px);
      }

      &:first-child {
        @media (min-width: $screen-mobile-landscape) {
          margin-right: 8px;
        }
      }
    }

    &__col-5 {
      padding: 0;
      margin-bottom: 16px;

      &#{$root}__button-container {
        margin-bottom: 8px;
      }

      &:first-child {
        @media (min-width: $screen-mobile-landscape) {
          margin-right: 8px;
        }
      }
    }

    &__col-12 {
      display: flex;
      margin-bottom: 24px;
      padding: 0;

      .radio-input {
        width: auto;
      }
    }
  }

  #{$root}__row {
    &--no-bottom-space {
      margin-bottom: 0;
    }
  }

  &__button-container {
    margin-bottom: 8px;

    .newsletter-form__button {
      width: 100%;
    }
  }

  &__button {
    min-width: auto;
    width: 100%;
    height: 44px;
    line-height: 45px;
    font-weight: $fontWeightThin;
  }

  &__declaration {
    padding: 0;
    font-size: 11px;
    line-height: 12px;
    margin-top: 16px;
    display: block;
    color: $brownMediumHoverColor;

    a:not(.button) {
      font-size: 11px;
      color: $brownMediumHoverColor;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: 13px;
      line-height: 14px;

      a:not(.button) {
        font-size: 13px;
      }
    }
  }

  &__mandatory {
    font-size: 11px;
    color: $brownMediumHoverColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 13px;
    }
  }
}
