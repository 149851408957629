.breadcrumbs {
  --breadcrumbs-color: #{$blackColor};
  list-style: none;
  margin: 12px auto 8px;
  display: none;
  z-index: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include container();

  .page--search-list.product-list--max-list-columns-4 &,
  .page--category-list.product-list--max-list-columns-4 & {
    max-width: $maxWidthProductlist;
  }

  @media (min-width: $screen-tablet-portrait) {
    display: block;
  }

  @media (min-width: $screen-desktop) {
    .page-detail-view & {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &--mood-teaser & {
    --breadcrumbs-color: #{$whiteColor};
  }

  &__wrapper {
    z-index: 2;

    &.breadcrumbs--mood-teaser {
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }

  &__wrapper--hidden {
    .breadcrumbs {
      @include visually-hidden();
    }

    .page--category-list &,
    .page--content-page &,
    .page--landing-page &,
    .page--category-landingpage & {
      margin-bottom: 0;
    }

    .page-detail-view & {
      @media (min-width: $screen-tablet-portrait) {
        height: 16px;
      }

      @media (min-width: $screen-desktop-xl) {
        height: 32px;
      }
    }
  }

  &__item {
    display: inline-block;
    position: relative;
    padding-right: 20px;
    margin-right: 7px;
    padding-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include iconFont(arrowRight, after) {
      position: absolute;
      right: -4px;
      top: 1px;
      color: var(--breadcrumbs-color);
      transform: scale(.75);
    }

    &:last-child {
      padding-left: 0;
      margin-left: 0;

      &::after {
        content: none;
      }
    }
  }

  &__link {
    text-decoration: none;
  }

  &__link-name {
    @include fontSize($fontSizeSmall);
    color: var(--breadcrumbs-color);
  }

  .page--login & {
    display: none;
  }
}
