.scroll-to-content-button {
  display: inline-block;
  letter-spacing: 2px;
  color: $scrollToContentButtonColor;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.4;

  @include iconFont(arrowRight, after) {
    font-size: 10px;
    padding-left: 5px;
    transition: transform .15s ease-in-out;
  }

  &:hover {
    &::after {
      transform: translateX(4px);
    }
  }

  .page-intro--mood & {
    text-shadow: 0 0 5px rgb(0 0 0 / .4), 0 0 12px rgb(0 0 0 / .2);
  }

  &__wrapper {
    min-height: 19px;
    margin: 0 auto;

    .theme-light-premium &,
    .theme-dark-premium & {
      min-height: 32px;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 44px;
      }
    }
  }
}

/* stylelint-disable-next-line */
scroll-to-content-button,
[is="scroll-to-content-button"] {
  background-color: transparent !important;
}
