.localisation-layer {
  padding-bottom: 8px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1400;
  background: $sandHoverColor;

  @media (min-width: $screen-mobile-landscape) {
    max-width: 597px;
  }

  &__overlay {
    z-index: 1399;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / .8);
  }

  &__container {
    padding: 32px 24px 28px;
    font-size: 18px;
    line-height: 1.4;

    @media (min-width: $screen-mobile-landscape) {
      padding: 40px 32px 36px;
    }
  }

  &__logo-container {
    text-align: left;
    display: block;
    padding: 32px 0 0 24px;

    @media (min-width: $screen-mobile-landscape) {
      text-align: center;
      padding: 32px 0 0;
    }

    .logo {
      width: $localisationLayerLogoWidth;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    padding: 0 24px 24px;
    width: calc(100% + 16px);
    margin: 0 -8px;

    @media (min-width: $screen-mobile-landscape) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 32px 24px;
    }
  }

  &__checkbox-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px;
    font-size: 18px;

    @media (min-width: $screen-mobile-landscape) {
      padding: 0 32px 24px;
    }

    .checkbox__box {
      left: 0;
      top: 3px;
    }

    .checkbox__label {
      font-size: 16px;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 356px;
    min-height: 55px;
    min-width: auto;
    color: $blackColor;
    background: $localisationLayerButtonBackground;
    border: 2px solid $orangeColor;
    border-radius: 2px;
    margin: 0 8px 8px;
    padding: 0 24px;
    text-transform: uppercase;
    justify-content: center;

    @media (min-width: $screen-mobile-landscape) {
      margin: 0 8px;
      max-width: 256px;
    }

    &:hover {
      color: $whiteColor !important;
      background: $orangeColor !important;
      border: $orangeColor !important;
    }

    &-country {
      width: 24px;
      height: 18px;
      border-radius: 2px;
      margin: 0 14px 0 0;
      position: relative;

      &::before,
      &::after {
        position: absolute;
        content: "";
      }

      &--de {
        background: #ffd018;

        &::before {
          width: 24px;
          height: 6px;
          left: calc(50% - 12px);
          top: calc(50% - 3px);
          background: #e31d1c;
        }

        &::after {
          width: 24px;
          height: 6px;
          left: calc(50% - 12px);
          top: calc(50% - 9px);
          background: #272727;
        }
      }

      &--ch {
        background: #e31d1c;

        &::before {
          width: 3px;
          height: 9px;
          background: #f1f9ff;
          transform: rotate(-90deg);
          left: calc(50% - 1.5px);
          top: calc(50% - 4.5px);
        }

        &::after {
          width: 3px;
          height: 9px;
          background: #f1f9ff;
          left: calc(50% - 1.5px);
          top: calc(50% - 4.5px);
        }
      }
    }
  }

  .lightbox__close-btn {
    right: 20px;
    top: 20px;
    color: $redDarkColor;
    text-decoration: none;
    background: transparent;
    border: 0 none;
  }
}
