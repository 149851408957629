.html-block {
  $root: &;
  font-size: $fontSizeSmall;
  color: $textDarkestColor;
  font-family: inherit;
  margin: 0;

  &--spacing-top-bottom {
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    color: $textBaseColor;
    font-weight: $fontWeightRegular;
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 18px;
    }

    &:first-child {
      margin-top: 0;
    }

    &.html-block--type-h1 {
      font-size: 36px;
      color: $orangeColor;
      margin: 0 0 16px;
    }

    &.html-block--type-h2 {
      font-size: $fontSizeXXMedium;
    }

    &.html-block--type-h3 {
      @include fontSize($fontSizeXMedium);
    }

    &.html-block--type-h4,
    &.html-block--type-h5,
    &.html-block--type-h6 {
      @include fontSize($baseFontSize);
    }
  }

  h1 {
    font-size: 36px;
    color: $orangeColor;
    margin: 0 0 16px;
  }

  h2 {
    font-size: $fontSizeXMedium;
    font-weight: $fontWeightBold;
    line-height: 1.2;
    margin-bottom: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXYMedium;
      line-height: 1.2;
      margin-bottom: 8px;
    }
  }

  h3 {
    @include fontSize($fontSizeXMedium);
    margin-bottom: 4px;
    font-weight: $fontWeightBold;
  }

  h4,
  h5,
  h6 {
    @include fontSize($baseFontSize);
  }

  p {
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    font-size: 13px;
    line-height: 1.3;
    margin: 0 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 15px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }

    strong,
    b {
      margin: 0;
    }
  }

  strong {
    font-weight: $fontWeightBold;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    line-height: 1.4;
    font-size: $fontSizeMedium;
    border-left: 5px solid $borderLightColor;
  }

  a:not(.button) {
    color: inherit;
    text-decoration: underline;
    display: inline-block;
    position: relative;
  }

  hr {
    border: 0;
    border-bottom: $brownMediumLightColor 1px solid;
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 24px 0;
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 16px 0 16px 24px;
    font-size: 12px;
    line-height: 130%;
    color: $textBaseColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 14px;
      margin: 16px 0 16px 32px;
    }

    li {
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }
    }

    &.ul--checkmark {
      li {
        margin: 0 0 16px;
      }
    }

    &.ul--letter {
      list-style: none;
      margin-left: 18px;

      li {
        margin: 0 0 16px;
      }
    }
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &__content-section,
  &__content-row {
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 24px 0;
      flex-direction: row;
    }
  }

  &__content-row--big {
    margin: 32px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px 0;

      #{$root}__content-col {
        padding: 0 24px;
      }
    }
  }

  &__content-row {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
    }

    img {
      width: 100%;
    }
  }

  &__content-image {
    padding: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
    }

    > a {
      width: 100%;
    }
  }

  &__content-col {
    padding: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 12px;
      flex-grow: 1;
      flex-basis: 0;

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--full {
      width: 100%;
    }

    &--grow-1 {
      flex-grow: 1;
    }

    &--grow-2 {
      flex-grow: 2;
    }

    &--grow-3 {
      flex-grow: 3;
    }
  }

  &__content-buttons {
    .button {
      vertical-align: top;
    }

    .button:first-child {
      margin: 0 0 16px;

      @media (min-width: $screen-mobile-landscape) {
        margin: 0 16px 16px 0;
      }
    }
  }

  .lightbox & {
    > *:first-child {
      margin-top: 0;
    }
  }

  .grid__element--groupedContent1 &,
  .grid__element--groupedContent2 &,
  .grid__element--groupedContent3 & {
    line-height: 1.25;
    color: $grayDarkColor;
  }

  &.headline-teaser {
    .negative-margin {
      margin-top: -8px;
      margin-bottom: -16px;

      h1,
      h2,
      h3,
      h4 {
        margin-bottom: 0;
      }
    }
  }

  &--full-image {
    position: relative;

    img {
      width: 100%;
    }
  }
}

.trustpilot-box {
  padding: 10px;
  color: $textBaseColor;
  max-width: 220px;

  .footer & {
    background: $sandColor;
    border-radius: 6px;
  }

  &-headline {
    font-size: $fontSizeXMedium;
  }

  &-stars {
    background: url(../staticImages/trustpilot-stars.svg);
    background-size: cover;
    width: 128px;
    height: 24px;
    margin: 10px auto;
  }

  &-text {
    font-size: $fontSizeSmall;
    line-height: 1.4;
  }

  &-logo {
    background: url(../staticImages/trustpilot-logo-transparent.png) 0 0 no-repeat;
    background-size: cover;
    width: 118px;
    height: 30px;
    margin: 4px auto 2px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.html-block .u-h1,
.html-block .u-h2,
.html-block .u-h3,
.html-block .u-h4 {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.4;
  font-weight: $fontWeightRegular;
  margin: 16px 0;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 18px;
  }

  &:first-child {
    margin-top: 0;
  }
}

h1.u-h1,
h2.u-h1,
h3.u-h1,
h4.u-h1,
.u-h1 {
  font-size: $fontSizeXXMedium !important;
  margin: 0 0 16px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 36px !important;
  }
}

h1.u-h2,
h2.u-h2,
h3.u-h2,
h4.u-h2,
.u-h2 {
  font-size: $fontSizeXMedium !important;
  line-height: 1.2;
  margin-bottom: 0;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $fontSizeXYMedium !important;
    line-height: 1.6;
  }
}

h1.u-h3,
h2.u-h3,
h3.u-h3,
h4.u-h3,
.u-h3 {
  font-size: $baseFontSize !important;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $fontSizeXMedium !important;
  }
}

h1.u-h4,
h2.u-h4,
h3.u-h4,
h4.u-h4,
.u-h4 {
  font-size: $baseFontSize !important;
}

.u-h-black {
  color: $textDarkestColor !important;
  font-weight: $fontWeightBold !important;
}

.u-teaser-headline,
.html-block .u-teaser-headline {
  order: 1;
  text-align: center;
  font-size: $fontSizeXYMedium;
  margin: 0 0 24px;
  color: $orangeColor;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $fontSizeXXMedium;
    margin: 0 0 32px;
  }
}

.u-teaser-headline-alternative-font,
.html-block .u-teaser-headline-alternative-font {
  order: 1;
  text-align: center;
  margin: 0 0 16px;
  font-family: $fontSecondaryFamily !important;
  font-weight: $fontWeightRegular !important;
  font-size: 28px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 34px;
    margin: 0 0 16px;
  }

  @media (min-width: $screen-desktop) {
    font-size: 45px;
  }
}

.u-alternative-white {
  font-family: $fontSecondaryFamily !important;
  font-weight: $fontWeightRegular !important;
  color: $whiteColor !important;
}

.u-alternative-font {
  font-family: $fontSecondaryFamily !important;
}

.u-font-size-md {
  font-size: $fontSizeXXMedium !important;
}

.u-font-size-lg {
  font-size: $fontSizeLarge !important;
}

.u-font-weight-thin {
  font-weight: $fontWeightThin !important;
}

.u-font-weight-regular {
  font-weight: $fontWeightRegular !important;
}

.u-text-m,
.html-block .u-text-m {
  font-family: $fontPrimaryFamily;
  font-size: 13px;
  font-weight: $fontWeightRegular;
  line-height: 1.3;
  color: $textBaseColor;
  margin: 0 0 8px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 15px;
  }

  @media (min-width: $screen-tablet-portrait) {
    font-size: 17px;
  }
}

.u-text-l,
.html-block .u-text-l,
.html-block .u-text-l p {
  font-family: $fontPrimaryFamily;
  font-weight: $fontWeightRegular;
  font-size: 13px;
  line-height: 1.3;
  margin: 0 0 16px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 15px;
  }

  @media (min-width: $screen-desktop) {
    font-size: 17px;
  }

  a:not(.button),
  li {
    color: $textBaseColor;
    text-decoration-color: inherit;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.u-color-text-highlight {
  color: $textHighlightColor !important;
}

.u-color-text-light {
  color: $textLightColor !important;
}

.u-color-text-gray-light {
  color: $textLightGrayColor !important;
}

.u-color-text-dark {
  color: $grayDarkColor !important;
}

.u-color-text-warning {
  color: $warningColor !important;
}

.u-color-text-error {
  color: $errorColor !important;
}

.u-color-text-success {
  color: $successColor !important;
}

.u-color-text-red {
  //color: $redDarkColor !important;
}

.u-color-brand-primary {
  color: $brandPrimaryColor !important;
}

.u-color-brand-secondary {
  color: $brandSecondaryColor !important;
}

.u-underline {
  text-decoration: underline !important;
}

.u-italic {
  font-style: italic !important;
}

.u-strong {
  font-weight: $fontWeightBold !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-margin-0 {
  margin: 0 !important;
}

.u-margin-t-0 {
  margin-top: 0 !important;
}

.u-margin-t-xs {
  margin-top: 4px !important;
}

.u-margin-t-s {
  margin-top: 8px !important;
}

.u-margin-t-m {
  margin-top: 16px !important;
}

.u-margin-t-l {
  margin-top: 24px !important;
}

.u-margin-t-xl {
  margin-top: 32px !important;
}

.u-margin-t-xxl {
  margin-top: 40px !important;
}

.u-negative-margin-t-xs {
  margin-top: -4px !important;
}

.u-negative-margin-t-s {
  margin-top: -8px !important;
}

.u-negative-margin-t-m {
  margin-top: -16px !important;
}

.u-negative-margin-t-l {
  margin-top: -24px !important;
}

.u-negative-margin-t-xl {
  margin-top: -32px !important;
}

.u-negative-margin-t-xxl {
  margin-top: -40px !important;
}

.u-margin-b-0 {
  margin-bottom: 0 !important;
}

.u-margin-b-xs {
  margin-bottom: 4px !important;
}

.u-margin-b-s {
  margin-bottom: 8px !important;
}

.u-margin-b-m {
  margin-bottom: 16px !important;
}

.u-margin-b-l {
  margin-bottom: 24px !important;
}

.u-margin-b-xl {
  margin-bottom: 32px !important;
}

.u-margin-b-xxl {
  margin-bottom: 40px !important;
}

.u-negative-margin-b-xs {
  margin-bottom: -4px !important;
}

.u-negative-margin-b-s {
  margin-bottom: -8px !important;
}

.u-negative-margin-b-m {
  margin-bottom: -16px !important;
}

.u-negative-margin-b-l {
  margin-bottom: -24px !important;
}

.u-negative-margin-b-xl {
  margin-bottom: -32px !important;
}

.u-negative-margin-b-xxl {
  margin-bottom: -40px !important;
}

.u-padding-t-0 {
  padding-top: 0 !important;
}

.u-padding-top {
  padding-top: 32px !important;

  @media (min-width: $screen-tablet-portrait) {
    padding-top: 40px !important;
  }
}

.u-padding-t-xs {
  padding-top: 4px !important;
}

.u-padding-t-s {
  padding-top: 8px !important;
}

.u-padding-t-m {
  padding-top: 16px !important;
}

.u-padding-t-l {
  padding-top: 24px !important;
}

.u-padding-t-xl {
  padding-top: 32px !important;
}

.u-padding-b-0 {
  padding-bottom: 0 !important;
}

.u-padding-b-xs {
  padding-bottom: 4px !important;
}

.u-padding-b-s {
  padding-bottom: 8px !important;
}

.u-padding-b-m {
  padding-bottom: 16px !important;
}

.u-padding-b-l {
  padding-bottom: 24px !important;
}

.u-padding-b-xl {
  padding-bottom: 32px !important;
}

.u-margin-mobile-t-s {
  @media (max-width: $screen-mobile-landscape-max) {
    margin-top: 8px !important;
  }
}

.u-margin-headline-s {
  margin: 8px 0 16px !important;
  padding: 0 !important;

  @media (min-width: $screen-tablet-portrait) {
    margin: 16px 0 !important;
  }

  @media (min-width: $screen-desktop) {
    margin: 24px 0 !important;
  }
}

.u-columns-2 {
  line-height: 1.4;

  @media (min-width: $screen-tablet-portrait) {
    columns: 2; // stylelint-disable-line plugin/no-unsupported-browser-features
  }
}

.u-hidden {
  visibility: hidden;
  padding: 0;
  margin: 0;
  float: left;
}

.nobr {
  white-space: nowrap;
}
