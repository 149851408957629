@mixin bottleShadow() {
  &::before {
    // stylelint-disable plugin/no-unsupported-browser-features
    background:
      transparent radial-gradient(
        ellipse at center center,
        rgb(137 51 18 / .9) 10%,
        rgb(137 51 18 / .2) 50%,
        transparent 70%
      ) repeat scroll 0 0;
    bottom: -9px;
    content: "";
    height: 33px;
    left: 5%;
    opacity: .6;
    position: absolute;
    width: 90%;
    z-index: 0;
    margin: auto;
  }
}
