.header {
  $root: &;
  $transitionTime: .25s;
  $transitionDelay: .25s;
  $transition: $transitionTime cubic-bezier(.25, .46, .45, .94);
  position: relative;
  z-index: $indexHeader;

  &--search-active {
    z-index: 610;
  }

  &__wrapper {
    background: var(--header-background);
    box-shadow: 0 0 6px 0 rgb(0 0 0 / .1);
  }

  &__top-bar {
    position: absolute;
    z-index: 0;
    right: 40px;
    top: 10px;

    .header__sticky--active & {
      display: none;
    }
  }

  &__meta-menu {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      display: block;
    }
  }

  &__sticky {
    &--enabled,
    &--active {
      will-change: position;
      position: relative;
      width: 100%;
    }

    &--active {
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      box-shadow: 0 0 5px 1px rgb(0 0 0 / .5);
      transition: all 300ms ease-in-out;

      .page--scroll-down & {
        opacity: 0;
        top: -100px;
      }

      .page--scroll-up & {
        opacity: 1;
        top: 0;
      }

      .product-navigation--is-sticky & {
        @media (min-width: $screen-tablet-portrait) {
          box-shadow: none;
        }
      }
    }
  }

  &__main {
    background: var(--header-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    flex-wrap: wrap;
    position: relative;
    min-height: 46px;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      @include container($maxWidthHeader);
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (min-width: $screen-desktop-sm) {
      min-height: 95px;
      padding-top: 10px;
      align-items: flex-start;
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        max-height: 0;
        min-height: 0;
        margin: 0;
        padding: 0;
        align-items: center;
        order: 2;
      }
    }
  }

  &__logo-wrapper {
    display: block;
    height: auto;
    text-align: center;

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        position: absolute;
      }
    }
  }

  &__logo-link {
    display: flex;
    z-index: 600;
    height: 40px;
    width: 73px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 4px;
    overflow: hidden;

    @media (min-width: $screen-desktop-sm) {
      width: auto;
      height: auto;
      position: relative;
      top: 0;
    }

    .header__logo {
      height: 38px;
      width: 69px;

      @media (min-width: $screen-desktop-sm) {
        width: 131px;
        height: 75px;
      }
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        position: absolute;
        height: 38px;
        width: 69px;
        top: 5px;
        left: 40px;

        @media (min-width: $screen-desktop-xl) {
          left: 40px;
        }

        .header__logo {
          height: 38px;
          width: 69px;
        }
      }
    }
  }

  &__logo {
    display: block;
    width: 80px;
    height: 44px;

    @media (min-width: $screen-desktop-sm) {
      width: 135px;
      height: 75px;
    }
  }

  &__logo-claim {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 194px;
      height: 75px;
      margin: -5px 0 0 20px;
    }

    .header__sticky--active & {
      display: none;
    }
  }

  &__search-trigger,
  &__burger-menu-trigger {
    order: 1;
    font-size: 23px;
    display: block;
    padding: 10px 0;
    text-align: center;
    position: relative;

    .header__sticky--active & {
      display: block;

      @media (min-width: $screen-desktop-sm) {
        display: none;
      }
    }
  }

  &__burger-menu-trigger {
    width: 25px;
    top: 1px;

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }
  }

  &__search-trigger {
    color: $headerIconColor;
    padding: 0 16px;
    top: 3px;
  }

  &__burger-menu {
    display: block;
    background: $headerIconColor;
    width: $iconBurgerWidth;
    height: $iconBurgerHeight;
    border-radius: $iconBurgerHeight;
    position: relative;
    transition-duration: $iconBurgerDuration;
    transition-delay: $iconBurgerDelay;
    vertical-align: middle;
    top: -3px;

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      background: $headerIconColor;
      width: $iconBurgerWidth;
      height: $iconBurgerHeight;
      border-radius: $iconBurgerHeight;
      left: 0;
    }

    &::before {
      top: -7px;
      transition: $iconBurgerPseudoTransition;
    }

    &::after {
      top: 7px;
      transition: $iconBurgerPseudoTransition;
      content: attr(data-value);
      background: none;
      color: $headerIconColor;
      font-size: 8px;
      text-align: center;
      text-transform: uppercase;
    }

    &--open,
    .no-scroll--burger &,
    .burger-menu--open & {
      transition-duration: .1s;
      transition-delay: $iconBurgerDelay;
      background: transparent;

      &::before {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 3px;
        transform: rotateZ(-45deg);
      }

      &::after {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 3px;
        transform: rotateZ(45deg);
        content: '';
        background: $headerIconColor;
        width: $iconBurgerWidth;
        height: $iconBurgerHeight;
      }
    }

    .header__sticky--active & {
      display: block;
    }
  }

  &__search-icon {
    @include iconFont(search) {
      color: $sandColor;
    }

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }
  }

  &__user-shortcuts {
    order: 3;
    position: relative;
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-grow: 1;

    @media (min-width: $screen-desktop-sm) {
      margin: 35px 0 0;
      flex-grow: 0;
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        position: absolute;
        right: 40px;
        bottom: -42px;
        z-index: 2;
      }

      @media (min-width: $screen-desktop-xl) {
        right: 40px;
      }
    }
  }

  &__navigation-wrapper {
    order: 4;

    @media (min-width: $screen-desktop-sm) {
      background-color: $sandColor;
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: $terracottaColor;
      }
    }
  }

  &__navigation {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        @include container($maxWidthHeader);
      }
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: $maxWidthInner;
        padding: 0 120px;
      }
    }
  }

  &__search {
    margin: 10px auto;
    width: 95%;
    order: 5;
    display: none;
    position: relative;

    @media (min-width: $screen-desktop-sm) {
      display: block;
      order: 1;
      width: 457px;
      margin: 33px 6px 0 auto;
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        display: none;
      }
    }

    .header--search-active & {
      animation: search-slide .3s;
      display: block;
    }

    .search-autosuggest__close {
      opacity: 0;
      transition: opacity .1s linear;
      transition-delay: $transitionTime + $transitionDelay;

      .header__sticky--active & {
        display: none;
      }
    }
  }

  &__product-flyout {
    width: calc(100vw - 30px);
    max-width: 320px;
    position: absolute;
    background: $backgroundWhiteColor;
    border-radius: 5px;
    top: 100%;
    margin-top: 10px;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / .3);
    z-index: $indexBasketFlyout;
  }

  &__checkout-process-breadcrumbs-wrapper {
    position: relative;
  }

  &__checkout-process-breadcrumbs {
    order: 6;
  }

  &__live-event {
    min-height: 34px;
    background: $orangeColor;

    > div {
      opacity: 0;
    }
  }

  &__benefit-line {
    .page--sticky-header & {
      display: none;
    }
  }

  &__sticky-cart {
    position: fixed;
    top: 24px;
    right: 24px;
    width: 40px;
    height: 40px;
    background: $terracottaHoverColor;
    border-radius: 50%;
    box-shadow: $goTopBoxShadow;
    transition: background .2s linear, transform .3s ease-in-out;
    transform: translateY(-90px);
    cursor: pointer;
    display: none;

    &:hover {
      background: $terracottaActiveColor;
    }

    #{$root}--sticky & {
      // Show, when sticky is active
      transform: translateY(0);
    }

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
    }

    @media (min-width: $maxWidthOuter + 80) {
      right: calc(50% - #{$maxWidthOuter * .5 + 32});
    }

    .user-shortcut {
      &__link {
        color: $whiteColor;
        padding: 8px 9px;
      }

      &__notification-badge-wrapper {
        bottom: -2px;
      }
    }
  }

  .html-block {
    a:not(.button) {
      padding: 0 8px;
      text-decoration: none;

      &:hover {
        color: inherit;
        text-decoration: underline;
        text-decoration-color: inherit;
      }
    }
  }
}

@keyframes search-slide {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  100% { max-height: 50px; }
}
