.content-block-quick-facts {
  $root: &;

  @include clearList();
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -16px;

  &__item {
    position: relative;
    padding-left: 32px;
    margin: 0 0 16px;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: auto;
      min-width: 160px;
      max-width: 160px;
      margin-right: 12px;
    }

    @media (min-width: $screen-desktop) {
      min-width: 240px;
      max-width: 240px;
    }

    &#{$root}--c_rebsorte_aromen {
      padding-left: 0;

      @media (min-width: $screen-tablet-portrait) {
        min-width: 270px;
        max-width: 270px;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
      }

      @media (min-width: $screen-tablet-landscape) {
        min-width: 320px;
        max-width: 320px;
      }
    }
  }

  &__headline {
    display: block;
    font-size: 11px;
    line-height: 1.4;
    letter-spacing: 1px;
    color: $textBaseColor;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
    margin-bottom: 2px;
    width: 100%;
  }

  &__value {
    display: inline-block;
    font-size: $fontSizeXSmall;

    &:not(:last-child)::after {
      content: ', ';
    }

    &--image {
      vertical-align: top;
      margin: 5px 8px 3px 0;
      max-width: 68px;

      @media (min-width: $screen-mobile-large) {
        max-width: 80px;
      }

      @media (min-width: $screen-tablet-portrait) {
        max-width: 100px;
      }

      &::after {
        content: '' !important;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--c_rebsorte_passt_zu,
  &--c_rebsorte_synonyme,
  &--c_rebsorte_aromen,
  &--c_erzeuger_anbaugebiet,
  &--c_erzeuger_bodentyp,
  &--c_erzeuger_klima,
  &--c_herkunft_rebflaeche,
  &--c_herkunft_weinmacher,
  &--c_herkunft_jahresproduktion {
    @include iconFont(typeOfWine) {
      position: absolute;
      left: 0;
      top: 5px;
      color: $textBaseColor;
      font-size: 19px;
    }
  }

  &--c_erzeuger_anbaugebiet {
    @include iconFont(region) {
      top: 3px;
      font-size: 22px;
    }
  }

  &--c_erzeuger_bodentyp {
    @include iconFont(bodentyp) {
      top: -2px;
      font-size: 27px;
    }
  }

  &--c_erzeuger_klima {
    @include iconFont(klima) {
      top: -1px;
      font-size: 27px;
    }
  }

  &--c_herkunft_rebflaeche {
    @include iconFont(rebflaeche) {
      top: -1px;
      font-size: 26px;
    }
  }

  &--c_herkunft_weinmacher {
    @include iconFont(weinmacher) {
      top: 1px;
      font-size: 25px;
    }
  }

  &--c_herkunft_jahresproduktion {
    @include iconFont(jahresproduktion) {
      top: 0;
      font-size: 29px;
    }
  }

  &--c_rebsorte_passt_zu {
    @include iconFont(passtZu) {
      top: -1px;
      font-size: 23px;
    }
  }

  &--c_rebsorte_synonyme {
    @include iconFont(synonyme) {
      top: -1px;
      font-size: 30px;
    }
  }

  &--c_rebsorte_aromen {
    &::before {
      display: none;
    }
  }

  &__value-text {
    color: $textBaseColor;
  }

  &__value-image {
    display: block;
    width: 100%;
    margin: 0 0 8px;
  }
}
