@import '../../../bps/components/productBoxWide/productBoxWide.scss';

.product-box-wide {
  $root: &;
  border-radius: 3.6px;
  background: $productBoxBackgroundColor;
  padding: 20px;
  flex-wrap: nowrap;

  &--personalization {
    flex-wrap: wrap;
  }

  &__product-flags {
    width: 80px;
    top: 10px;
    left: -5px;
  }

  &__name {
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 8px;

    @media (min-width: $screen-desktop) {
      font-size: 32px;
    }
  }

  &__short-description {
    font-size: 14px;

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }
  }

  &__button-wishlist {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 24px;
  }

  &__vintage {
    display: none;
  }

  &__long-description {
    color: $textBaseColor;
    font-size: 14px;
    line-height: 1.3;

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }

    p {
      margin: 0 0 8px;
    }

    .products-list-page--with-left-navigation & {
      @media (min-width: $screen-tablet-portrait) {
        line-height: 1.2;
        margin-top: 8px;
      }

      @media (min-width: $screen-tablet-landscape) {
        line-height: 1.3;
        margin-top: 16px;
      }
    }
  }

  &__rating-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 12px 0 0;

    .products-list-page--with-left-navigation & {
      @media (min-width: $screen-tablet-portrait) {
        margin: 8px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 12px 0 0;
      }
    }
  }

  &__tags-wrapper {
    margin: 12px 0 0;
    display: flex;
    flex-flow: row wrap;

    .product-box__tags {
      display: flex;
      position: relative;
      justify-content: flex-start;
      gap: 12px;
      width: auto;
    }

    .flags {
      width: auto;
    }

    .flag {
      padding: 0;
    }

    .flag--with-dot {
      @media (min-width: $screen-tablet-portrait) {
        margin-right: 10px !important;
      }

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 4px;
        position: relative;
        display: inline-block;
        background: $brownMediumColor;
        margin: 0 0 0 10px;
        opacity: .4;
      }
    }

    .tags__list {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: $screen-tablet-portrait) {
        margin-right: 10px;
      }

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 4px;
        position: relative;
        display: inline-block;
        background: $brownMediumColor;
        margin: 0 0 0 10px;
        opacity: .4;
      }

      &:last-child {
        &::after {
          display: none;

          .product-box-wide__tags-wrapper--has-flag & {
            display: inline-block;
          }
        }
      }

      .products-list-page--with-left-navigation & {
        @media (min-width: $screen-tablet-portrait) {
          display: none;
        }

        @media (min-width: $screen-tablet-landscape) {
          display: flex;
        }
      }
    }

    &--has-tags {
      #{$root}__bottle-amount {
        &::after {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 4px;
          position: relative;
          display: inline-block;
          background: $brownMediumColor;
          margin: 0 0 0 10px;
          opacity: .4;
        }
      }
    }

    &--no-set {
      .tags__list {
        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &__segment-image {
    position: relative;
    width: 35%;
    max-width: 420px;

    @media (min-width: $screen-laptop) {
      width: 35%;
    }

    @media (min-width: $screen-desktop) {
      width: 45%;
      height: auto;
    }

    .products-list-page--no-sidebar & {
      @media (min-width: $screen-laptop) {
        width: 45%;
      }

      @media (min-width: $screen-desktop) {
        min-width: 488px;
        height: auto;
      }
    }
  }

  &__segment-center {
    flex: 1 1;
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .tags__tag,
    .flag,
    #{$root}__bottle-amount {
      color: $textBaseColor;
      font-size: 14px;
      line-height: 1.3;
      white-space: nowrap;
      width: auto;
      padding: 0;
      margin: 0;
      border: 0 none;
      background: transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
      border-radius: 0;

      @media (min-width: $screen-desktop) {
        font-size: 17px;
      }
    }

    #{$root}__bottle-amount {
      @media (min-width: $screen-tablet-portrait) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
    }

    .products-list-page--with-left-navigation & {
      @media (min-width: $screen-tablet-portrait) {
        margin: 0 8px 0 16px;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 12px 0 24px;
      }
    }
  }

  &__header {
    .products-list-page--with-left-navigation & {
      @media (min-width: $screen-tablet-portrait) {
        width: calc(100% + 95px);
      }

      @media (min-width: $screen-tablet-landscape) {
        width: 100%;
      }
    }
  }

  &__segment-conversion {
    width: auto;
    flex-direction: column;

    @media (min-width: $screen-laptop) {
      width: 180px;
      max-width: 180px;
    }

    .products-list-page--with-left-navigation & {
      @media (min-width: $screen-tablet-portrait) {
        width: 130px;
        max-width: 130px;
      }

      @media (min-width: $screen-desktop-xl) {
        width: 140px;
        max-width: 140px;
      }
    }
  }

  &__image {
    height: auto;
    width: 100%;
    margin: auto;

    .product-box--type-HORIZONTAL & {
      margin: 0 auto;
    }

    img {
      max-height: none;
      position: relative;
      width: 100%;
    }
  }

  &__wishlist-wrapper {
    height: 60px;
  }

  &__image-wrapper {
    &--bottle,
    &--vertical {
      background: rgb(255 234 199);
      // stylelint-disable-next-line
      background: radial-gradient(circle, rgb(255 234 199) 0%, rgb(255 211 154) 50%, rgb(222 163 99) 100%);
      // stylelint-disable-next-line
      background-image:
        image-set(
          url('../staticImages/productBoxWide-bg.webp') 1x,
          url('../staticImages/productBoxWide-2x-bg.webp') 2x,
          url('../staticImages/productBoxWide-bg.jpg') 1x,
          url('../staticImages/productBoxWide-2x-bg.jpg') 2x,
        );
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 20px;

      img {
        width: auto !important;
      }

      #{$root}__image {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 40px);
        position: relative;
      }
    }

    &--bottle {
      min-height: 275px;

      .image-element {
        position: relative;

        .product-box--type-VERTICAL & {
          @include bottleShadow();

          &::before {
            left: -50%;
            width: 150%;
          }
        }
      }
    }
  }

  .price-info {
    font-size: 25px;
    align-items: flex-end;

    &__old {
      font-size: 1.3em;
      margin: 0 0 -6px;

      .price-info__currency {
        padding-left: 5px;
      }
    }

    &__currency {
      padding-left: 8px;
    }

    &__asterisk {
      font-size: .45em;
      line-height: 1.8em;
      position: absolute;
      right: -8px;
    }

    &__info {
      margin: 4px 0 0;
      justify-content: flex-end;
    }

    &__unit {
      color: $brownMediumColor;
    }

    &--currency-CHF {
      .price-info__currency {
        padding-left: 0;
      }
    }
  }

  .product-box__buttons-wrapper {
    padding-bottom: 18px;
  }

  &.product-box--in-basket {
    .product-box__buttons-wrapper {
      padding-bottom: 0;
    }
  }

  &__personalization-banner-wrapper {
    margin: 0;
    width: 100%;
    position: relative;
  }

  &__personalization-banner {
    margin: -20px -20px 20px;
    width: calc(100% + 40px);
    position: relative;

    &::after {
      position: absolute;
      right: 48px;
      left: 48px;
      margin: auto;
    }
  }

  .read-more__btn {
    font-size: 14px;
    font-weight: $fontWeightRegular;
    margin: 0;
    cursor: pointer;
    color: $brownMediumColor;

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }
  }
}
