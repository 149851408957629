.content-page {
  @media screen and (max-width: $screen-mobile-landscape-max) {
    display: flex;
    flex-direction: column;
  }

  @include sideColumnLayout();
  position: relative;

  &__column {
    display: block;

    @media screen and (max-width: $screen-mobile-landscape-max) {
      order: 1;
    }
  }

  &__content {
    font-size: $baseFontSize;
    display: block;
    padding: 0;

    .html-block:not(.html-block--no-spacing) {
      @include container($maxWidthOuter);
    }

    .html-block h2 {
      margin-bottom: 16px;
    }

    .html-block .u-text-l a:not(.button) {
      font-size: inherit;
    }

    .carousel__wrapper {
      width: 100%;
      margin: 0;

      .image-slider-teaser__text {
        color: $brownMediumColor;
      }
    }

    @media screen and (max-width: $screen-mobile-landscape-max) {
      order: 3;
    }

    .process-teaser--white {
      background: $sandHoverColor;
    }
  }

  &__page-intro {
    width: 100%;

    @media screen and (max-width: $screen-mobile-landscape-max) {
      padding-top: 24px;
      margin-bottom: -16px;
    }

    @media screen and (min-width: $screen-tablet-portrait) {
      @include fullWidth();
    }

    .page-intro--no-image {
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        width: 100%;
        padding-left: calc(28% - 20px);
      }

      @media (min-width: $screen-tablet-landscape) {
        padding-left: calc(24% - 20px);
      }
    }

    .page-intro--no-image .products-list-page__name {
      margin: -8px 0 0;
      padding-top: 0;
      font-size: 24px;

      @media screen and (min-width: $screen-tablet-portrait) {
        font-size: 34px;
      }

      @media screen and (min-width: $screen-desktop) {
        font-size: 45px;
      }
    }

    .mood-teaser__text-wrap {
      justify-content: flex-start;

      .products-list-page__name {
        font-size: 24px;

        @media screen and (min-width: $screen-tablet-portrait) {
          font-size: 34px;
        }

        @media screen and (min-width: $screen-desktop) {
          font-size: 45px;
        }
      }
    }

    .mood-teaser__picture-image {
      width: 100%;
      min-height: unset;
      height: auto;

      @media screen and (min-width: $screen-tablet-xsm) {
        width: auto;
        min-height: 300px;
      }
    }

    &--wrapper {
      width: 100%;

      @media screen and (max-width: $screen-mobile-landscape-max) {
        order: 2;
      }
    }
  }

  &__page-intro--no-image {
    width: 100%;
    margin: 0 auto;
    left: auto;
    right: auto;
  }

  .sidebar-menu {
    margin: 40px auto 0;

    @media screen and (max-width: $screen-mobile-landscape-max) {
      margin: 16px auto 0;
    }
  }
}
