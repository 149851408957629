@import '../../../bps/components/campaignHeader/campaignHeader.scss';

.campaign-header {
  background: $orangeColor;

  .page--sticky-header & {
    display: none;
  }

  &__container {
    @include container($maxWidthInner + 80px);
  }

  &__content {
    color: $sandColor;
    font-size: 10px;
    font-weight: $fontWeightThin;
    padding: 4px 0 5px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 15px;
      padding: 4px 0 5px;
    }

    a {
      color: $sandColor;
    }
  }

  &__close-btn {
    padding: 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 8px 12px;
      margin-right: -10px;
    }

    @include iconFont(close) {
      font-size: 13px;
      font-weight: $fontWeightSemiBold;
      top: 1px;
      position: relative;
    }
  }
}
