.lazy-load-image {
  position: relative;

  &__image {
    opacity: 1;
    transition: opacity .1s linear;

    &--not-loaded {
      opacity: 0;
    }

    &--error {
      padding: 10%;
    }
  }

  &__loader {
    @include loader();
    display: inline-block;
    min-width: 40px;
    min-height: 30px;
  }
}

@keyframes lazyLoadImageLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* stylelint-disable-next-line */
lazy-load-image,
[is="lazy-load-image"] {
  @include loader();
  display: inline-block;
  min-width: 40px;
  min-height: 30px;
}
