@import "../../../bps/utilities/scss/bottle/bottleShadow.scss";

.product-box {
  $root: &;
  background: $productBoxBackgroundColor;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-radius: $productBoxListBorder;

  &--personalization {
    .cinema-teaser & {
      margin: 6px 0 0;
      height: calc(100% - 6px);
    }

    .products-list & {
      @media (max-width: $screen-tablet-xsm) {
        margin: 6px 0 0;
        height: calc(100% - 6px);
      }
    }

    .products-list--type_list & {
      @media (max-width: $screen-tablet-portrait) {
        margin: 6px 0 0;
        height: calc(100% - 6px);
      }
    }
  }

  &:hover {
    z-index: 2;
  }

  &--error {
    $stripeColor: transparentize($errorColor, .8);
    background-image: linear-gradient(45deg, $stripeColor 25%, $sandColor 25%, $sandColor 50%, $stripeColor 50%, $stripeColor 75%, $sandColor 75%, $sandColor 100%); // stylelint-disable-line  plugin/no-unsupported-browser-features
    background-size: 56.57px 56.57px;
  }

  &__segment-header {
    display: flex;
    padding-bottom: 2px;
    text-decoration: none;
  }

  &__header-text {
    width: 100%;
    padding-right: 8px;
  }

  &__body {
    display: flex;
    flex-grow: 1;

    #{$root}--type-horizontal & {
      flex-direction: column;
    }

    #{$root}--type-vertical & {
      flex-direction: row;
    }
  }

  &__rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 14px;
  }

  &__segment-main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    #{$root}--type-horizontal & {
      text-align: left;
    }

    #{$root}--type-vertical & {
      text-align: right;
      flex: 1 1;
      padding-top: 8px;
      padding-bottom: 18px;

      @media (min-width: $screen-desktop) {
        padding-top: 20px;
      }
    }

    .product-box--in-basket & {
      padding-bottom: 0;
    }
  }

  &__segment-image {
    display: flex;

    #{$root}--type-horizontal & {
      flex-grow: 1;
      flex-direction: column;
      margin-bottom: 16px;
      display: flex;
    }

    #{$root}--type-vertical & {
      width: 60%;
    }

    &.lazy-load {
      #{$root}--type-vertical & {
        min-height: 332px;

        @media (min-width: $screen-tablet-portrait) {
          min-height: 294px;
        }

        @media (min-width: 1251px) {
          min-height: 332px;
        }
      }
    }
  }

  &__image-container {
    width: 100%;
    position: relative;

    #{$root}--type-vertical & {
      padding: 0;
      display: flex;
      align-items: flex-end;

      .image-element,
      .product-box__image-wrapper .lazy-load {
        min-width: 100px;
        min-height: 270px;
        margin: 0;
      }
    }

    #{$root}--type-VERTICAL &,
    #{$root}--type-SQUARE & {
      padding: 0;
      display: flex;
      align-items: flex-end;

      .image-element,
      .product-box__image-wrapper .lazy-load {
        min-width: 100px;
        min-height: 270px;
        margin: 0;
      }
    }

    #{$root}--type-horizontal & {
      .image-element,
      .product-box__image-wrapper .lazy-load {
        min-width: 260px;

        @media (min-width: $screen-tablet-xsm) {
          min-width: 100%;
        }
      }
    }
  }

  &__image-wrapper {
    #{$root}--type-vertical & {
      margin: 0;
      padding: 16px 6px 8px 0;
      max-width: 160px;
      height: auto;
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    @media (min-width: $screen-mobile-landscape) {
      display: flex;
    }

    > .image-element__pic {
      @media (min-width: $screen-mobile-landscape) {
        display: flex;
        width: 100%;

        // stylelint-disable-next-line
        .is_safari--old .product-box--type-horizontal & {
          display: block;
        }
      }

      .product-box--type-vertical & {
        display: flex;
        width: 100%;
        min-height: 308px;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
      }

      .product-box--type-bottle.product-box--type-vertical & {
        @include bottleShadow();
      }
    }

    &.image-element--load-error {
      &::after {
        display: none;
      }
    }

    img {
      position: relative;
      z-index: 1;

      #{$root}--type-VERTICAL & {
        width: 110px;
      }

      #{$root}--type-HORIZONTAL &,
      #{$root}--type-SQUARE & {
        width: 100%;
      }
    }
  }

  &__name,
  &__short-description {
    padding: 0;
    max-width: 100%;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    line-height: 110%;
  }

  &__name {
    width: 100%;
    font-size: 20px;
    font-weight: $fontWeightBold;
    font-family: $fontHeadlineFamily;
    color: $productBoxNameColor;
    margin-bottom: 3px;
  }

  &__short-description {
    font-size: 14px;
    font-family: $fontPrimaryFamily;
    color: $productBoxShortDescriptionColor;
  }

  &__product-flags {
    position: absolute;
    left: -10px;
    z-index: 2;
    width: 72px;
    text-decoration: none;

    #{$root}--type-horizontal & {
      top: 7px;
      left: -1px;
    }

    #{$root}--type-vertical & {
      bottom: 0;
      height: 308px;
    }

    .flags {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .flag {
      margin: 0 0 8px;
    }

    .flag--savings {
      transform: scale(.7);
      margin: -10px 0 -6px;
    }
  }

  &__basket-button {
    width: 100%;
    min-width: 100%;
    align-self: flex-end;
    float: left;
    padding: 0 5px;
  }

  &__price-wrapper {
    display: flex;
    align-items: stretch;

    &--no-price {
      flex-grow: 1;
      justify-content: flex-end;
    }

    .price-info {
      font-size: 25px;

      &__old {
        font-size: 1.3em;
        margin: 0 0 -6px;

        .price-info__currency {
          padding-left: 5px;
        }
      }

      &__currency {
        padding-left: 8px;
      }

      &__asterisk {
        font-size: .45em;
        line-height: 1.8em;
        position: absolute;
        right: -8px;
      }

      &__info {
        margin: 4px 0 0;
        justify-content: flex-end;
      }

      &__unit {
        color: $brownMediumColor;
      }

      &--currency-CHF {
        .price-info__currency {
          padding-left: 0;
        }
      }
    }

    #{$root}--type-horizontal & {
      flex-direction: row;
      margin-top: -10px;

      .price-info__info {
        justify-content: flex-start;
      }
    }

    #{$root}--type-vertical & {
      flex-direction: column;
    }
  }

  &__price {
    font-size: $fontSizeXXSmall;

    #{$root}--type-horizontal & {
      flex: 1 1;
      align-items: flex-start;
    }

    #{$root}--type-vertical & {
      margin-bottom: 8px;
      align-items: flex-end;
    }
  }

  &__availability {
    #{$root}--type-horizontal & {
      margin: 0;

      .availability-info__label {
        text-align: left;
      }
    }

    #{$root}--type-vertical & {
      margin: 8px 0 0 -15%;
      width: 115%;
    }

    .availability-info--label {
      font-size: $fontSizeXSmall;
      text-transform: none;
      word-break: break-word;

      &::before {
        display: none;
      }
    }
  }

  &__link {
    color: $brownMediumColor;
    font-size: 10px;
    line-height: 1.2;
    display: inline-block;
    text-decoration: underline;
    margin: 8px 0;
    order: 9;

    #{$root}--type-horizontal & {
      text-align: right;
    }
  }

  &__area {
    font-size: 12px;
    line-height: 120%;
    width: 100%;
  }

  &__ratings {
    justify-content: normal;
    font-size: 13px;

    #{$root}--type-horizontal & {
      margin: 0;
    }

    #{$root}--type-vertical & {
      margin: 0 0 8px;
    }
  }

  &__bottle-amount {
    font-size: $fontSizeSmall;
  }

  &__counter {
    max-width: 135px;
    margin-right: 8px;
    width: 40px;
    min-width: 40px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &--currently-free {
      height: 0;

      &-reorder {
        @media (min-width: $screen-desktop) {
          height: 44px;
        }
      }

      &-product-box {
        @media (min-width: $screen-tablet-xsm) {
          height: 44px;
        }
      }
    }

    &.lazy-load {
      min-height: 40px;
    }
  }

  &__button-to-cart {
    position: relative;
    min-width: auto;
  }

  &__button-wishlist {
    position: relative;
    width: 24px;
    height: 28px;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    color: $productBoxButtonWishlistColor;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -1px -3px 0 0;

    @include iconFont(heart) {
      transition: color .15s linear;
    }

    &:hover {
      @include iconFont(heartFilled);
    }

    &--filled {
      @include iconFont(heartFilled);
      color: $productBoxWishlistColor;
    }

    &--remove {
      width: 16px;
      height: 16px;

      @include iconFont(close) {
        color: $grayDarkColor;
        font-size: 16px;
        height: 16px;
        text-align: center;
      }

      &:hover {
        @include iconFont(close) {
          color: $grayDarkColor;
        }
      }
    }

    &--disabled {
      cursor: not-allowed;
      color: $productBoxButtonWishlistColor;

      &:hover {
        @include iconFont(heartUnfilled);
      }
    }

    &--loading {
      @include loader(25, $productBoxWishlistColor);
    }
  }

  &__tags {
    margin: 0;
    position: relative;

    .tags {
      &__list {
        float: none;
        width: 100%;
      }

      &__tag {
        font-size: 12px;
        padding: 0;
        margin: 0;
        border: 0 none;
        background: transparent;

        &.tag--bulk_price {
          cursor: pointer;
          width: auto;
          height: auto;
          background: $orangeColor;
          color: $whiteColor;
          font-size: 12px;
          line-height: 1;
          text-transform: none;
          padding: 3px 5px 5px;
          font-weight: $fontWeightRegular;
          border-radius: 2px;
          border: 1px solid $orangeColor;
          margin: 8px 0 0;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__tags-wrapper {
    .flags {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
    }

    .flag {
      font-size: 12px;
      font-family: $fontPrimaryFamily;
      font-weight: $fontWeightRegular;
      line-height: 1.2;
      color: $textDarkestColor;
      text-transform: none;
      padding: 0;
      margin: 0;
      border: 0 none;
      background: transparent;
      width: auto;
      height: auto;
      border-radius: 0;

      &:first-child {
        padding: 2px 0 0;
      }

      // stylelint-disable-next-line
      &.flag--nonAlcoholic,
      &.flag--vegan {
        color: $greenColor;
      }
    }
  }

  &__conversion {
    width: 100%;

    #{$root}--type-vertical & {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      flex-flow: column wrap;
      justify-content: flex-end;
      align-content: flex-end;
    }

    #{$root}--type-horizontal & {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: row wrap;
    }
  }

  &__status {
    background: rgb(146 117 101 / .2);
    color: $textDarkestColor;
    padding: 6px 8px 8px;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    border-radius: 2px;
    margin-left: 2px;
    min-height: 40px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &--basket {
      background: transparent;
      color: $brownColor;
      animation: growBounce;
      animation-duration: .3s;
      padding: 0;
      margin: 8px 0 0;
      font-size: 10px;
      min-height: auto;
      justify-content: flex-end;
      order: 8;

      @include iconFont(checked) {
        font-size: 1em;
        margin-right: 4px;
        top: 1px;
        position: relative;
      }
    }

    &--basket-up {
      margin: -5px 0;
    }

    &--unavailable {
      max-width: 220px;
    }

    &--on-request {
      background: rgb(146 117 101 / .2);
      align-self: center;
      cursor: pointer;
      text-transform: uppercase;
      color: $textBaseColor;
      padding: 6px 12px 8px;

      &:hover {
        color: $textBaseColor;
      }
    }
  }

  &__bulk-price {
    margin: 0;

    #{$root}--type-vertical & {
      margin: 8px 0 0 -20%;
      width: 120%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__bulk-price-info {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-flow: wrap row;
    margin: 0 0 3px;
    cursor: pointer;

    &:hover {
      > span {
        background: $orangeHoverColor;
      }
    }

    &:active {
      > span {
        background: $orangeActiveColor;
      }
    }

    > span {
      display: inline-block;
      background: $orangeColor;
      color: $whiteColor;
      font-size: 12px;
      line-height: 1;
      text-transform: none;
      padding: 3px 5px 5px;
      font-weight: $fontWeightRegular;
      border-radius: 1.8px;
      margin: 0 0 -3px -5px;
      transition: background .1s linear;
    }
  }

  .button--successor-to-basket,
  &__status--successor {
    border-radius: 1.8px;
    background: $brownColor;
    color: $whiteColor;
    font-size: 12px;
    line-height: 1.2;
    padding: 2px 8px 4px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
    letter-spacing: normal;
    transition: background .15s linear, border-color .15s linear, color .15s linear, box-shadow .15s linear;

    &:hover {
      background: $brownHoverColor;
    }

    &:active {
      background: $brownActiveColor;
    }
  }

  &__status--successor {
    white-space: nowrap;

    @include iconFont(arrowRight, after) {
      font-size: 9px;
      display: inline-block;
      padding-left: 2px;
      margin: 1px 0 0;
      transition: all ease-in 300ms;
    }

    &:hover {
      &::after {
        transform: translateX(4px);
      }
    }

    &:active {
      &::after {
        transform: translateX(4px);
      }
    }
  }

  &__info-wrapper {
    width: 100%;
    display: flex;
    justify-items: center;
    flex-flow: column wrap;
    align-items: flex-start;

    #{$root}--type-horizontal & {
      margin: 0 0 8px;
    }
  }

  &__buttons-wrapper {
    #{$root}--type-horizontal & {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 18px;
      max-width: 50%;
    }

    .product-box--type-horizontal.product-box--in-basket & {
      padding-bottom: 0;
    }
  }

  &__placeholder {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.product-box-wide {
      flex-direction: row;
    }

    .loader {
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 0;
      left: 0;
    }

    .product-box__segment-image {
      position: relative;
    }

    .product-box__image {
      justify-content: center;
    }

    .product-box__image-container {
      padding: 0;
      display: flex;
      align-items: flex-end;
    }

    .product-box-wide__long-description {
      max-height: 180px;
      overflow: hidden;
    }

    .lazy-load-image__image {
      min-width: 100px;
      min-height: 270px;
    }

    img {
      max-width: 100%;
    }
  }

  .products-list--type_list & {
    #{$root}--personalization {
      margin: 4px 0 0;
    }
  }

  &__personalization-banner-wrapper {
    margin: 0 -20px;
    width: calc(100% + 40px);
    display: block;
    text-decoration: none;
  }

  &__personalization-banner {
    margin: -16px auto 16px;
  }

  &__segment-review {
    min-height: 35px;
    position: relative;
    z-index: 1;
    background: $terracottaColor;
    color: $sandColor;
    text-align: center;
    padding: 8px 24px;
    font-size: 14px;
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    line-height: 1.3;
    border-radius: 3.6px;
    margin: -16px -20px 16px;
    width: calc(100% + 40px);
    display: flex;
    text-decoration: none;
    flex-direction: column;

    &::after {
      position: absolute;
      right: 48px;
      bottom: -13px;
      content: "";
      width: 0;
      height: 0;
      border-top: 14px solid $terracottaColor;
      border-right: 16px solid transparent;
    }

    .ratings:not(.ratings--disabled) {
      font-size: 13px;
      padding: 2px 0 0;
      order: 2;

      .ratings__star {
        &::before {
          opacity: .6;
          transform: none;
          color: $ratingStarsColor;
        }
      }
    }
  }
}
