.theme-world-intro {
  margin: 0 !important;

  &__content {
    @include container(1180px);
    text-align: center;
  }

  h1,
  h2 {
    color: $orangeColor;
    margin-top: 32px;
    font-size: 24px;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 40px;
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
    }
  }

  .one-page-navigation {
    &__wrapper {
      background: $sandHoverColor;
    }

    &__container {
      @include container();
    }
  }
}

span[data-nav-anchor] {
  display: none;
}

