@import '../../../bps/components/orderPosition/orderPosition.scss';

.order-position {
  &__qty {
    order: 1;
    padding: 0 12px 0 0;
    flex: none;
  }

  &__price {
    order: 2;
    gap: 2px;

    @media (min-width: $screen-tablet-portrait) {
      gap: 8px;
    }
  }

  &__price-current {
    font-size: 16px;
  }

  &__price-old {
    font-size: 13px;
    color: $grayColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 16px;
    }

    &::after {
      border-color: $orangeColor;
    }
  }

  &__price-summary {
    order: 3;
    flex: 1 1;
    font-size: 16px;
  }

  &__promotion {
    font-size: 12px;
    margin: 0 4px 0 0;
    padding: 3px 6px 5px;
    border-radius: 1.8px;
    font-weight: $fontWeightThin;
  }

  &__tags {
    margin-bottom: 10px;
  }

  &__image {
    max-width: 70px;

    @media (min-width: $screen-mobile-sm) {
      max-width: 95px;
    }
  }

  &__image-container {
    max-width: 113px;
    margin: 0 8px 0 0;
  }

  &__name {
    font-family: $fontHeadlineFamily;
    line-height: 1.1;
    font-weight: $fontWeightBold;
    font-size: 17px;
  }

  &__reduced-flag {
    background: $orangeColor;
    border-radius: 1.8px;
    padding: 2px 5px 4px;
    font-size: 13px;
    z-index: 1;
    margin: 0 0 0 -4px;

    @media (min-width: $screen-mobile-sm) {
      margin: 0 0 0 5px;
    }
  }

  &__delete-btn {
    top: 2px;
    right: 2px;
    padding: 17px 10px 17px 17px;

    @include iconFont($productOrderPositionRemoveIcon) {
      font-size: 16px;
    }

    @media (min-width: $screen-mobile-sm) {
      padding: 17px;
    }
  }

  .availability-info {
    margin-bottom: 5px;
    flex: none;
    min-height: 24px;

    &--label {
      font-size: 13px;
      color: $textBaseColor;
    }

    &__label .availability-info--label::before {
      margin: -1px 5px 2px 0;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 5px 4px -18px;
      }
    }
  }

  .counter__input {
    background: $whiteColor;
    border: 1px solid $grayLightColor;
  }

  .counter--disabled {
    .counter__input {
      color: $grayColor;
    }
  }
}
