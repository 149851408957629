@use "sass:math";

@mixin loader($size: 30, $color: $loaderColor) {
  @include iconFont(loaderGlasA, after);
  @include iconFont(loaderSwing, before);

  &::after,
  &::before {
    font-size: #{$size}px;
    display: block;
    position: absolute;
    animation: vinosLoaderGlasRotate 1.5s infinite ease-in-out, vinosLoaderGlasAnim 1.5s infinite linear;
    color: $color;
    top: 50%;
    left: 50%;
    margin: #{math.div($size, -2)}px 0 0 #{round(math.div($size, -2.3))}px;
    padding: 0;
    z-index: 20;
  }

  &::before {
    font-size: #{math.div($size, 3)}px;
    animation: vinosLoaderSwingRotate 1.5s infinite ease-in-out, vinosLoaderSwingTop 1.5s infinite linear;
    opacity: .4;
  }
  position: relative;

  @content;
}
