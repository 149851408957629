// https://www.cssportal.com/css-triangle-generator/

@mixin triangle(
  $left: 50px,
  $right: 50px,
  $height: 86.6px,
  $color: #000
) {
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $right $height $left;
    border-color: transparent transparent $color;
    @content;
  }
}
