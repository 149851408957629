.tabs-component {
  $root: &;

  &-tab {
    font-size: 12px;
    color: $orangeColor;
    margin-left: 24px;
    list-style: none;
    transition: color .1s ease;
    text-align: center;
    border-bottom: transparent 2px solid;

    @media (min-width: $screen-mobile-sm) {
      font-size: 14px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: 18px;
      margin: 0 16px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 21px;
      margin: 0 24px;
    }

    body:not(.is_touch) & {
      &:hover {
        border-bottom: $orangeColor 2px solid;
      }
    }

    &.is-active {
      border-bottom: $orangeColor 2px solid;
      color: $blackColor;
    }

    &.is-disabled * {
      color: $buttonDisabledTextColor;
      cursor: not-allowed !important;
    }
  }

  &-tabs {
    display: flex;
    margin: 0;
    padding: 24px 16px;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 10px;

    @media (min-width: $screen-mobile-landscape) {
      justify-content: center;
    }

    @media (min-width: $screen-tablet-landscape) {
      gap: 20px;
    }

    @media (min-width: $screen-desktop) {
      overflow: hidden;
      padding: 32px 0 24px;
      gap: 40px;
    }
  }

  &-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: 2px;
    text-decoration: none;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      white-space: nowrap;
    }

    @include iconFont(arrowRight) {
      position: absolute;
      left: -14px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      color: $orangeColor;

      @media (min-width: $screen-desktop) {
        left: -28px;
        font-size: 26px;
      }

      .is-active & {
        display: none;
      }
    }

    &:empty {
      display: none;
    }
  }
}
