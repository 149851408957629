.ul {
  @mixin ulIcon($icon) {
    list-style: none;
    margin: 16px 0 !important;

    li {
      position: relative;
      padding-left: 21px;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 30px;
      }

      @include iconFont($icon) {
        position: absolute;
        left: 0;
        top: 5px;
        text-decoration: none;
        @content;
      }
    }
  }

  &--checkmark {
    @include ulIcon('checkSmall') {
      font-size: 10px;
    }
  }

  &--leaf {
    @include ulIcon('grapeVariety') {
      color: $orangeColor;
      font-size: 16px;
    }
  }

  &--place {
    @include ulIcon('region') {
      color: $orangeColor;
      font-size: 16px;
    }
  }

  &--producer {
    @include ulIcon('bodega') {
      color: $orangeColor;
      font-size: 16px;
    }
  }

  &--mix {
    list-style: none;
    margin: 16px 0 !important;

    li {
      position: relative;
      padding-left: 21px;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 30px;
      }

      &::before {
        font-family: iconfont, sans-serif;
        display: inline-block;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: $brandSecondaryColor;
        font-size: 16px;
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        text-decoration: none;
      }
    }
  }

  &--leaf,
  &--place,
  &--producer,
  &--mix {
    li {
      &.icon--checkmark::before {
        content: map-get($icons, 'checkSmall') !important;
        font-size: 10px;
        left: 5px;
      }

      &.icon--grapeVariety::before {
        content: map-get($icons, 'grapeVariety') !important;
        font-size: 16px;
      }

      &.icon--region::before {
        content: map-get($icons, 'region') !important;
        font-size: 16px;
      }

      &.icon--bodega::before {
        content: map-get($icons, 'bodega') !important;
        font-size: 16px;
      }

      &.icon--award::before {
        content: map-get($icons, 'award') !important;
        font-size: 20px;
        left: 1px;
      }

      &.icon--weinmacher::before {
        content: map-get($icons, 'weinmacher') !important;
        font-size: 22px;
      }

      &.icon--homeCava::before {
        content: map-get($icons, 'homeCava') !important;
        font-size: 22px;
        left: -1px;
      }

      &.icon--homeWine::before {
        content: map-get($icons, 'homeWine') !important;
        font-size: 20px;
      }

      &.icon--typeOfWine::before {
        content: map-get($icons, 'typeOfWine') !important;
        font-size: 18px;
      }

      &.icon--star::before {
        content: map-get($icons, 'star') !important;
        font-size: 18px;
      }
    }
  }
}
