.localisation-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__language {
    color: $sandColor;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightThin;
    text-transform: uppercase;
    margin: 0 6px 1px 0;
  }

  &__flag {
    border-radius: 2px;
    height: 12px;
  }

  &:hover {
    .localisation-button__language {
      color: $sandHoverColor;
    }
  }
}
