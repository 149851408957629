@import '../../dependencies.scss';

.illustration {
  @mixin illu($file, $size: 260) {
    background: url('../staticImages/' + $file + '-' + $size + '.png') no-repeat;
    background-size: contain;
    width: #{$size}px;
    height: #{$size}px;
  }
  display: inline-block;

  $illuList: 'cheers_glaeser',
    'familie_tisch',
    'flagge_spanien',
    'flamenco',
    'kaese',
    'kastagnetten',
    'korkenzieher',
    'kuessendes_paar',
    'kuesser',
    'mann_fernrohr',
    'medaille',
    'schinken',
    'sonne',
    'tandem',
    'tapas',
    'trauben_gruen',
    'trauben_rot',
    'weinberg',
    'weinfass',
    'zauberer_02',
    'frau_beeren',
    'katalog',
    'SST',
    'einschenk1',
    'einschenk2',
    'einschenk3',
    'glaeser_rose',
    'glaeser_weiss',
    'kaese_schinken',
    'lagerverkauf',
    'liefererung',
    'strand_rose',
    'strand_rot',
    'strand_weiss',
    'weihnachten',
    'weinglas_mann',
    'weisswein1',
    'weisswein2',
    'winzer',
    'WSH',
    'cyber_wine',
    'sgt_geschenk',
    'sgt_geschenke',
    'jubilaeum',
    'rioja',
    'wsh_rabatt_gold',
    'wsh_rabatt_rot',
    'wsh_geschenke_gold',
    'wsh_geschenke_rot',
    'silvester_rabatt',
    'liefergarantie',
    'lagerverkauf_jubi',
    'katalog2';

  @each $currentItem in $illuList {
    $i: index($illuList, $currentItem);

    &-#{$i} {
      @include illu($currentItem);
    }
  }
}
