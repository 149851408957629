.ratings {
  $root: &;
  max-width: none;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--disabled {
    justify-content: flex-end;
    flex-flow: row-reverse;
  }

  &__wrapper {
    max-width: none;
    position: relative;
    display: flex;
    flex-flow: row-reverse;
    order: 1;
  }

  &__star {
    font-size: 1.1em;
    color: $ratingStarsColor;
    position: relative;
    display: block;
    width: auto;
    height: auto;
    cursor: pointer;
    line-height: 0;
    margin: 0 1px;

    #{$root}--disabled & {
      cursor: auto;
    }

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }

    &::before {
      transition: color .5s, opacity .2s, transform .6s cubic-bezier(.175, .885, .32, 2);
    }

    #{$root}:not(#{$root}--disabled) &:hover {
      color: $ratingStarsHoverColor;

      &::before {
        color: currentColor;
      }
    }

    &--empty {
      #{$root}--disabled &::before {
        opacity: .5;
      }

      #{$root}:not(#{$root}--disabled) &::before {
        opacity: .8;
        transform: scale(.8);
        color: $ratingEmptyStarsColor;
      }
    }

    &--25,
    &--50,
    &--75 {
      @include iconFont(starFilled, after) {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        overflow: hidden;
      }
    }

    &--25::after {
      width: 35%;
    }

    &--50::after {
      width: 50%;
    }

    &--75::after {
      width: 60%;
    }

    @include iconFont(starFilled);
  }

  &__error {
    order: 2;
  }
}

.ratings:not(.ratings--disabled) {
  .ratings__star:hover,
  .ratings__star:hover ~ .ratings__star,
  .ratings__star:focus,
  .ratings__star:focus ~ .ratings__star {
    color: $ratingStarsHoverColor;
    cursor: pointer;

    &::before {
      color: $ratingStarsHoverColor;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
ratings {
  min-height: 20px;
  display: block;
}
