.filter-buttons {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $screen-mobile-landscape) {
    justify-content: space-between;
  }

  &__close-head {
    @include iconFont(close, after) {
      position: absolute;
      top: 36px;
      right: 0;
      font-size: 22px;
    }
    display: inline-block;
    padding: 12px 24px;
    letter-spacing: 1.5px;
    color: $blackColor;
    font-weight: $fontWeightRegular;
    line-height: 150%;
    font-size: 24px;
  }

  &__close-foot {
    width: 100%;
    padding: 0 32px 0 40px;
    font-size: 16px;
    font-weight: $fontWeightThin;
  }

  .button--primary {
    &::after {
      display: none;
    }
  }

  &__apply {
    width: 100%;
    font-size: 16px;
    font-weight: $fontWeightThin;

    @media (min-width: $screen-mobile-landscape) {
      margin-bottom: 16px;
    }

    &:only-child {
      width: 100%;
    }
  }

  &__cancel,
  &__reset {
    margin-top: 16px;
    display: inline-block;
    color: $grayLightColor;
    text-decoration: underline;
    white-space: nowrap;

    @media (min-width: $screen-mobile-landscape) {
      margin: 0;
    }
  }
}
