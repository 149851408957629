.tag {
  $root: &;
  padding: 4px 8px 6px;
  color: $tagColor;
  border: 1px solid $tagBorderColor;
  background: $tagBackgroundColor;
  border-radius: $tagBorderRadius;
  position: relative;
  transition: border .2s, box-shadow .2s, background-color .2s;
  text-decoration: none;
  font-size: $tagFontSize;

  &[href] {
    cursor: pointer;
  }

  &:hover {
    text-decoration: none;
    color: $tagHoverColor;
    border-color: $tagBorderHoverColor;
    background: $tagBackgroundHoverColor;

    #{$root}__button {
      color: $tagHoverColor;
    }
  }

  &:visited {
    color: $tagColor;
  }

  &--inactive {
    border: 0;

    &:hover {
      cursor: default;
      border: 0;
    }
  }

  &--button {
    padding: 4px 10px 6px 12px;
    color: $whiteColor;
    background: $brownMediumColor;
    cursor: pointer;
    font-weight: $fontWeightThin;

    &:hover {
      background-color: $brownMediumHoverColor;
      color: $whiteColor;

      #{$root}__button {
        background-color: transparent;
        color: inherit;
      }
    }
  }

  &--button-left {
    padding-left: $tagPaddingRight;

    #{$root}__button {
      right: auto;
      left: 10px;
    }
  }

  &__button {
    transition: color .2s;
    cursor: pointer;
    position: relative;

    &::before {
      font-size: 8px;
    }

    &--icon {
      &_close {
        color: $whiteColor;

        @include iconFont(close);
      }

      &_search {
        @include iconFont(search);
      }
    }
  }

  &--link {
    color: $redDarkColor !important;
    border: $redDarkColor 1px solid;
    background: transparent;
    display: inline-block;
    margin: 2px 0;

    &:hover {
      border: $redDarkColor 1px solid;
    }
  }
}
