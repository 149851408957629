.personalized-cinema-teaser {
  $root: &;
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 24px 0 56px;

  @media (min-width: $screen-tablet-portrait) {
    flex-flow: row;
    align-items: stretch;
    gap: 30px;
    padding: 40px 0;
  }

  @media (min-width: $screen-tablet-portrait) and (max-width: $screen-laptop) {
    padding-right: 32px !important;
  }

  @media (min-width: $screen-desktop-xl) {
    gap: 60px;
  }

  &::before {
    @include fullWidth();
    content: '';
    background: #ffe0c2;
    background: linear-gradient(90deg, rgb(114 74 45 / .4) 0%, rgb(0 0 0 / 0) 79.96%), #ffe0c2; // stylelint-disable-line
    position: absolute;
    bottom: 136px;
    height: 36px;

    @media (min-width: $screen-tablet-portrait) {
      bottom: 0 !important;
      height: 74px;
    }
  }

  &--no-button {
    @media (max-width: $screen-tablet-portrait - 1) {
      padding: 24px 0 0;

      &::before {
        bottom: 80px;
      }
    }
  }

  &--single-product {
    @media (max-width: $screen-tablet-portrait - 1) {
      &::before {
        bottom: 96px;
      }

      &#{$root}--no-button {
        &::before {
          bottom: 40px;
        }
      }
    }
  }

  &__intro {
    flex: 1;
  }

  &__bubble {
    background: $orangeColor;
    border-radius: 16px;
    padding: 16px 24px 24px;
    margin: 0 auto;
    color: $whiteColor;
    max-width: 450px;
    line-height: 1.3;
    font-size: 13px;

    @include triangle(0, 32px, 24px, $orangeColor) {
      position: absolute;
      transform: translate(40px, 24px) scale(-1);
    }

    @media (min-width: $screen-tablet-portrait) {
      position: relative;
      height: 100%;
      max-width: 275px;
      margin: 0 auto 0 0;
      font-size: 15px;

      &::after {
        transform: translate(0, 24px) scaleY(-1.2);
        right: -30px;
        bottom: 100px;
      }
    }

    @media (min-width: $screen-desktop) {
      font-size: 17px;
    }

    em {
      color: $blackColor;
      display: block;
      font-size: 1.25em;
      margin: 0 0 8px;

      @media (min-width: $screen-tablet-portrait) {
        transform: rotate(-8deg);
        font-size: 1.5em;
        line-height: .8;
        margin: 24px 0;
      }

      @media (min-width: $screen-laptop) {
        font-size: 50px;
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    text-align: center;

    @media (min-width: $screen-tablet-portrait) {
      bottom: 56px;

      .button {
        @include switchButtonStyle('primary>secondary');
        padding: 0 16px;
      }
    }
  }

  &__products {
    @media (min-width: $screen-tablet-portrait) {
      width: 62%;
      padding: 0;
    }

    @media (min-width: $screen-laptop) {
      width: 66%;
    }

    @media (min-width: $screen-desktop) {
      width: 75%;
    }
  }

  .product-box {
    background: transparent;
    padding: 16px 10px 32px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 16px 10px 40px;
    }

    &--type-vertical {
      .product-box__image img {
        width: 110px;

        @media (min-width: $screen-tablet-portrait) {
          width: 100px;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 110px;
        }

        @media (min-width: $screen-desktop-xl) {
          width: 120px;
        }
      }

      .product-box__segment-image {
        margin: 0 0 -32px;

        @media (min-width: $screen-tablet-portrait) {
          margin: 0 0 -40px;
        }
      }

      &.product-box--type-bottle {
        .product-box__image {
          position: relative;
          top: 4px;
        }
      }
    }

    &__product-flags {
      height: 300px !important;

      @media (min-width: $screen-tablet-portrait) {
        height: 310px !important;
      }

      @media (min-width: $screen-desktop-xl) {
        height: 335px !important;
      }
    }

    &__personalization-banner {
      margin: -16px 16px 16px;
      width: auto;
    }

    &__segment-review {
      margin: -16px 0 16px;
      width: auto;
    }
  }

  .product-box-slider .carousel__slide--single {
    max-width: 400px;
    margin: 0 auto 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 auto;
    }
  }
}
