.go-top {
  visibility: hidden;
  text-decoration: none;
  border-radius: 100%;
  display: block;
  z-index: 4;
  cursor: pointer;
  width: $goTopWidthMobile;
  height: $goTopWidthMobile;
  color: $goTopColor;
  background: $goTopBackgroundColor;
  box-shadow: $goTopBoxShadow;
  font-size: $goTopFontSizeMobile;
  position: fixed;
  bottom: -50px;
  transition: all 150ms ease-in-out;
  transform: scale(1);

  @media (min-width: $screen-desktop-xl) {
    font-size: $goTopFontSizeDesktop;
    width: $goTopWidthDesktop;
    height: $goTopWidthDesktop;
  }

  @include iconFont($goTopIcon) {
    font-size: inherit;
    color: inherit;
    transition: color .2s;
    text-align: center;
    text-shadow: $goTopIconTextShadow;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -54%);
  }

  .filter--open & {
    display: none;
  }

  .body:not(.is_touch) & {
    &:hover {
      color: $goTopHoverColor;
      background: $goTopHoverBackgroundColor;
      transform: scale(1.2);
    }
  }

  &:active {
    color: $goTopHoverColor;
    background: $goTopActiveBackgroundColor;
    transform: scale(1.1);
  }

  &__wrapper {
    @include container($maxWidthOuter);
    position: relative;
    z-index: 550;
  }

  &--sticky {
    visibility: visible;
    margin-bottom: 0;
    bottom: 20px;
    right: $goTopIconRightMobile;

    @media (min-width: 1664px) {
      right: calc(50% - 828px);
    }

    .product-list--max-list-columns-4 & {
      @media (min-width: 1664px) {
        right: $goTopIconRightMobile;
      }

      @media (min-width: 1924px) {
        right: calc(50% - 948px);
      }
    }
  }

  &--with-bottom-space {
    @media (max-width: $screen-tablet-portrait - 1) {
      bottom: 76px;
    }
  }
}
