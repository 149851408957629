.service-teaser {
  position: relative;
  text-decoration: none;
  text-align: center;

  &:nth-child(n+3) {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &:hover,
  &:visited {
    text-decoration: none;
  }

  &::before {
    font-size: 40px;
    color: $blackColor;

    @media (min-width: $screen-desktop-sm) {
      font-size: 56px;
    }
  }

  &__headline {
    margin: 3px 0;
    color: $blackColor;
    font-weight: $fontWeightSemiBold;
    font-size: 11px;
    letter-spacing: .1px;
    line-height: 1.3;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 17px;
    }
  }

  &__subline {
    color: $brownMediumColor;
    font-weight: $fontWeightRegular;
    font-size: 11px;
    letter-spacing: .1px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 17px;
    }
  }
}
