.component-placeholder {
  animation: 1s linear forwards infinite placeHolderAnimation;
  background: transparent;
  background: linear-gradient(to right, transparent 8%, #dad4c2 18%, transparent 33%); // stylelint-disable-line  plugin/no-unsupported-browser-features
  background-size: 200% 100%;
  position: relative;
}

@keyframes placeHolderAnimation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

@mixin componentPreloader() {
  @extend .component-placeholder;

  @content;
}
