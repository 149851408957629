.localisation-switch-layer {
  width: 100%;
  max-width: 375px;
  background: $whiteColor;
  padding: 20px;
  position: absolute;
  border-radius: 20px 20px 0 0;
  z-index: $indexLightbox;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  @media (min-width: $screen-tablet-portrait) {
    padding: 24px;
    top: 0;
    right: 24px;
    bottom: auto;
    left: auto;
    border-radius: 0 0 8px 8px;
    transform: none;
  }

  &__head {
    padding: 0 24px 22px 0;
    margin-bottom: 8px;
    font-size: 18px;
    color: $textBaseColor;
  }

  &__close {
    @include iconFont(close);
    font-size: 24px;
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 12px;
    cursor: pointer;
    color: $lightboxCloseBtnColor;

    @media (min-width: $screen-tablet-portrait) {
      right: 8px;
      top: 8px;
    }
  }

  &__btn {
    margin-top: 16px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 40px;
    font-size: 16px;

    &::after {
      display: none;
    }
  }

  .select + .select {
    margin-top: 12px;
  }

  &-container {
    @include container();
    position: absolute;
    bottom: 0;

    @media (min-width: $screen-tablet-portrait) {
      position: relative;
    }
  }

  &-animate-top {
    &-enter-active,
    &-leave-active {
      transition: transform 300ms ease-in-out;
      transform: translate(-50%, 0);

      @media (min-width: $screen-tablet-portrait) {
        transform: translate(0, 0);
      }
    }

    &-enter-from,
    &-leave-to {
      transform: translate(-50%, 100%);

      @media (min-width: $screen-tablet-portrait) {
        transform: translate(0, -100%);
      }
    }
  }
}
