.header-meta-content {
  padding: 1px 0 3px 24px;
  display: flex;
  justify-content: flex-end;

  &__links {
    .html-teaser {
      text-align: right;

      a:not(.button) {
        color: $sandColor;
        font-size: 13px;
        font-weight: $fontWeightThin;
        text-align: right;
        line-height: 140%;

        &:last-child {
          padding-right: 0;
        }

        &:visited {
          color: $sandColor;
        }

        &:hover {
          text-decoration: underline;
          color: $sandHoverColor !important;
        }

        &:active {
          color: $sandActiveColor !important;
        }
      }
    }
  }

  .localisation-button {
    margin: 0 1px 0 20px;
  }
}
