.product-box-slider {
  .carousel {
    &__wrapper {
      padding-bottom: 16px;
      margin-bottom: -16px;
    }

    &__slide--single {
      width: 100%;
      max-width: 490px;
      margin: auto;

      @media (min-width: $screen-tablet-portrait) {
        max-width: none;
      }
    }

    &__button {
      &--prev {
        top: calc(50% - 20px);
        height: auto;

        @media (min-width: $screen-tablet-portrait) {
          left: -10px;
        }

        @media (min-width: $screen-desktop-sm) {
          left: -20px;
        }

        @media (min-width: $screen-desktop-xxl) {
          left: -65px;
        }
      }

      &--next {
        top: calc(50% - 20px);
        height: auto;

        @media (min-width: $screen-tablet-portrait) {
          right: -10px;
        }

        @media (min-width: $screen-desktop-sm) {
          right: -20px;
        }

        @media (min-width: $screen-desktop-xxl) {
          right: -65px;
        }
      }
    }
  }

  &--itemcount_2 {
    max-width: 720px;
    margin: 0 auto;
  }
}

// Placeholder style
/* stylelint-disable-next-line */
product-box-slider,
[is="product-box-slider"] {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  overflow: hidden;

  > .product-box {
    width: auto;
    max-width: 289px;
    margin-right: 24px;
    min-width: 289px;
    padding: 0;
    min-height: 394px;

    .product-box__placeholder {
      padding: 16px;
    }
  }
}
