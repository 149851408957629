@import '../../../bps/components/modal/modal.scss';

.modal {
  &--success {
    color: $whiteColor;
  }

  &--warning {
    color: $whiteColor;
  }

  &--error {
    color: $whiteColor;
  }

  &__text-big {
    color: $whiteColor;
    font-size: 14px;
    line-height: 140%;
    margin: 0 16px 0 0;
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 18px;
    }

    a {
      color: $whiteColor;
      text-decoration: underline;
    }
  }
}
