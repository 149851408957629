.price-info {
  $root: &;
  color: $priceColor;
  font-family: $fontPriceFamily;
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
  align-items: baseline;
  font-size: 28px;

  &--reduction {
    color: $grayColor;
  }

  a {
    color: $grayColor;
    text-decoration: underline;
  }

  &__current {
    white-space: nowrap;
    color: $priceColor;
    font-weight: $fontWeightRegular;
    font-size: 1.6em;
    line-height: 120%;
    display: flex;
    position: relative;

    #{$root}--reduction & {
      color: $priceDiscountColor;
    }

    &--line-through::after {
      position: absolute;
      content: "";
      left: 0;
      top: 58%;
      right: 0;
      border-top: 1.5px solid;
      border-color: $orangeColor;
      transform: rotate(-9.5deg);

      @media (min-width: $screen-tablet-portrait) {
        border-top: 2.5px solid;
        border-color: $orangeColor;
      }
    }
  }

  &__currency {
    font-size: 1em;
    padding-left: 6px;
  }

  &__old {
    font-size: 1em;
    align-items: baseline;
    color: $priceColor;
  }

  &__old-text {
    display: none;
  }

  &__old-value {
    white-space: nowrap;
    font-weight: $fontWeightRegular;
    line-height: 120%;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 58%;
      right: 0;
      border-top: 1.5px solid;
      border-color: $orangeColor;
      transform: rotate(-9.5deg);

      @media (min-width: $screen-tablet-portrait) {
        border-top: 2.5px solid;
        border-color: $orangeColor;
      }
    }
  }

  &__asterisk {
    font-size: .5em;
    color: $grayDarkColor;
    font-weight: $fontWeightRegular;
    position: relative;
    top: auto;
    line-height: 1.4em;
    vertical-align: top;

    #{$root}--reduction & {
      color: $priceDiscountColor;
    }
  }

  &__unit,
  &__food-specification-link {
    color: $grayColor;
    font-size: 10px;
    font-weight: $fontWeightRegular;
    font-family: $fontPrimaryFamily;
  }

  &__unit-text,
  &__unit-price,
  &__unit-info {
    white-space: nowrap;
  }

  &__unit-price {
    display: inline-flex;
  }

  &__tax {
    font-size: .1em;

    &--article-number {
      font-size: 9px;
      color: $brownMediumColor;
      font-weight: $fontWeightRegular;
      font-family: $fontFamily;
      display: flex;
      align-items: center;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 11px;
      }
    }
  }

  &__value,
  &__old-value {
    display: flex;
  }

  &__old-value-cent,
  &__value-cent {
    font-size: .6em;
    line-height: 1.6;
    padding: 0;
  }

  &--price-line-through {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-items: flex-end;

    .price-info__current {
      color: $grayDarkColor !important;
      margin: 0 8px 0 0;
    }

    .price-info__unit {
      margin: 0 0 7px;
    }

    .price-info__value {
      position: relative;
      float: left;
      margin: 0 8px 0 0;

      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background: $grayDarkColor;
        top: 51%;
        left: 0;
        display: block;
        position: absolute;
      }
    }
  }

  &__savedPercentage {
    color: $whiteColor;
    background: $orangeColor;
    font-size: 13px;
    padding: 0 4px 2px;
    margin: 0 0 0 4px;
    font-weight: $fontWeightRegular;
    border-radius: 2px;
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &--currency-CHF {
    .price-info {
      &__currency {
        padding-left: 0;
        margin-left: 0;
        margin-right: 4px;
        order: 1;
        font-size: .75em;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        line-height: 1.3;
      }

      &__value,
      &__old-value-euro {
        order: 2;
      }

      &__old-value-cent {
        order: 3;
      }

      &__asterisk {
        order: 4;
      }
    }
  }

  &--inline {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: baseline;
    font-size: 52px;

    #{$root}__old,
    #{$root}__current {
      width: 50%;
    }

    #{$root}__current,
    #{$root}__unit {
      @media (min-width: $screen-tablet-portrait) {
        display: inline-block;
      }
    }

    #{$root}__old {
      font-size: .55em;
      display: flex;
      justify-content: flex-end;
      padding: 0 8px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        font-size: .65em;
      }

      @media (min-width: $screen-tablet-landscape) {
        font-size: .75em;
        padding: 0 12px 0 0;
      }
    }

    #{$root}__old-value {
      &::after {
        border-top: 3px solid $orangeColor;
      }
    }

    #{$root}__current {
      font-size: .8em;
      text-align: left;
      display: flex;
      padding: 0 0 0 8px;
      justify-content: center;

      @media (min-width: $screen-tablet-portrait) {
        font-size: .9em;
      }

      @media (min-width: $screen-tablet-landscape) {
        font-size: 1em;
        padding: 0 0 0 12px;
      }
    }

    &#{$root}--reduction {
      #{$root}__current {
        justify-content: flex-start;
      }
    }

    #{$root}__unit {
      font-size: 10px;
    }

    #{$root}__info {
      justify-content: space-between;
      min-height: 15px;
      justify-items: center;
      margin: 2px 0 0;

      #{$root}__unit,
      #{$root}__food-specification-link {
        width: 50%;
        text-align: left;
        min-height: 14px;
        padding: 0 12px;
      }

      #{$root}__food-specification-link {
        border-right: 1px solid $grayColor;
        text-align: right;
      }
    }
  }
}
