.burger-menu {
  width: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  transform: translateX(-510px);
  transition: transform 500ms;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  z-index: $indexBurgerMenu;
  background: $backgroundWhiteColor;
  padding-top: 55px;
  max-width: 480px;

  @media (min-width: $screen-tablet-portrait) {
    top: 83px;
    left: 24px;
    height: calc(100% - 83px);
  }

  &__wrapper {
    position: relative;

    &-background {
      @media (max-width: $screen-laptop) {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: transparent;
        z-index: 1290;
      }
    }
  }

  &--open {
    transform: translateX(0);

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }
  }

  &__close-button {
    display: none;
  }

  &__nav-wrapper {
    margin-top: -50px;
  }

  &__loader {
    @include spinner();
    animation: fadeIn .5s;
    animation-delay: 20ms;
  }

  &__localisation-btn {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 24px;
  }
}

// Placeholder style
/* stylelint-disable-next-line */
burger-menu,
[is="burger-menu"] {
  display: none;
}
