@mixin container($containerWidth: $maxWidthOuter) {
  width: 100%;
  max-width: $containerWidth;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-mobile-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: $screen-desktop-sm) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
