@import '../../../bps/components/quickfacts/quickfacts.scss';

.quickfacts {
  &__item {
    text-align: left;

    &::before {
      content: "";
      color: $redDarkColor;
      margin: auto;
      position: absolute;
      display: inline-block;
      top: 3px;
      left: 0;
      min-height: auto;
      padding: 0;
      font-size: 16px;
      min-width: 16px;
      text-align: center;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 18px;
        min-width: 23px;
      }
    }

    &--icon-bodega,
    &--icon-brand {
      @include iconFont(qfBrand);
    }

    &--icon-bottleSize,
    &--icon-ekwgr {
      @include iconFont(qfBottle);
    }

    &--icon-area,
    &--icon-origin {
      @include iconFont(qfLocation);
    }

    &--icon-taste,
    &--icon-acidContent {
      @include iconFont(qfGlass);
    }

    &--icon-grapeVariety {
      @include iconFont(qfGrape);
    }
  }

  &__label {
    color: $grayColor;
    font-weight: $fontWeightBold;
    display: inline-block;
    float: left;
    font-size: 11px;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 0 0 26px;
  }

  &__value {
    color: $grayDarkColor;
    font-weight: $fontWeightRegular;
    display: block;
    line-height: 1.2;
    padding: 0 0 0 26px;
    font-size: 12px;

    a {
      color: $grayDarkColor;
      font-weight: $fontWeightRegular;
      font-size: 10px;
      line-height: 1.2;
      text-decoration: none;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 12px;
        line-height: 1.4;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__value-item {
    display: inline-block;
  }
}
