.pagination {
  color: $paginationTextColor;
  font-size: $paginationBaseFontSize;

  @media (min-width: $screen-tablet-landscape) {
    font-size: $paginationSelectFontSize;
  }

  &__button-container {
    &.pagination__button-container--show-page-buttons {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: $paginationButtonsJustifyContent;
      min-width: 175px;
    }
  }

  &__pages {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: $paginationPagesFlexGrow;
    margin: 0;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: $blackColor;
    transition: color .2s;

    &--prev {
      @include iconFont(arrowLeft);
    }

    &--next {
      @include iconFont(arrowRight);
      margin-right: -10px;

      @media (min-width: $screen-tablet-landscape) {
        margin-right: $paginationButtonRightMargin;
      }
    }

    &::before {
      font-weight: $fontWeightRegular;
    }

    &--disabled {
      cursor: not-allowed;
      display: none;

      &:hover {
        color: $textBaseColor;
      }
    }
  }

  &__wrapper {
    position: relative;

    &--disabled {
      &::before {
        content: '';
      }
    }

    & .select {
      color: $blackColor;

      &__wrapper {
        padding-right: 32px;

        .select__selected-value {
          padding: 0 12px;
          font-size: 16px;
          color: $blackColor;
          font-weight: $fontWeightRegular;
        }
      }
    }
  }

  &__label,
  &__pages-count {
    font-size: 13px;
    line-height: 1.4;
    color: $blackColor;
  }

  &__label {
    margin-right: 8px;
  }

  .select--formless .select__custom-dropdown {
    @include customScrollbar(4px, $grayLighterColor);
    border-right: 2px solid $paginationSelectBackgroundColor;

    &--visible {
      border-bottom: 4px solid $paginationSelectBackgroundColor;
    }
  }

  .select {
    margin: $paginationSelectMargin;
    color: $paginationSelectColor;

    &__wrapper::before {
      color: $blackColor;
      font-size: 12px;
      font-weight: $fontWeightRegular !important;
    }

    &__selected-value {
      font-weight: $paginationSelectFontWeight;
      font-size: $paginationBaseFontSize;

      @media (min-width: $screen-tablet-landscape) {
        font-size: $paginationSelectFontSize;
      }
    }

    &__custom-option {
      font-size: $baseFontSize;

      &--selected {
        color: $selectActiveColor;
        font-weight: $selectFontWeightBold;
      }
    }
  }

  &__placeholder {
    @include componentPreloader();
    min-height: 46px;
    padding-right: 16px;
  }
}
