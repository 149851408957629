.address-suggestion {
  position: relative;

  &-container {
    position: absolute;
    top: 33px;
    left: 16px;
    right: 16px;
    max-height: 120px;
    overflow-y: auto;
    border: 1px solid $addressSuggestionBorderColor;
    background-color: $addressSuggestionBackgroundColor;
    padding: 4px 8px;
    z-index: 600;

    &--more-spacing {
      top: 96px;
    }

    &--field {
      left: 8px;
      right: 8px;
    }
  }

  &-text-input {
    border-color: $addressSuggestionBorderColor;
  }

  &-element {
    margin: 0 -8px;
    padding: 0 12px;
    color: $addressSuggestionColor;
    font-size: 16px;
    line-height: 1.4;
    cursor: pointer;

    &-selected {
      background-color: $addressSuggestionHoverBackgroundColor;
      color: $addressSuggestionHoverColor;
    }
  }

  &--hide {
    display: none;
  }

  &__myPostPickPostUserId {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span:first-child {
      font-size: $fontSizeXSmall;
      margin: auto 5px auto auto;
    }

    div.input:nth-child(2) {
      width: 80%;
    }
  }
}
