// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-amex-name: 'sprite-amex';
$sprite-amex-x: 0px;
$sprite-amex-y: 429px;
$sprite-amex-offset-x: 0px;
$sprite-amex-offset-y: -429px;
$sprite-amex-width: 51px;
$sprite-amex-height: 36px;
$sprite-amex-total-width: 205px;
$sprite-amex-total-height: 1200px;
$sprite-amex-image: '../staticImages/img-sprite.png';
$sprite-amex: (0px, 429px, 0px, -429px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-amex', );
$sprite-apple-pay-name: 'sprite-apple-pay';
$sprite-apple-pay-x: 0px;
$sprite-apple-pay-y: 465px;
$sprite-apple-pay-offset-x: 0px;
$sprite-apple-pay-offset-y: -465px;
$sprite-apple-pay-width: 51px;
$sprite-apple-pay-height: 36px;
$sprite-apple-pay-total-width: 205px;
$sprite-apple-pay-total-height: 1200px;
$sprite-apple-pay-image: '../staticImages/img-sprite.png';
$sprite-apple-pay: (0px, 465px, 0px, -465px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-apple-pay', );
$sprite-creditcard-name: 'sprite-creditcard';
$sprite-creditcard-x: 0px;
$sprite-creditcard-y: 184px;
$sprite-creditcard-offset-x: 0px;
$sprite-creditcard-offset-y: -184px;
$sprite-creditcard-width: 51px;
$sprite-creditcard-height: 35px;
$sprite-creditcard-total-width: 205px;
$sprite-creditcard-total-height: 1200px;
$sprite-creditcard-image: '../staticImages/img-sprite.png';
$sprite-creditcard: (0px, 184px, 0px, -184px, 51px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-creditcard', );
$sprite-creditcards-wide-name: 'sprite-creditcards-wide';
$sprite-creditcards-wide-x: 0px;
$sprite-creditcards-wide-y: 219px;
$sprite-creditcards-wide-offset-x: 0px;
$sprite-creditcards-wide-offset-y: -219px;
$sprite-creditcards-wide-width: 182px;
$sprite-creditcards-wide-height: 35px;
$sprite-creditcards-wide-total-width: 205px;
$sprite-creditcards-wide-total-height: 1200px;
$sprite-creditcards-wide-image: '../staticImages/img-sprite.png';
$sprite-creditcards-wide: (0px, 219px, 0px, -219px, 182px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-creditcards-wide', );
$sprite-dhl-name: 'sprite-dhl';
$sprite-dhl-x: 0px;
$sprite-dhl-y: 52px;
$sprite-dhl-offset-x: 0px;
$sprite-dhl-offset-y: -52px;
$sprite-dhl-width: 85px;
$sprite-dhl-height: 19px;
$sprite-dhl-total-width: 205px;
$sprite-dhl-total-height: 1200px;
$sprite-dhl-image: '../staticImages/img-sprite.png';
$sprite-dhl: (0px, 52px, 0px, -52px, 85px, 19px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-dhl', );
$sprite-dhl-mini-name: 'sprite-dhl_mini';
$sprite-dhl-mini-x: 0px;
$sprite-dhl-mini-y: 0px;
$sprite-dhl-mini-offset-x: 0px;
$sprite-dhl-mini-offset-y: 0px;
$sprite-dhl-mini-width: 52px;
$sprite-dhl-mini-height: 13px;
$sprite-dhl-mini-total-width: 205px;
$sprite-dhl-mini-total-height: 1200px;
$sprite-dhl-mini-image: '../staticImages/img-sprite.png';
$sprite-dhl-mini: (0px, 0px, 0px, 0px, 52px, 13px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-dhl_mini', );
$sprite-dhlgogreen-name: 'sprite-dhlgogreen';
$sprite-dhlgogreen-x: 0px;
$sprite-dhlgogreen-y: 1020px;
$sprite-dhlgogreen-offset-x: 0px;
$sprite-dhlgogreen-offset-y: -1020px;
$sprite-dhlgogreen-width: 128px;
$sprite-dhlgogreen-height: 90px;
$sprite-dhlgogreen-total-width: 205px;
$sprite-dhlgogreen-total-height: 1200px;
$sprite-dhlgogreen-image: '../staticImages/img-sprite.png';
$sprite-dhlgogreen: (0px, 1020px, 0px, -1020px, 128px, 90px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-dhlgogreen', );
$sprite-dhlgowhite-name: 'sprite-dhlgowhite';
$sprite-dhlgowhite-x: 0px;
$sprite-dhlgowhite-y: 1110px;
$sprite-dhlgowhite-offset-x: 0px;
$sprite-dhlgowhite-offset-y: -1110px;
$sprite-dhlgowhite-width: 128px;
$sprite-dhlgowhite-height: 90px;
$sprite-dhlgowhite-total-width: 205px;
$sprite-dhlgowhite-total-height: 1200px;
$sprite-dhlgowhite-image: '../staticImages/img-sprite.png';
$sprite-dhlgowhite: (0px, 1110px, 0px, -1110px, 128px, 90px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-dhlgowhite', );
$sprite-ec-name: 'sprite-ec';
$sprite-ec-x: 0px;
$sprite-ec-y: 825px;
$sprite-ec-offset-x: 0px;
$sprite-ec-offset-y: -825px;
$sprite-ec-width: 64px;
$sprite-ec-height: 37px;
$sprite-ec-total-width: 205px;
$sprite-ec-total-height: 1200px;
$sprite-ec-image: '../staticImages/img-sprite.png';
$sprite-ec: (0px, 825px, 0px, -825px, 64px, 37px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-ec', );
$sprite-facebook-name: 'sprite-facebook';
$sprite-facebook-x: 0px;
$sprite-facebook-y: 120px;
$sprite-facebook-offset-x: 0px;
$sprite-facebook-offset-y: -120px;
$sprite-facebook-width: 32px;
$sprite-facebook-height: 32px;
$sprite-facebook-total-width: 205px;
$sprite-facebook-total-height: 1200px;
$sprite-facebook-image: '../staticImages/img-sprite.png';
$sprite-facebook: (0px, 120px, 0px, -120px, 32px, 32px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-facebook', );
$sprite-faktura-name: 'sprite-faktura';
$sprite-faktura-x: 0px;
$sprite-faktura-y: 862px;
$sprite-faktura-offset-x: 0px;
$sprite-faktura-offset-y: -862px;
$sprite-faktura-width: 64px;
$sprite-faktura-height: 37px;
$sprite-faktura-total-width: 205px;
$sprite-faktura-total-height: 1200px;
$sprite-faktura-image: '../staticImages/img-sprite.png';
$sprite-faktura: (0px, 862px, 0px, -862px, 64px, 37px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-faktura', );
$sprite-forecast-dhl-name: 'sprite-forecast-dhl';
$sprite-forecast-dhl-x: 0px;
$sprite-forecast-dhl-y: 254px;
$sprite-forecast-dhl-offset-x: 0px;
$sprite-forecast-dhl-offset-y: -254px;
$sprite-forecast-dhl-width: 51px;
$sprite-forecast-dhl-height: 35px;
$sprite-forecast-dhl-total-width: 205px;
$sprite-forecast-dhl-total-height: 1200px;
$sprite-forecast-dhl-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl: (0px, 254px, 0px, -254px, 51px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl', );
$sprite-forecast-dhl-eu-name: 'sprite-forecast-dhl_eu';
$sprite-forecast-dhl-eu-x: 0px;
$sprite-forecast-dhl-eu-y: 289px;
$sprite-forecast-dhl-eu-offset-x: 0px;
$sprite-forecast-dhl-eu-offset-y: -289px;
$sprite-forecast-dhl-eu-width: 51px;
$sprite-forecast-dhl-eu-height: 35px;
$sprite-forecast-dhl-eu-total-width: 205px;
$sprite-forecast-dhl-eu-total-height: 1200px;
$sprite-forecast-dhl-eu-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl-eu: (0px, 289px, 0px, -289px, 51px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl_eu', );
$sprite-forecast-post-ch-name: 'sprite-forecast-post_ch';
$sprite-forecast-post-ch-x: 0px;
$sprite-forecast-post-ch-y: 324px;
$sprite-forecast-post-ch-offset-x: 0px;
$sprite-forecast-post-ch-offset-y: -324px;
$sprite-forecast-post-ch-width: 51px;
$sprite-forecast-post-ch-height: 35px;
$sprite-forecast-post-ch-total-width: 205px;
$sprite-forecast-post-ch-total-height: 1200px;
$sprite-forecast-post-ch-image: '../staticImages/img-sprite.png';
$sprite-forecast-post-ch: (0px, 324px, 0px, -324px, 51px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-forecast-post_ch', );
$sprite-giftvoucher-name: 'sprite-giftvoucher';
$sprite-giftvoucher-x: 0px;
$sprite-giftvoucher-y: 501px;
$sprite-giftvoucher-offset-x: 0px;
$sprite-giftvoucher-offset-y: -501px;
$sprite-giftvoucher-width: 51px;
$sprite-giftvoucher-height: 36px;
$sprite-giftvoucher-total-width: 205px;
$sprite-giftvoucher-total-height: 1200px;
$sprite-giftvoucher-image: '../staticImages/img-sprite.png';
$sprite-giftvoucher: (0px, 501px, 0px, -501px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-giftvoucher', );
$sprite-gogreen-mini-name: 'sprite-gogreen_mini';
$sprite-gogreen-mini-x: 0px;
$sprite-gogreen-mini-y: 13px;
$sprite-gogreen-mini-offset-x: 0px;
$sprite-gogreen-mini-offset-y: -13px;
$sprite-gogreen-mini-width: 52px;
$sprite-gogreen-mini-height: 13px;
$sprite-gogreen-mini-total-width: 205px;
$sprite-gogreen-mini-total-height: 1200px;
$sprite-gogreen-mini-image: '../staticImages/img-sprite.png';
$sprite-gogreen-mini: (0px, 13px, 0px, -13px, 52px, 13px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-gogreen_mini', );
$sprite-hermes-name: 'sprite-hermes';
$sprite-hermes-x: 0px;
$sprite-hermes-y: 71px;
$sprite-hermes-offset-x: 0px;
$sprite-hermes-offset-y: -71px;
$sprite-hermes-width: 119px;
$sprite-hermes-height: 21px;
$sprite-hermes-total-width: 205px;
$sprite-hermes-total-height: 1200px;
$sprite-hermes-image: '../staticImages/img-sprite.png';
$sprite-hermes: (0px, 71px, 0px, -71px, 119px, 21px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-hermes', );
$sprite-instagram-name: 'sprite-instagram';
$sprite-instagram-x: 0px;
$sprite-instagram-y: 152px;
$sprite-instagram-offset-x: 0px;
$sprite-instagram-offset-y: -152px;
$sprite-instagram-width: 32px;
$sprite-instagram-height: 32px;
$sprite-instagram-total-width: 205px;
$sprite-instagram-total-height: 1200px;
$sprite-instagram-image: '../staticImages/img-sprite.png';
$sprite-instagram: (0px, 152px, 0px, -152px, 32px, 32px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-instagram', );
$sprite-mastercard-name: 'sprite-mastercard';
$sprite-mastercard-x: 0px;
$sprite-mastercard-y: 537px;
$sprite-mastercard-offset-x: 0px;
$sprite-mastercard-offset-y: -537px;
$sprite-mastercard-width: 51px;
$sprite-mastercard-height: 36px;
$sprite-mastercard-total-width: 205px;
$sprite-mastercard-total-height: 1200px;
$sprite-mastercard-image: '../staticImages/img-sprite.png';
$sprite-mastercard: (0px, 537px, 0px, -537px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-mastercard', );
$sprite-packstation-name: 'sprite-packstation';
$sprite-packstation-x: 0px;
$sprite-packstation-y: 92px;
$sprite-packstation-offset-x: 0px;
$sprite-packstation-offset-y: -92px;
$sprite-packstation-width: 128px;
$sprite-packstation-height: 28px;
$sprite-packstation-total-width: 205px;
$sprite-packstation-total-height: 1200px;
$sprite-packstation-image: '../staticImages/img-sprite.png';
$sprite-packstation: (0px, 92px, 0px, -92px, 128px, 28px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-packstation', );
$sprite-packstation-mini-name: 'sprite-packstation_mini';
$sprite-packstation-mini-x: 0px;
$sprite-packstation-mini-y: 26px;
$sprite-packstation-mini-offset-x: 0px;
$sprite-packstation-mini-offset-y: -26px;
$sprite-packstation-mini-width: 62px;
$sprite-packstation-mini-height: 13px;
$sprite-packstation-mini-total-width: 205px;
$sprite-packstation-mini-total-height: 1200px;
$sprite-packstation-mini-image: '../staticImages/img-sprite.png';
$sprite-packstation-mini: (0px, 26px, 0px, -26px, 62px, 13px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-packstation_mini', );
$sprite-packstation-small-name: 'sprite-packstation_small';
$sprite-packstation-small-x: 0px;
$sprite-packstation-small-y: 359px;
$sprite-packstation-small-offset-x: 0px;
$sprite-packstation-small-offset-y: -359px;
$sprite-packstation-small-width: 51px;
$sprite-packstation-small-height: 35px;
$sprite-packstation-small-total-width: 205px;
$sprite-packstation-small-total-height: 1200px;
$sprite-packstation-small-image: '../staticImages/img-sprite.png';
$sprite-packstation-small: (0px, 359px, 0px, -359px, 51px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-packstation_small', );
$sprite-paketshop-name: 'sprite-paketshop';
$sprite-paketshop-x: 0px;
$sprite-paketshop-y: 394px;
$sprite-paketshop-offset-x: 0px;
$sprite-paketshop-offset-y: -394px;
$sprite-paketshop-width: 51px;
$sprite-paketshop-height: 35px;
$sprite-paketshop-total-width: 205px;
$sprite-paketshop-total-height: 1200px;
$sprite-paketshop-image: '../staticImages/img-sprite.png';
$sprite-paketshop: (0px, 394px, 0px, -394px, 51px, 35px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-paketshop', );
$sprite-paypal-name: 'sprite-paypal';
$sprite-paypal-x: 0px;
$sprite-paypal-y: 573px;
$sprite-paypal-offset-x: 0px;
$sprite-paypal-offset-y: -573px;
$sprite-paypal-width: 51px;
$sprite-paypal-height: 36px;
$sprite-paypal-total-width: 205px;
$sprite-paypal-total-height: 1200px;
$sprite-paypal-image: '../staticImages/img-sprite.png';
$sprite-paypal: (0px, 573px, 0px, -573px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-paypal', );
$sprite-paypalcheckout-name: 'sprite-paypalcheckout';
$sprite-paypalcheckout-x: 0px;
$sprite-paypalcheckout-y: 976px;
$sprite-paypalcheckout-offset-x: 0px;
$sprite-paypalcheckout-offset-y: -976px;
$sprite-paypalcheckout-width: 205px;
$sprite-paypalcheckout-height: 44px;
$sprite-paypalcheckout-total-width: 205px;
$sprite-paypalcheckout-total-height: 1200px;
$sprite-paypalcheckout-image: '../staticImages/img-sprite.png';
$sprite-paypalcheckout: (0px, 976px, 0px, -976px, 205px, 44px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-paypalcheckout', );
$sprite-paypalexpress-name: 'sprite-paypalexpress';
$sprite-paypalexpress-x: 0px;
$sprite-paypalexpress-y: 609px;
$sprite-paypalexpress-offset-x: 0px;
$sprite-paypalexpress-offset-y: -609px;
$sprite-paypalexpress-width: 51px;
$sprite-paypalexpress-height: 36px;
$sprite-paypalexpress-total-width: 205px;
$sprite-paypalexpress-total-height: 1200px;
$sprite-paypalexpress-image: '../staticImages/img-sprite.png';
$sprite-paypalexpress: (0px, 609px, 0px, -609px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-paypalexpress', );
$sprite-post-ch-name: 'sprite-post_ch';
$sprite-post-ch-x: 0px;
$sprite-post-ch-y: 39px;
$sprite-post-ch-offset-x: 0px;
$sprite-post-ch-offset-y: -39px;
$sprite-post-ch-width: 45px;
$sprite-post-ch-height: 13px;
$sprite-post-ch-total-width: 205px;
$sprite-post-ch-total-height: 1200px;
$sprite-post-ch-image: '../staticImages/img-sprite.png';
$sprite-post-ch: (0px, 39px, 0px, -39px, 45px, 13px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-post_ch', );
$sprite-postfinance-name: 'sprite-postfinance';
$sprite-postfinance-x: 0px;
$sprite-postfinance-y: 645px;
$sprite-postfinance-offset-x: 0px;
$sprite-postfinance-offset-y: -645px;
$sprite-postfinance-width: 51px;
$sprite-postfinance-height: 36px;
$sprite-postfinance-total-width: 205px;
$sprite-postfinance-total-height: 1200px;
$sprite-postfinance-image: '../staticImages/img-sprite.png';
$sprite-postfinance: (0px, 645px, 0px, -645px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-postfinance', );
$sprite-proclima-name: 'sprite-proclima';
$sprite-proclima-x: 0px;
$sprite-proclima-y: 936px;
$sprite-proclima-offset-x: 0px;
$sprite-proclima-offset-y: -936px;
$sprite-proclima-width: 45px;
$sprite-proclima-height: 40px;
$sprite-proclima-total-width: 205px;
$sprite-proclima-total-height: 1200px;
$sprite-proclima-image: '../staticImages/img-sprite.png';
$sprite-proclima: (0px, 936px, 0px, -936px, 45px, 40px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-proclima', );
$sprite-rechnung-name: 'sprite-rechnung';
$sprite-rechnung-x: 0px;
$sprite-rechnung-y: 681px;
$sprite-rechnung-offset-x: 0px;
$sprite-rechnung-offset-y: -681px;
$sprite-rechnung-width: 51px;
$sprite-rechnung-height: 36px;
$sprite-rechnung-total-width: 205px;
$sprite-rechnung-total-height: 1200px;
$sprite-rechnung-image: '../staticImages/img-sprite.png';
$sprite-rechnung: (0px, 681px, 0px, -681px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-rechnung', );
$sprite-rechnungch-name: 'sprite-rechnungch';
$sprite-rechnungch-x: 0px;
$sprite-rechnungch-y: 717px;
$sprite-rechnungch-offset-x: 0px;
$sprite-rechnungch-offset-y: -717px;
$sprite-rechnungch-width: 51px;
$sprite-rechnungch-height: 36px;
$sprite-rechnungch-total-width: 205px;
$sprite-rechnungch-total-height: 1200px;
$sprite-rechnungch-image: '../staticImages/img-sprite.png';
$sprite-rechnungch: (0px, 717px, 0px, -717px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-rechnungch', );
$sprite-sepa-name: 'sprite-sepa';
$sprite-sepa-x: 0px;
$sprite-sepa-y: 753px;
$sprite-sepa-offset-x: 0px;
$sprite-sepa-offset-y: -753px;
$sprite-sepa-width: 51px;
$sprite-sepa-height: 36px;
$sprite-sepa-total-width: 205px;
$sprite-sepa-total-height: 1200px;
$sprite-sepa-image: '../staticImages/img-sprite.png';
$sprite-sepa: (0px, 753px, 0px, -753px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-sepa', );
$sprite-sofortuberweisung-name: 'sprite-sofortuberweisung';
$sprite-sofortuberweisung-x: 0px;
$sprite-sofortuberweisung-y: 899px;
$sprite-sofortuberweisung-offset-x: 0px;
$sprite-sofortuberweisung-offset-y: -899px;
$sprite-sofortuberweisung-width: 64px;
$sprite-sofortuberweisung-height: 37px;
$sprite-sofortuberweisung-total-width: 205px;
$sprite-sofortuberweisung-total-height: 1200px;
$sprite-sofortuberweisung-image: '../staticImages/img-sprite.png';
$sprite-sofortuberweisung: (0px, 899px, 0px, -899px, 64px, 37px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-sofortuberweisung', );
$sprite-visa-name: 'sprite-visa';
$sprite-visa-x: 0px;
$sprite-visa-y: 789px;
$sprite-visa-offset-x: 0px;
$sprite-visa-offset-y: -789px;
$sprite-visa-width: 51px;
$sprite-visa-height: 36px;
$sprite-visa-total-width: 205px;
$sprite-visa-total-height: 1200px;
$sprite-visa-image: '../staticImages/img-sprite.png';
$sprite-visa: (0px, 789px, 0px, -789px, 51px, 36px, 205px, 1200px, '../staticImages/img-sprite.png', 'sprite-visa', );
$sprite-amex-2x-name: 'sprite-amex-2x';
$sprite-amex-2x-x: 0px;
$sprite-amex-2x-y: 858px;
$sprite-amex-2x-offset-x: 0px;
$sprite-amex-2x-offset-y: -858px;
$sprite-amex-2x-width: 102px;
$sprite-amex-2x-height: 72px;
$sprite-amex-2x-total-width: 410px;
$sprite-amex-2x-total-height: 2400px;
$sprite-amex-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-amex-2x: (0px, 858px, 0px, -858px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-amex-2x', );
$sprite-apple-pay-2x-name: 'sprite-apple-pay-2x';
$sprite-apple-pay-2x-x: 0px;
$sprite-apple-pay-2x-y: 930px;
$sprite-apple-pay-2x-offset-x: 0px;
$sprite-apple-pay-2x-offset-y: -930px;
$sprite-apple-pay-2x-width: 102px;
$sprite-apple-pay-2x-height: 72px;
$sprite-apple-pay-2x-total-width: 410px;
$sprite-apple-pay-2x-total-height: 2400px;
$sprite-apple-pay-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-apple-pay-2x: (0px, 930px, 0px, -930px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-apple-pay-2x', );
$sprite-creditcard-2x-name: 'sprite-creditcard-2x';
$sprite-creditcard-2x-x: 0px;
$sprite-creditcard-2x-y: 368px;
$sprite-creditcard-2x-offset-x: 0px;
$sprite-creditcard-2x-offset-y: -368px;
$sprite-creditcard-2x-width: 102px;
$sprite-creditcard-2x-height: 70px;
$sprite-creditcard-2x-total-width: 410px;
$sprite-creditcard-2x-total-height: 2400px;
$sprite-creditcard-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-creditcard-2x: (0px, 368px, 0px, -368px, 102px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-creditcard-2x', );
$sprite-creditcards-wide-2x-name: 'sprite-creditcards-wide-2x';
$sprite-creditcards-wide-2x-x: 0px;
$sprite-creditcards-wide-2x-y: 438px;
$sprite-creditcards-wide-2x-offset-x: 0px;
$sprite-creditcards-wide-2x-offset-y: -438px;
$sprite-creditcards-wide-2x-width: 364px;
$sprite-creditcards-wide-2x-height: 70px;
$sprite-creditcards-wide-2x-total-width: 410px;
$sprite-creditcards-wide-2x-total-height: 2400px;
$sprite-creditcards-wide-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-creditcards-wide-2x: (0px, 438px, 0px, -438px, 364px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-creditcards-wide-2x', );
$sprite-dhl-2x-name: 'sprite-dhl-2x';
$sprite-dhl-2x-x: 0px;
$sprite-dhl-2x-y: 104px;
$sprite-dhl-2x-offset-x: 0px;
$sprite-dhl-2x-offset-y: -104px;
$sprite-dhl-2x-width: 170px;
$sprite-dhl-2x-height: 38px;
$sprite-dhl-2x-total-width: 410px;
$sprite-dhl-2x-total-height: 2400px;
$sprite-dhl-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-dhl-2x: (0px, 104px, 0px, -104px, 170px, 38px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-dhl-2x', );
$sprite-dhl-mini-2x-name: 'sprite-dhl_mini-2x';
$sprite-dhl-mini-2x-x: 0px;
$sprite-dhl-mini-2x-y: 0px;
$sprite-dhl-mini-2x-offset-x: 0px;
$sprite-dhl-mini-2x-offset-y: 0px;
$sprite-dhl-mini-2x-width: 104px;
$sprite-dhl-mini-2x-height: 26px;
$sprite-dhl-mini-2x-total-width: 410px;
$sprite-dhl-mini-2x-total-height: 2400px;
$sprite-dhl-mini-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-dhl-mini-2x: (0px, 0px, 0px, 0px, 104px, 26px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-dhl_mini-2x', );
$sprite-dhlgogreen-2x-name: 'sprite-dhlgogreen-2x';
$sprite-dhlgogreen-2x-x: 0px;
$sprite-dhlgogreen-2x-y: 2040px;
$sprite-dhlgogreen-2x-offset-x: 0px;
$sprite-dhlgogreen-2x-offset-y: -2040px;
$sprite-dhlgogreen-2x-width: 256px;
$sprite-dhlgogreen-2x-height: 180px;
$sprite-dhlgogreen-2x-total-width: 410px;
$sprite-dhlgogreen-2x-total-height: 2400px;
$sprite-dhlgogreen-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-dhlgogreen-2x: (0px, 2040px, 0px, -2040px, 256px, 180px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-dhlgogreen-2x', );
$sprite-dhlgowhite-2x-name: 'sprite-dhlgowhite-2x';
$sprite-dhlgowhite-2x-x: 0px;
$sprite-dhlgowhite-2x-y: 2220px;
$sprite-dhlgowhite-2x-offset-x: 0px;
$sprite-dhlgowhite-2x-offset-y: -2220px;
$sprite-dhlgowhite-2x-width: 256px;
$sprite-dhlgowhite-2x-height: 180px;
$sprite-dhlgowhite-2x-total-width: 410px;
$sprite-dhlgowhite-2x-total-height: 2400px;
$sprite-dhlgowhite-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-dhlgowhite-2x: (0px, 2220px, 0px, -2220px, 256px, 180px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-dhlgowhite-2x', );
$sprite-ec-2x-name: 'sprite-ec-2x';
$sprite-ec-2x-x: 0px;
$sprite-ec-2x-y: 1650px;
$sprite-ec-2x-offset-x: 0px;
$sprite-ec-2x-offset-y: -1650px;
$sprite-ec-2x-width: 128px;
$sprite-ec-2x-height: 74px;
$sprite-ec-2x-total-width: 410px;
$sprite-ec-2x-total-height: 2400px;
$sprite-ec-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-ec-2x: (0px, 1650px, 0px, -1650px, 128px, 74px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-ec-2x', );
$sprite-facebook-2x-name: 'sprite-facebook-2x';
$sprite-facebook-2x-x: 0px;
$sprite-facebook-2x-y: 240px;
$sprite-facebook-2x-offset-x: 0px;
$sprite-facebook-2x-offset-y: -240px;
$sprite-facebook-2x-width: 64px;
$sprite-facebook-2x-height: 64px;
$sprite-facebook-2x-total-width: 410px;
$sprite-facebook-2x-total-height: 2400px;
$sprite-facebook-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-facebook-2x: (0px, 240px, 0px, -240px, 64px, 64px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-facebook-2x', );
$sprite-faktura-2x-name: 'sprite-faktura-2x';
$sprite-faktura-2x-x: 0px;
$sprite-faktura-2x-y: 1724px;
$sprite-faktura-2x-offset-x: 0px;
$sprite-faktura-2x-offset-y: -1724px;
$sprite-faktura-2x-width: 128px;
$sprite-faktura-2x-height: 74px;
$sprite-faktura-2x-total-width: 410px;
$sprite-faktura-2x-total-height: 2400px;
$sprite-faktura-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-faktura-2x: (0px, 1724px, 0px, -1724px, 128px, 74px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-faktura-2x', );
$sprite-forecast-dhl-2x-name: 'sprite-forecast-dhl-2x';
$sprite-forecast-dhl-2x-x: 0px;
$sprite-forecast-dhl-2x-y: 508px;
$sprite-forecast-dhl-2x-offset-x: 0px;
$sprite-forecast-dhl-2x-offset-y: -508px;
$sprite-forecast-dhl-2x-width: 102px;
$sprite-forecast-dhl-2x-height: 70px;
$sprite-forecast-dhl-2x-total-width: 410px;
$sprite-forecast-dhl-2x-total-height: 2400px;
$sprite-forecast-dhl-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-2x: (0px, 508px, 0px, -508px, 102px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl-2x', );
$sprite-forecast-dhl-eu-2x-name: 'sprite-forecast-dhl_eu-2x';
$sprite-forecast-dhl-eu-2x-x: 0px;
$sprite-forecast-dhl-eu-2x-y: 578px;
$sprite-forecast-dhl-eu-2x-offset-x: 0px;
$sprite-forecast-dhl-eu-2x-offset-y: -578px;
$sprite-forecast-dhl-eu-2x-width: 102px;
$sprite-forecast-dhl-eu-2x-height: 70px;
$sprite-forecast-dhl-eu-2x-total-width: 410px;
$sprite-forecast-dhl-eu-2x-total-height: 2400px;
$sprite-forecast-dhl-eu-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-eu-2x: (0px, 578px, 0px, -578px, 102px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl_eu-2x', );
$sprite-forecast-post-ch-2x-name: 'sprite-forecast-post_ch-2x';
$sprite-forecast-post-ch-2x-x: 0px;
$sprite-forecast-post-ch-2x-y: 648px;
$sprite-forecast-post-ch-2x-offset-x: 0px;
$sprite-forecast-post-ch-2x-offset-y: -648px;
$sprite-forecast-post-ch-2x-width: 102px;
$sprite-forecast-post-ch-2x-height: 70px;
$sprite-forecast-post-ch-2x-total-width: 410px;
$sprite-forecast-post-ch-2x-total-height: 2400px;
$sprite-forecast-post-ch-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-post-ch-2x: (0px, 648px, 0px, -648px, 102px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-post_ch-2x', );
$sprite-giftvoucher-2x-name: 'sprite-giftvoucher-2x';
$sprite-giftvoucher-2x-x: 0px;
$sprite-giftvoucher-2x-y: 1002px;
$sprite-giftvoucher-2x-offset-x: 0px;
$sprite-giftvoucher-2x-offset-y: -1002px;
$sprite-giftvoucher-2x-width: 102px;
$sprite-giftvoucher-2x-height: 72px;
$sprite-giftvoucher-2x-total-width: 410px;
$sprite-giftvoucher-2x-total-height: 2400px;
$sprite-giftvoucher-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-giftvoucher-2x: (0px, 1002px, 0px, -1002px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-giftvoucher-2x', );
$sprite-gogreen-mini-2x-name: 'sprite-gogreen_mini-2x';
$sprite-gogreen-mini-2x-x: 0px;
$sprite-gogreen-mini-2x-y: 26px;
$sprite-gogreen-mini-2x-offset-x: 0px;
$sprite-gogreen-mini-2x-offset-y: -26px;
$sprite-gogreen-mini-2x-width: 104px;
$sprite-gogreen-mini-2x-height: 26px;
$sprite-gogreen-mini-2x-total-width: 410px;
$sprite-gogreen-mini-2x-total-height: 2400px;
$sprite-gogreen-mini-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-gogreen-mini-2x: (0px, 26px, 0px, -26px, 104px, 26px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-gogreen_mini-2x', );
$sprite-hermes-2x-name: 'sprite-hermes-2x';
$sprite-hermes-2x-x: 0px;
$sprite-hermes-2x-y: 142px;
$sprite-hermes-2x-offset-x: 0px;
$sprite-hermes-2x-offset-y: -142px;
$sprite-hermes-2x-width: 238px;
$sprite-hermes-2x-height: 42px;
$sprite-hermes-2x-total-width: 410px;
$sprite-hermes-2x-total-height: 2400px;
$sprite-hermes-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermes-2x: (0px, 142px, 0px, -142px, 238px, 42px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-hermes-2x', );
$sprite-instagram-2x-name: 'sprite-instagram-2x';
$sprite-instagram-2x-x: 0px;
$sprite-instagram-2x-y: 304px;
$sprite-instagram-2x-offset-x: 0px;
$sprite-instagram-2x-offset-y: -304px;
$sprite-instagram-2x-width: 64px;
$sprite-instagram-2x-height: 64px;
$sprite-instagram-2x-total-width: 410px;
$sprite-instagram-2x-total-height: 2400px;
$sprite-instagram-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-instagram-2x: (0px, 304px, 0px, -304px, 64px, 64px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-instagram-2x', );
$sprite-mastercard-2x-name: 'sprite-mastercard-2x';
$sprite-mastercard-2x-x: 0px;
$sprite-mastercard-2x-y: 1074px;
$sprite-mastercard-2x-offset-x: 0px;
$sprite-mastercard-2x-offset-y: -1074px;
$sprite-mastercard-2x-width: 102px;
$sprite-mastercard-2x-height: 72px;
$sprite-mastercard-2x-total-width: 410px;
$sprite-mastercard-2x-total-height: 2400px;
$sprite-mastercard-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-mastercard-2x: (0px, 1074px, 0px, -1074px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-mastercard-2x', );
$sprite-packstation-2x-name: 'sprite-packstation-2x';
$sprite-packstation-2x-x: 0px;
$sprite-packstation-2x-y: 184px;
$sprite-packstation-2x-offset-x: 0px;
$sprite-packstation-2x-offset-y: -184px;
$sprite-packstation-2x-width: 256px;
$sprite-packstation-2x-height: 56px;
$sprite-packstation-2x-total-width: 410px;
$sprite-packstation-2x-total-height: 2400px;
$sprite-packstation-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-packstation-2x: (0px, 184px, 0px, -184px, 256px, 56px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-packstation-2x', );
$sprite-packstation-mini-2x-name: 'sprite-packstation_mini-2x';
$sprite-packstation-mini-2x-x: 0px;
$sprite-packstation-mini-2x-y: 52px;
$sprite-packstation-mini-2x-offset-x: 0px;
$sprite-packstation-mini-2x-offset-y: -52px;
$sprite-packstation-mini-2x-width: 124px;
$sprite-packstation-mini-2x-height: 26px;
$sprite-packstation-mini-2x-total-width: 410px;
$sprite-packstation-mini-2x-total-height: 2400px;
$sprite-packstation-mini-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-packstation-mini-2x: (0px, 52px, 0px, -52px, 124px, 26px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-packstation_mini-2x', );
$sprite-packstation-small-2x-name: 'sprite-packstation_small-2x';
$sprite-packstation-small-2x-x: 0px;
$sprite-packstation-small-2x-y: 718px;
$sprite-packstation-small-2x-offset-x: 0px;
$sprite-packstation-small-2x-offset-y: -718px;
$sprite-packstation-small-2x-width: 102px;
$sprite-packstation-small-2x-height: 70px;
$sprite-packstation-small-2x-total-width: 410px;
$sprite-packstation-small-2x-total-height: 2400px;
$sprite-packstation-small-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-packstation-small-2x: (0px, 718px, 0px, -718px, 102px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-packstation_small-2x', );
$sprite-paketshop-2x-name: 'sprite-paketshop-2x';
$sprite-paketshop-2x-x: 0px;
$sprite-paketshop-2x-y: 788px;
$sprite-paketshop-2x-offset-x: 0px;
$sprite-paketshop-2x-offset-y: -788px;
$sprite-paketshop-2x-width: 102px;
$sprite-paketshop-2x-height: 70px;
$sprite-paketshop-2x-total-width: 410px;
$sprite-paketshop-2x-total-height: 2400px;
$sprite-paketshop-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paketshop-2x: (0px, 788px, 0px, -788px, 102px, 70px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-paketshop-2x', );
$sprite-paypal-2x-name: 'sprite-paypal-2x';
$sprite-paypal-2x-x: 0px;
$sprite-paypal-2x-y: 1146px;
$sprite-paypal-2x-offset-x: 0px;
$sprite-paypal-2x-offset-y: -1146px;
$sprite-paypal-2x-width: 102px;
$sprite-paypal-2x-height: 72px;
$sprite-paypal-2x-total-width: 410px;
$sprite-paypal-2x-total-height: 2400px;
$sprite-paypal-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypal-2x: (0px, 1146px, 0px, -1146px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-paypal-2x', );
$sprite-paypalcheckout-2x-name: 'sprite-paypalcheckout-2x';
$sprite-paypalcheckout-2x-x: 0px;
$sprite-paypalcheckout-2x-y: 1952px;
$sprite-paypalcheckout-2x-offset-x: 0px;
$sprite-paypalcheckout-2x-offset-y: -1952px;
$sprite-paypalcheckout-2x-width: 410px;
$sprite-paypalcheckout-2x-height: 88px;
$sprite-paypalcheckout-2x-total-width: 410px;
$sprite-paypalcheckout-2x-total-height: 2400px;
$sprite-paypalcheckout-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypalcheckout-2x: (0px, 1952px, 0px, -1952px, 410px, 88px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-paypalcheckout-2x', );
$sprite-paypalexpress-2x-name: 'sprite-paypalexpress-2x';
$sprite-paypalexpress-2x-x: 0px;
$sprite-paypalexpress-2x-y: 1218px;
$sprite-paypalexpress-2x-offset-x: 0px;
$sprite-paypalexpress-2x-offset-y: -1218px;
$sprite-paypalexpress-2x-width: 102px;
$sprite-paypalexpress-2x-height: 72px;
$sprite-paypalexpress-2x-total-width: 410px;
$sprite-paypalexpress-2x-total-height: 2400px;
$sprite-paypalexpress-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypalexpress-2x: (0px, 1218px, 0px, -1218px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-paypalexpress-2x', );
$sprite-post-ch-2x-name: 'sprite-post_ch-2x';
$sprite-post-ch-2x-x: 0px;
$sprite-post-ch-2x-y: 78px;
$sprite-post-ch-2x-offset-x: 0px;
$sprite-post-ch-2x-offset-y: -78px;
$sprite-post-ch-2x-width: 90px;
$sprite-post-ch-2x-height: 26px;
$sprite-post-ch-2x-total-width: 410px;
$sprite-post-ch-2x-total-height: 2400px;
$sprite-post-ch-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-post-ch-2x: (0px, 78px, 0px, -78px, 90px, 26px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-post_ch-2x', );
$sprite-postfinance-2x-name: 'sprite-postfinance-2x';
$sprite-postfinance-2x-x: 0px;
$sprite-postfinance-2x-y: 1290px;
$sprite-postfinance-2x-offset-x: 0px;
$sprite-postfinance-2x-offset-y: -1290px;
$sprite-postfinance-2x-width: 102px;
$sprite-postfinance-2x-height: 72px;
$sprite-postfinance-2x-total-width: 410px;
$sprite-postfinance-2x-total-height: 2400px;
$sprite-postfinance-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-postfinance-2x: (0px, 1290px, 0px, -1290px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-postfinance-2x', );
$sprite-proclima-2x-name: 'sprite-proclima-2x';
$sprite-proclima-2x-x: 0px;
$sprite-proclima-2x-y: 1872px;
$sprite-proclima-2x-offset-x: 0px;
$sprite-proclima-2x-offset-y: -1872px;
$sprite-proclima-2x-width: 90px;
$sprite-proclima-2x-height: 80px;
$sprite-proclima-2x-total-width: 410px;
$sprite-proclima-2x-total-height: 2400px;
$sprite-proclima-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-proclima-2x: (0px, 1872px, 0px, -1872px, 90px, 80px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-proclima-2x', );
$sprite-rechnung-2x-name: 'sprite-rechnung-2x';
$sprite-rechnung-2x-x: 0px;
$sprite-rechnung-2x-y: 1362px;
$sprite-rechnung-2x-offset-x: 0px;
$sprite-rechnung-2x-offset-y: -1362px;
$sprite-rechnung-2x-width: 102px;
$sprite-rechnung-2x-height: 72px;
$sprite-rechnung-2x-total-width: 410px;
$sprite-rechnung-2x-total-height: 2400px;
$sprite-rechnung-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-rechnung-2x: (0px, 1362px, 0px, -1362px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-rechnung-2x', );
$sprite-rechnungch-2x-name: 'sprite-rechnungch-2x';
$sprite-rechnungch-2x-x: 0px;
$sprite-rechnungch-2x-y: 1434px;
$sprite-rechnungch-2x-offset-x: 0px;
$sprite-rechnungch-2x-offset-y: -1434px;
$sprite-rechnungch-2x-width: 102px;
$sprite-rechnungch-2x-height: 72px;
$sprite-rechnungch-2x-total-width: 410px;
$sprite-rechnungch-2x-total-height: 2400px;
$sprite-rechnungch-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-rechnungch-2x: (0px, 1434px, 0px, -1434px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-rechnungch-2x', );
$sprite-sepa-2x-name: 'sprite-sepa-2x';
$sprite-sepa-2x-x: 0px;
$sprite-sepa-2x-y: 1506px;
$sprite-sepa-2x-offset-x: 0px;
$sprite-sepa-2x-offset-y: -1506px;
$sprite-sepa-2x-width: 102px;
$sprite-sepa-2x-height: 72px;
$sprite-sepa-2x-total-width: 410px;
$sprite-sepa-2x-total-height: 2400px;
$sprite-sepa-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-sepa-2x: (0px, 1506px, 0px, -1506px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-sepa-2x', );
$sprite-sofortuberweisung-2x-name: 'sprite-sofortuberweisung-2x';
$sprite-sofortuberweisung-2x-x: 0px;
$sprite-sofortuberweisung-2x-y: 1798px;
$sprite-sofortuberweisung-2x-offset-x: 0px;
$sprite-sofortuberweisung-2x-offset-y: -1798px;
$sprite-sofortuberweisung-2x-width: 128px;
$sprite-sofortuberweisung-2x-height: 74px;
$sprite-sofortuberweisung-2x-total-width: 410px;
$sprite-sofortuberweisung-2x-total-height: 2400px;
$sprite-sofortuberweisung-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-sofortuberweisung-2x: (0px, 1798px, 0px, -1798px, 128px, 74px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-sofortuberweisung-2x', );
$sprite-visa-2x-name: 'sprite-visa-2x';
$sprite-visa-2x-x: 0px;
$sprite-visa-2x-y: 1578px;
$sprite-visa-2x-offset-x: 0px;
$sprite-visa-2x-offset-y: -1578px;
$sprite-visa-2x-width: 102px;
$sprite-visa-2x-height: 72px;
$sprite-visa-2x-total-width: 410px;
$sprite-visa-2x-total-height: 2400px;
$sprite-visa-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-visa-2x: (0px, 1578px, 0px, -1578px, 102px, 72px, 410px, 2400px, '../staticImages/img-sprite-2x.png', 'sprite-visa-2x', );
$spritesheet-width: 205px;
$spritesheet-height: 1200px;
$spritesheet-image: '../staticImages/img-sprite.png';
$spritesheet-sprites: ($sprite-amex, $sprite-apple-pay, $sprite-creditcard, $sprite-creditcards-wide, $sprite-dhl, $sprite-dhl-mini, $sprite-dhlgogreen, $sprite-dhlgowhite, $sprite-ec, $sprite-facebook, $sprite-faktura, $sprite-forecast-dhl, $sprite-forecast-dhl-eu, $sprite-forecast-post-ch, $sprite-giftvoucher, $sprite-gogreen-mini, $sprite-hermes, $sprite-instagram, $sprite-mastercard, $sprite-packstation, $sprite-packstation-mini, $sprite-packstation-small, $sprite-paketshop, $sprite-paypal, $sprite-paypalcheckout, $sprite-paypalexpress, $sprite-post-ch, $sprite-postfinance, $sprite-proclima, $sprite-rechnung, $sprite-rechnungch, $sprite-sepa, $sprite-sofortuberweisung, $sprite-visa, );
$spritesheet: (205px, 1200px, '../staticImages/img-sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 410px;
$retina-spritesheet-height: 2400px;
$retina-spritesheet-image: '../staticImages/img-sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-amex-2x, $sprite-apple-pay-2x, $sprite-creditcard-2x, $sprite-creditcards-wide-2x, $sprite-dhl-2x, $sprite-dhl-mini-2x, $sprite-dhlgogreen-2x, $sprite-dhlgowhite-2x, $sprite-ec-2x, $sprite-facebook-2x, $sprite-faktura-2x, $sprite-forecast-dhl-2x, $sprite-forecast-dhl-eu-2x, $sprite-forecast-post-ch-2x, $sprite-giftvoucher-2x, $sprite-gogreen-mini-2x, $sprite-hermes-2x, $sprite-instagram-2x, $sprite-mastercard-2x, $sprite-packstation-2x, $sprite-packstation-mini-2x, $sprite-packstation-small-2x, $sprite-paketshop-2x, $sprite-paypal-2x, $sprite-paypalcheckout-2x, $sprite-paypalexpress-2x, $sprite-post-ch-2x, $sprite-postfinance-2x, $sprite-proclima-2x, $sprite-rechnung-2x, $sprite-rechnungch-2x, $sprite-sepa-2x, $sprite-sofortuberweisung-2x, $sprite-visa-2x, );
$retina-spritesheet: (410px, 2400px, '../staticImages/img-sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-amex-group-name: 'sprite-amex';
$sprite-amex-group: ('sprite-amex', $sprite-amex, $sprite-amex-2x, );
$sprite-apple-pay-group-name: 'sprite-apple-pay';
$sprite-apple-pay-group: ('sprite-apple-pay', $sprite-apple-pay, $sprite-apple-pay-2x, );
$sprite-creditcard-group-name: 'sprite-creditcard';
$sprite-creditcard-group: ('sprite-creditcard', $sprite-creditcard, $sprite-creditcard-2x, );
$sprite-creditcards-wide-group-name: 'sprite-creditcards-wide';
$sprite-creditcards-wide-group: ('sprite-creditcards-wide', $sprite-creditcards-wide, $sprite-creditcards-wide-2x, );
$sprite-dhl-group-name: 'sprite-dhl';
$sprite-dhl-group: ('sprite-dhl', $sprite-dhl, $sprite-dhl-2x, );
$sprite-dhl-mini-group-name: 'sprite-dhl_mini';
$sprite-dhl-mini-group: ('sprite-dhl_mini', $sprite-dhl-mini, $sprite-dhl-mini-2x, );
$sprite-dhlgogreen-group-name: 'sprite-dhlgogreen';
$sprite-dhlgogreen-group: ('sprite-dhlgogreen', $sprite-dhlgogreen, $sprite-dhlgogreen-2x, );
$sprite-dhlgowhite-group-name: 'sprite-dhlgowhite';
$sprite-dhlgowhite-group: ('sprite-dhlgowhite', $sprite-dhlgowhite, $sprite-dhlgowhite-2x, );
$sprite-ec-group-name: 'sprite-ec';
$sprite-ec-group: ('sprite-ec', $sprite-ec, $sprite-ec-2x, );
$sprite-facebook-group-name: 'sprite-facebook';
$sprite-facebook-group: ('sprite-facebook', $sprite-facebook, $sprite-facebook-2x, );
$sprite-faktura-group-name: 'sprite-faktura';
$sprite-faktura-group: ('sprite-faktura', $sprite-faktura, $sprite-faktura-2x, );
$sprite-forecast-dhl-group-name: 'sprite-forecast-dhl';
$sprite-forecast-dhl-group: ('sprite-forecast-dhl', $sprite-forecast-dhl, $sprite-forecast-dhl-2x, );
$sprite-forecast-dhl-eu-group-name: 'sprite-forecast-dhl_eu';
$sprite-forecast-dhl-eu-group: ('sprite-forecast-dhl_eu', $sprite-forecast-dhl-eu, $sprite-forecast-dhl-eu-2x, );
$sprite-forecast-post-ch-group-name: 'sprite-forecast-post_ch';
$sprite-forecast-post-ch-group: ('sprite-forecast-post_ch', $sprite-forecast-post-ch, $sprite-forecast-post-ch-2x, );
$sprite-giftvoucher-group-name: 'sprite-giftvoucher';
$sprite-giftvoucher-group: ('sprite-giftvoucher', $sprite-giftvoucher, $sprite-giftvoucher-2x, );
$sprite-gogreen-mini-group-name: 'sprite-gogreen_mini';
$sprite-gogreen-mini-group: ('sprite-gogreen_mini', $sprite-gogreen-mini, $sprite-gogreen-mini-2x, );
$sprite-hermes-group-name: 'sprite-hermes';
$sprite-hermes-group: ('sprite-hermes', $sprite-hermes, $sprite-hermes-2x, );
$sprite-instagram-group-name: 'sprite-instagram';
$sprite-instagram-group: ('sprite-instagram', $sprite-instagram, $sprite-instagram-2x, );
$sprite-mastercard-group-name: 'sprite-mastercard';
$sprite-mastercard-group: ('sprite-mastercard', $sprite-mastercard, $sprite-mastercard-2x, );
$sprite-packstation-group-name: 'sprite-packstation';
$sprite-packstation-group: ('sprite-packstation', $sprite-packstation, $sprite-packstation-2x, );
$sprite-packstation-mini-group-name: 'sprite-packstation_mini';
$sprite-packstation-mini-group: ('sprite-packstation_mini', $sprite-packstation-mini, $sprite-packstation-mini-2x, );
$sprite-packstation-small-group-name: 'sprite-packstation_small';
$sprite-packstation-small-group: ('sprite-packstation_small', $sprite-packstation-small, $sprite-packstation-small-2x, );
$sprite-paketshop-group-name: 'sprite-paketshop';
$sprite-paketshop-group: ('sprite-paketshop', $sprite-paketshop, $sprite-paketshop-2x, );
$sprite-paypal-group-name: 'sprite-paypal';
$sprite-paypal-group: ('sprite-paypal', $sprite-paypal, $sprite-paypal-2x, );
$sprite-paypalcheckout-group-name: 'sprite-paypalcheckout';
$sprite-paypalcheckout-group: ('sprite-paypalcheckout', $sprite-paypalcheckout, $sprite-paypalcheckout-2x, );
$sprite-paypalexpress-group-name: 'sprite-paypalexpress';
$sprite-paypalexpress-group: ('sprite-paypalexpress', $sprite-paypalexpress, $sprite-paypalexpress-2x, );
$sprite-post-ch-group-name: 'sprite-post_ch';
$sprite-post-ch-group: ('sprite-post_ch', $sprite-post-ch, $sprite-post-ch-2x, );
$sprite-postfinance-group-name: 'sprite-postfinance';
$sprite-postfinance-group: ('sprite-postfinance', $sprite-postfinance, $sprite-postfinance-2x, );
$sprite-proclima-group-name: 'sprite-proclima';
$sprite-proclima-group: ('sprite-proclima', $sprite-proclima, $sprite-proclima-2x, );
$sprite-rechnung-group-name: 'sprite-rechnung';
$sprite-rechnung-group: ('sprite-rechnung', $sprite-rechnung, $sprite-rechnung-2x, );
$sprite-rechnungch-group-name: 'sprite-rechnungch';
$sprite-rechnungch-group: ('sprite-rechnungch', $sprite-rechnungch, $sprite-rechnungch-2x, );
$sprite-sepa-group-name: 'sprite-sepa';
$sprite-sepa-group: ('sprite-sepa', $sprite-sepa, $sprite-sepa-2x, );
$sprite-sofortuberweisung-group-name: 'sprite-sofortuberweisung';
$sprite-sofortuberweisung-group: ('sprite-sofortuberweisung', $sprite-sofortuberweisung, $sprite-sofortuberweisung-2x, );
$sprite-visa-group-name: 'sprite-visa';
$sprite-visa-group: ('sprite-visa', $sprite-visa, $sprite-visa-2x, );
$retina-groups: ($sprite-amex-group, $sprite-apple-pay-group, $sprite-creditcard-group, $sprite-creditcards-wide-group, $sprite-dhl-group, $sprite-dhl-mini-group, $sprite-dhlgogreen-group, $sprite-dhlgowhite-group, $sprite-ec-group, $sprite-facebook-group, $sprite-faktura-group, $sprite-forecast-dhl-group, $sprite-forecast-dhl-eu-group, $sprite-forecast-post-ch-group, $sprite-giftvoucher-group, $sprite-gogreen-mini-group, $sprite-hermes-group, $sprite-instagram-group, $sprite-mastercard-group, $sprite-packstation-group, $sprite-packstation-mini-group, $sprite-packstation-small-group, $sprite-paketshop-group, $sprite-paypal-group, $sprite-paypalcheckout-group, $sprite-paypalexpress-group, $sprite-post-ch-group, $sprite-postfinance-group, $sprite-proclima-group, $sprite-rechnung-group, $sprite-rechnungch-group, $sprite-sepa-group, $sprite-sofortuberweisung-group, $sprite-visa-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
