.general-message {
  position: relative;
  padding: 8px 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  min-height: 26px;
  border-radius: 4px;

  @media (min-width: $screen-tablet-portrait) {
    margin-bottom: 32px;
    padding: 8px 24px;
  }

  &:empty {
    display: none;
  }

  &--with-close {
    padding: 8px 32px 8px 16px;
  }

  &__top-wrapper {
    margin: 0 auto;
    max-width: $maxWidthOuter;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 24px;
      margin: 6px auto 0;
    }

    .general-message {
      margin: 0 auto;
    }
  }

  &--type {
    &_info {
      background: $orangeColor;
    }

    &_error {
      background: $errorColor;
    }

    &_warning {
      background: $warningColor;
    }

    &_success {
      background: $successColor;
    }
  }

  &__messages-list {
    @include clearList();
  }

  &__message {
    color: $whiteColor;
    font-size: 13px;
    line-height: 140%;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 17px;
    }

    a {
      color: $whiteColor;
      text-decoration: underline;
    }
  }

  &__close {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    cursor: pointer;

    @include iconFont('close') {
      font-size: 8px;
      font-weight: $fontWeightBold;
      color: $whiteColor;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 24px;
      height: 24px;

      &::before {
        font-size: 10px;
      }
    }
  }
}

// PLACEHOLDER
general-message {  // stylelint-disable-line
  &[type="error"] {
    position: relative;
    padding: 8px 24px;
    text-align: center;
    margin-bottom: 24px;
    width: 100%;
    min-height: 26px;
    background: $errorColor;
    color: $whiteColor;
    font-size: 14px;
    line-height: 140%;
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 18px;
    }

    a {
      color: $whiteColor;
      text-decoration: underline;
    }
  }

  &:empty {
    display: none;
  }
}
