.social-message {
  background: $socialMessageBackground;
  border-radius: 14px;
  padding: 10px 15px;
  font-size: 16px;
  display: block;
  line-height: 1.3;
  position: relative;

  &__name {
    color: $socialMessageNameColor;
    font-weight: bold;
    padding: 0 7px 0 0;
  }

  &__text {
    color: $socialMessageTextColor;
  }

  &__likes {
    position: absolute;
    color: $socialMessageLikeColor;
    background: $socialMessageLikeBackground;
    box-shadow: $socialMessageLikeShadow;
    border-radius: 16px;
    padding: 2px 6px 2px 2px;
    font-size: 12px;
    right: 8px;
    bottom: -10px;

    @include iconFont(heartFilled) {
      color: $socialMessageHeartColor;
      font-size: 9px;
      padding: .2em .2em .2em .26em;
      background: $socialMessageHeartBackground;
      border-radius: 100%;
      width: 11px;
      height: 11px;
      line-height: 1.3;
      margin: 1px 5px 1px 0;
    }
  }
}
