.add-to-cart-layer {
  $root: &;
  $borderLightColor: #92756533;
  display: block;
  position: relative;

  &--small {
    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__overlay {
    background: rgb(0 0 0 / .5);
    position: fixed;
    z-index: $indexHeader - 1;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__box {
    position: absolute;
    z-index: $indexHeader + 1;
    width: 100%;
    background: $whiteColor;
    padding: 16px 16px 24px;
    top: 4px;
    box-shadow: 0 8px 40px 0 rgb(0 0 0 / .25);
    transition: top .3s, right .3s;

    @media (min-width: $screen-laptop) {
      top: 0;
      right: 20px;
      width: 400px;
      padding: 16px;
      transform: translateY(-40px);
    }

    @media (min-width: $screen-desktop-sm) {
      right: 10px;
      transform: translateY(-50px);
    }

    @media (min-width: $maxWidthHeader) {
      right: calc(50% - #{$maxWidthHeader * .5} + 10px);
    }

    .page-detail-view .add-to-cart-layer--small &,
    .header--sticky & {
      position: fixed;
      transform: translateY(0);
      top: 0;

      @media (max-width: $screen-tablet-portrait - 1) {
        transition-duration: 0s;
      }

      @media (min-width: $screen-tablet-portrait) {
        right: 5px;
        top: 76px;

        #{$root}__triangle {
          right: 22px;
        }
      }

      @media (min-width: $maxWidthOuter + 100) {
        right: calc(50% - #{$maxWidthOuter * .5 + 44});

        #{$root}__triangle {
          right: 15px;
        }
      }
    }
  }

  &__triangle {
    $size: 9px;
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: 10px solid $whiteColor;
    position: absolute;
    top: -$size;
    right: 20px;

    @media (min-width: $screen-laptop) {
      right: 30px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: $borderLightColor 1px solid;
    padding-bottom: 16px;
  }

  &__headline {
    font-size: 22px;
    flex-grow: 1;
    font-family: $fontHeadlineFamily;
    font-weight: $fontWeightBold;
  }

  &__close {
    color: $blackColor;
    padding: 8px;
    margin: -8px;
    cursor: pointer;
    display: none;

    @include iconFont(close) {
      font-size: 16px;
    }

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &__message {
    padding: 20px 0 24px;
    font-size: $fontSizeSmall;

    @include iconFont(checked, before) {
      $size: 40px;
      font-size: 12px;
      border-radius: 50%;
      background: $successColor;
      color: $whiteColor;
      width: $size;
      height: $size;
      line-height: $size;
      margin-right: 16px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      animation: pulse 1s;
      animation-delay: .7s;
    }
  }

  &__cart-btn {
    @include switchButtonStyle('secondary>primary');
    width: 100%;

    #{$root}--small & {
      margin-bottom: 16px;
      height: $buttonPrimaryHeightDesktop;
      line-height: $buttonPrimaryHeightDesktop;
    }
  }

  &__continue-link {
    font-size: $fontSizeSmall;
    color: $orangeColor;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
    width: 100%;
    text-align: center;
  }

  &__list-container {
    @include customScrollbar(4px, $grayLighterColor);
    overflow: hidden auto;
    max-height: 225px;
    margin: 16px 0;
  }

  &__list {
    padding: 8px 16px 8px 0;
  }

  &__product:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: $borderLightColor 1px solid;
    margin-bottom: 12px;
  }

  &__footer {
    border-top: $borderLightColor 1px solid;
    padding-top: 16px;
    font-size: 16px;
    line-height: 130%;
  }

  &__cols {
    display: flex;
    margin-bottom: 4px;
  }

  &__col {
    width: 65%;

    &:last-child {
      text-align: right;
      width: 35%;
    }

    &--strong {
      color: $successColor;
    }
  }

  &__grand-total {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: $borderLightColor 1px solid;
  }

  &__upsell-promotion {
    margin-bottom: 16px;
    font-size: 13px;

    strong,
    span {
      background: $orangeColor;
      color: $whiteColor;
      font-weight: $fontWeightRegular;
      border-radius: 2px;
      padding: 1px 4px 2px;
    }

    &--not-free {
      strong,
      span {
        background: $redDarkColor;
      }
    }
  }

  &__tax {
    color: $brownMediumActiveColor;
    font-size: 10px;
  }

  .price-info {
    font-family: $fontPrimaryFamily;
    flex-flow: row wrap;
    justify-content: flex-end;

    &__current {
      display: flex;
      font-size: 16px;
      margin-left: 8px;
    }

    &__value-cent {
      font-size: 1em;
      line-height: inherit;
    }

    &__unit {
      color: $brownMediumActiveColor;
    }

    &__old-value::after {
      transform: rotate(-8deg);
      top: 48%;
    }
  }

  .add-to-cart-position {
    &__name {
      font-size: 16px;
      font-weight: $fontWeightRegular;
    }

    &__delete-btn {
      color: $brownMediumActiveColor;
      font-size: 13px;
    }
  }

  html[lang="de-CH"] & {
    &__col:last-child,
    &__upsell-promotion strong {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
    }
  }
}
