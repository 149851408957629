.burger-nav-tree {
  @include clearList();
  margin: 0;
  padding: 0 15px;
  position: relative;
  display: block;
  background: $backgroundWhiteColor;

  &__subcategories {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate3d(100%, 0, 0);
    background: $backgroundWhiteColor;

    @media (min-width: $screen-laptop) {
      transition: transform .3s;
    }

    &--active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__item {
    margin: 0;
    padding: 0;
    display: block;
    border-bottom: $grayLighterColor 1px solid;

    &:last-child {
      border-bottom: 0 none;
    }

    .burger-nav-tree__subcategories & {
      padding: 0 0 0 40px;
    }

    &--headline {
      color: $brandPrimaryColor;
      font-size: 24px;
      position: relative;
      padding: 17px 0;
      font-weight: bold;
      border: 0 none;
      display: inline-block;

      & + .burger-nav-tree__item {
        border-top: $grayLighterColor 1px solid;
      }

      .burger-nav-tree__subcategories & {
        padding: 17px 0;
      }

      a {
        color: $brandPrimaryColor;
        text-decoration: none;
      }
    }

    &--teaser {
      overflow: hidden;
      border: 0;

      .image-text-action-teaser {
        margin-bottom: 20px;
      }
    }

    &--image-teaser {
      .burger-nav-tree__subcategories & {
        padding: 0;
        border-bottom: 0 none;
      }

      & + .burger-nav-tree__item--category-special {
        border-top: $grayLighterColor 1px solid;
      }
    }

    &--category-special {
      padding: 5px 0;

      .burger-nav-tree__link {
        color: $brandPrimaryColor !important;
        font-weight: $fontWeightBold;
        line-height: 24px;
      }
    }

    &--subcategory {
      &_first {
        padding-top: 5px;
      }

      &_last {
        padding-bottom: 5px;
      }
    }

    &--icon {
      display: flex;
      align-items: center;

      & > * {
        flex-grow: 1;
      }

      &::before {
        display: inline-block;
        width: 22px;
        text-align: center;
      }

      &-wishlist {
        @include iconFont(heart);
        margin-top: 20px;
      }

      &-contact {
        @include iconFont(contact);
      }

      &-faq {
        @include iconFont(question);
      }

      &-chat {
        @include iconFont(chat);
      }

      &-service {
        @include iconFont(cloud);
      }
    }

    &--loader {
      @include componentPreloader();
    }
  }

  &__link {
    display: block;
    position: relative;
    padding: 13px 0;
    color: $redDarkColor;
    font-weight: $fontWeightMedium;
    font-size: 14px;
    text-decoration: none;
    line-height: 28px;
    cursor: pointer;

    &:visited {
      color: $redDarkColor;
    }

    &--highlighted {
      color: $redDarkColor;
    }

    &--arrow {
      @include iconFont(arrowRight) {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10px;
        color: $redDarkColor;
        font-weight: $fontWeightBold;
      }
    }

    &--loader {
      width: 100px;
      height: 14px;
      margin: 7px 0 7px 8px;
      display: block;
      background: $sandMediumColor;
      border-radius: 4px;

      &-alt {
        width: 120px;
      }
    }
  }

  &__back {
    color: $redDarkColor;
    font-size: 14px;
    padding: 16px 22px 16px 16px;
    display: inline-block;
    z-index: 1;
    cursor: pointer;
    position: relative;

    @include iconFont(arrowRight) {
      position: absolute;
      right: 18px;
      bottom: 0;
      transform: translateY(-50%) rotate(180deg);
      font-size: 10px;
      color: $redDarkColor;
      font-weight: $fontWeightBold;
    }
  }
}
