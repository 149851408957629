.accordion {
  $root: &;
  position: relative;

  &--open {
    > #{$root}__headline::after {
      transform: translateY(-50%) scale(1, -1);
    }
  }

  &--hide-arrow {
    > #{$root}__headline::after {
      display: none;
    }
  }

  &__headline {
    padding: 16px 0;
    font-size: 18px;
    font-weight: $fontWeightSemiBold;
    line-height: 1.2;
    position: relative;
    margin: 0;
    cursor: pointer;
    color: $redDarkColor;

    @include iconFont(arrowDown, after) {
      font-size: 10px;
      color: $redDarkColor;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scale(1, 1);
      transition: transform .3s;
    }
  }

  &__content {
    .accordion & {
      overflow: hidden;
      transition: max-height 500ms cubic-bezier(.4, 1, .5, 1);
      border-bottom: 1px solid $borderDefaultColor;
    }

    .accordion--open & {
      max-height: 20000px;
    }

    .accordion--closed & {
      max-height: 0;
    }
  }

  &__inner {
    margin: 0;
    padding: 8px 0 24px;
  }

  &__anchor {
    position: absolute;
    top: -60px;

    @media (min-width: $screen-tablet-portrait) {
      top: -132px;
    }
  }
}
