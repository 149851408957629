.additional-content {
  margin: 48px 0;

  &__wrapper {
    @include container();

    p {
      margin: 0 0 8px;
    }

    a {
      text-decoration: underline;
    }
  }

  .one-page-navigation {
    @include fullWidth();
    margin-bottom: 24px;

    &__container {
      @include container();
    }
  }
}
