.product-image {
  &--shadow-bottle {
    position: relative;

    picture::before {
      content: "";
      background: radial-gradient(closest-side, rgb(137 51 16 / .8) 0%, rgb(137 51 16 / .2) 70%, rgb(137 51 16 / .2) 70%, rgb(137 51 16 / 0) 100%); // stylelint-disable plugin/no-unsupported-browser-features
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      height: 10%;
      opacity: .5;
      width: 50%;
      transform: translate(35%, 35%);
    }
  }

  &--wide-shadow-bottle {
    position: relative;

    &::before {
      background:
        transparent radial-gradient(
          ellipse at center center,
          rgb(0 0 0 / .6) 40%,
          rgb(0 0 0 / .4) 30%,
          rgb(0 0 0 / .5) 20%,
          rgb(0 0 0 / .1) 60%,
          transparent 60%
        ) repeat scroll 0 0;
      bottom: -4px;
      content: "";
      height: 14px;
      left: -25%;
      opacity: .7;
      position: absolute;
      width: 150%;
      z-index: 0;
      right: 0;
      margin: auto;

      .product-order-position & {
        max-width: 95px;
      }
    }
  }

  &--detail-shadow-bottle {
    position: relative;

    &::before {
      content: "";
      background: radial-gradient(closest-side, rgb(137 51 16 / .8) 0%, rgb(137 51 16 / .2) 70%, rgb(137 51 16 / .2) 70%, rgb(137 51 16 / 0) 100%);
      position: absolute;
      bottom: .5%;
      left: 0;
      z-index: -1;
      height: 7%;
      opacity: .7;
      width: 230%;
      max-width: 320px;
      transform: translate(-44%, 32%);

      .product-detailed-page--premium & {
        background: radial-gradient(closest-side, rgb(0 0 0 / .6) 0%, rgb(0 0 0 / .2) 70%, rgb(0 0 0 / .2) 70%, rgb(0 0 0 / 0) 100%);
      }
    }
  }

  &--detail-shadow-set {
    position: relative;

    &::before {
      content: "";
      background: linear-gradient(-95deg, rgb(0 0 0 / .9) 0%, rgb(0 0 0 / .5) 10%, rgb(0 0 0 / 0) 80%, rgb(0 0 0 / 0) 100%);
      position: absolute;
      bottom: 4%;
      left: -5.6%;
      z-index: -1;
      height: 80%;
      opacity: .6;
      width: 12.6%;
      transform: rotate(15deg) skewY(15deg);
    }
  }
}
