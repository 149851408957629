.social-media {
  $root: &;
  display: flex;
  padding: 0;
  margin: -10px -5px;
  flex-direction: row;
  justify-content: flex-end;

  @media (min-width: $screen-tablet-portrait) {
    margin: -5px;
  }

  &__link {
    display: block;
    position: relative;
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: $fontWeightSemiBold;
    text-decoration: none !important;
    margin: 0;
    padding: 8px;
    color: $grayColor;

    &:hover,
    &:focus {
      color: $textBaseColor;
    }

    &--pinterest {
      @include iconFont(pinterest);
    }

    &--facebook {
      @include iconFont(facebook) {
        font-size: 1.3em;
      }
    }

    &--twitter {
      @include iconFont(twitter);
    }

    &--mail {
      @include iconFont(mail);
    }

    &--whatsapp {
      @include iconFont(whatsapp);

      @media (min-width: $screen-desktop) {
        display: none !important;
      }
    }
  }

  &--page {
    #{$root}__social-wrapper {
      margin-top: 10px;
      display: flex;
    }

    #{$root}__social-icons {
      margin-left: 0;
      display: flex;
      align-items: center;

      #{$root}__link {
        display: inline-block;
      }
    }
  }

  &--big-icons {
    #{$root}__text {
      font-weight: $fontWeightSemiBold;
      line-height: 140%;
    }

    #{$root}__social-icons {
      #{$root}__link {
        font-size: 30px !important;
        display: inline-block;
        color: $grayColor;
        position: relative;
        text-decoration: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 5px 10px;
        padding: 0;

        &:first-child {
          margin-left: 0;
        }

        &:visited {
          color: $grayColor;
        }

        &:hover {
          color: $grayDarkColor;
        }

        &--mail {
          @include iconFont(mail) {
            font-size: 15px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          color: $whiteColor;
          background: $grayColor;
          padding: 10px;

          &:hover {
            background: $textBaseColor;
            color: $whiteColor;
          }
        }

        &--facebook {
          @include iconFont(facebookInline);
        }

        &--twitter {
          @include iconFont(twitter);
        }

        &--whatsapp {
          @include iconFont(whatsapp);
        }
      }
    }
  }
}
