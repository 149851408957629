.google-map {
  width: 100%;
  height: 100%;
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    min-height: 450px;
  }

  &__tooltip {
    pointer-events: none;
    position: absolute;
    z-index: 10000;
    transform: translate(-50%, -100%);
    margin-top: -10px;
    box-shadow: 0 1px 3px rgb(0 0 0 / .3);
    background: #fff;
    font-size: 14px;
    padding: 8px;
    max-width: 160px;
    text-align: center;

    &::before {
      $size: 5px;
      content: '';
      position: absolute;
      bottom: -$size;
      left: 50%;
      transform: translateX(-$size);
      width: 0;
      height: 0;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-top: $size solid #fff;
    }
  }
}
