@import '../../../bps/components/sidePanel/sidePanel.scss';

.side-panel {
  $root: &;

  &--billing-form {
    background: $sidePanelCheckoutFormBackground;

    #{$root}__container {
      background: $sidePanelCheckoutFormBackground;
    }
  }

  &--checkout {
    #{$root}__close {
      top: 8px;
    }
  }

  &--cart-mobile {
    @media (max-width: $screen-mobile-landscape-max) {
      .side-panel__close {
        display: none;
      }

      .side-panel__container {
        margin-top: 12px;
      }

      .add-to-cart-panel__footer {
        display: none;
      }
    }
  }
}
