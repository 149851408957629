@mixin sideColumnLayout() {
  $root: &;

  @include container($maxWidthOuter);
  display: block;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &--no-sidebar {
    #{$root}__column {
      display: none;
    }

    #{$root}__content {
      width: 100%;
      padding-left: 0;
    }
  }

  &__column {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      width: calc(28% - 16px);
      padding-right: 16px;
    }

    @media (min-width: $screen-tablet-landscape) {
      display: block;
      width: calc(23.6% - 16px);
      padding-right: 16px;
    }
  }

  &__content {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: calc(72% + 16px);
      padding-left: 16px;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: calc(76.4% + 16px);
      padding-left: 16px;
    }
  }
}
