.category-page {
  &__header {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:empty {
      margin-top: 0;
    }
  }

  &__name {
    display: block;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }

  &__show-all {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      max-width: 200px;
    }
  }

  &__categories {
    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }

  &__category-tile {
    margin-bottom: 15px;

    @media (min-width: $screen-tablet-portrait) {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }
}
